import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Header from './header';

/* MUI */
import { Box, Container, Grid } from '@mui/material'
import {  } from '@mui/icons-material';

export default function VideoPlayerStudent({vpath}){

    const { videopath } = useParams()

    const videoRef = useRef(null);
    useEffect(() => {
        new Plyr(videoRef.current);
    }, [])

    return(
        <Box><Header back={true} />
            <Container>
                <Grid container mt={3}>
                    <Grid item xs={12} md={11} mx='auto' >
                        <video ref={videoRef} autoPlay={true} >
                            <source src={`${vpath}/${videopath}`} type="video/mp4" />
                        </video>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}