import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer className="footer-box margin-top_50">
                <div className="container">

                    <div className="row">

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5">
                            <div className="footer_blog">
                                <div className="full margin-bottom_30">
                                    <img src="images/footer_logo.png" alt="#" />
                                </div>
                                <div className="full white_fonts">
                                    <p>The Radiant Institute of Advance Technology which is a unit of JANKI GLOBAL EDUCATION which is Reg. by subsection (2) of section 7 of the Company Act 2013 and rule & the companies (Incorporation) Rules. 2014 the Cin of the company is U74999WB2016PTC216707.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <div className="footer_blog footer_menu white_fonts">
                                <h3>Quick links</h3>
                                <ul>
                                    <li><Link to="/"> Home</Link></li>
                                    <li><Link to="/about"> About Us</Link></li>
                                    <li><Link to="/courses"> Courses</Link></li>
                                    <li><Link to="/student"> Student Zone</Link></li>
                                    <li><Link to="/gallery"> Gallery</Link></li>
                                    <li><Link to="/dashboard-student"> Student Login</Link></li>
                                    <li><Link to="/dashboard-teacher"> Teacher Login</Link></li>
                                    <li><Link to="/dashboard-branch"> Franchise Login</Link></li>
                                    <li><Link to="/dashboard-admin"> Admin Login</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="footer_blog full white_fonts">
                                <h3>Contact us</h3>
                                <ul className="full">
                                    <li><img src="images/i5.png" alt='3' /><span>Bishari sthan<br></br>Haplaganjh, Katihar<br></br>Dist - Katihar, Bihar</span></li>
                                    <li><img src="images/i6.png" alt='2' /><span>info@radiantiteducation.com</span></li>
                                    <li><img src="images/i7.png" alt='1' /><span><a href='tel: 7979782589'>+91 7979782589</a></span></li>
                                    <li><img src="images/i7.png" alt='1' /><span><a href='tel: 9534541471'>+91 9534541471</a></span></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;