import React, { useEffect, useState } from 'react'
import {  } from 'react-router-dom'

import SidebarBranch from './../branch/sidebar'
import HeaderBranch from './../branch/header'

/* MUI */
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';

export default function PrintPayment({api}){
    const printIt = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML
        document.body.innerHTML = getArea            
        window.print()
        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }

    const [payments, setPayments] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('payment-detail-print', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPayments(data)
            }
        })
    }, [])

    return(
        <>
        <div className='sidebar'> <SidebarBranch /> </div>
        <div className='content'>
        <HeaderBranch />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-10 col-12 mx-auto mt-4'>
                    <div className='text-end'><IconButton onClick={() => printIt()} ><PrintIcon /></IconButton></div>
                    <TableContainer id='printArea' >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left' >SR.NO.</TableCell>
                                    <TableCell align='left' >NAME</TableCell>
                                    <TableCell align='left' >CODE</TableCell>
                                    <TableCell align='left' >BATCH</TableCell>
                                    <TableCell align='left' >FEES</TableCell>
                                    <TableCell align='left' >PAID</TableCell>
                                    <TableCell align='left' >DUES</TableCell>
                                    <TableCell align='left' >LAST PAYMENT</TableCell>
                                    <TableCell align='right' >REMARK</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { payments.map((pay, i) =>
                                <TableRow key={i} >
                                    <TableCell align='left' >{i+1}</TableCell>
                                    <TableCell align='left' >{pay.s_name}</TableCell>
                                    <TableCell align='left' >{pay.s_code}</TableCell>
                                    <TableCell align='left' >{pay.bh_start} To {pay.bh_end}</TableCell>
                                    <TableCell align='left' >{Number(pay.fees).toLocaleString('en-in')} &#8377;</TableCell>
                                    <TableCell align='left' >{Number(pay.payed).toLocaleString('en-in')} &#8377;</TableCell>
                                    <TableCell align='left' >{Number(Number(pay.fees) - Number(pay.payed)).toLocaleString('en-in')} &#8377;</TableCell>
                                    <TableCell align='left' >{pay.last_payed}</TableCell>
                                    <TableCell align='right' ></TableCell>
                                </TableRow> ) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}