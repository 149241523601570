import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Bar from './../bar'

/* MUI */
import { Box, Container, Grid, Typography } from '@mui/material'

export default function BranchFranchiseCertificate({api}){
    const { branchid } = useParams()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', branchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [branchid])

    const area = useRef()

    return(
        <Box><Bar text='Certificate' print={true} area={area} />
        
        <Container>
            <Grid container mt={4} >
                <Grid item xs={12} md={12} overflow='auto' margin='auto' >
                    <Box sx={{position: 'relative', width: '297mm', height: '208mm'}} ref={area} >
                        <img src='/cert/cert-fran.jpg' alt='Certificate' style={{width: '100%', height: '100%'}} />

                        <Box sx={{width: '100%', position: 'absolute', top: '100mm'}} textAlign='center' >
                            <Typography fontSize={20} color='black' letterSpacing={2} fontWeight='bold' fontFamily='initial' >{preview.b_name}</Typography>
                        </Box>

                        <Typography sx={{position: 'absolute', bottom: '52.8mm', left: '70mm'}} color='black' fontWeight='bold' >{preview.b_code}</Typography>
                        <Typography sx={{position: 'absolute', bottom: '52.8mm', left: '160mm'}} color='black' fontWeight='bold' >{preview.b_valid}</Typography>
                        <Typography sx={{position: 'absolute', bottom: '52.2mm', left: '237mm'}} color='black' fontWeight='bold' >{preview.b_joined}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>

        </Box>
    );
}