import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SidebarBranch from './sidebar'

/* MUI */
import {  } from '@mui/material'

export default function DashboardBranch({api}){
    let history = useHistory()

    useEffect(() => {
        fetch('/radiantapi/branch/ui.php')
        .then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-branch')
            }
        })
    }, [])

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [])

    const [amount, setAmount] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-amount', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setAmount(data)
            }
        })
    }, [])

    const [branch, setBranch] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranch(data)
            }
        })
    }, [api])

    const [bview, setBview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('total-entry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBview(data)
            }
        })
    }, [])

    return(
        <>
        <SidebarBranch api={api} />
        <div className='content'>
        <div className='container-fluid mb-5 mt-4'>
            <div className='row p-2' >
                <div className='col-11 mx-auto'>
                    <div className='row p-3 rounded-3 lightshadow mb-4'>
                        <div className='col-md-2 mb-4'>
                            <img src={`/instituteassets/branchimg/${branch.b_pic}`} className='rounded-circle shadow' style={{width: '150px'}} />
                        </div>
                        <div className='col-md-10 flex'>
                            <h3 className='fw-bold ms-1' style={{letterSpacing: '5px'}} >HELLO, {branch.b_oname}</h3>
                        </div>
                    </div>
                    <div className='row p-3 rounded-3 lightshadow pointer' style={{background: '#90a4ae'}} onClick={() => history.push('/student-list-branch')} >
                        <div className='col-md-3 flex text-center' style={{height: '120px'}}>
                            <h5 className='fw-bold' style={{letterSpacing: '5px'}} >STUDENT</h5>
                            <h3 className='fw-bold' >{Number(preview.students)}</h3>
                        </div>
                        <div className='col-md-3 flex text-center' style={{height: '120px'}}>
                            <h5 className='fw-bold' style={{letterSpacing: '5px'}} >PENDING</h5>
                            <h3 className='fw-bold' >{Number(preview.pending)}</h3>
                        </div>
                        <div className='col-md-3 flex text-center' style={{height: '120px'}}>
                            <h5 className='fw-bold' style={{letterSpacing: '5px'}} >ISSUED</h5>
                            <h3 className='fw-bold' >{Number(preview.issued)}</h3>
                        </div>
                        <div className='col-md-3 flex text-center' style={{height: '120px'}}>
                            <h5 className='fw-bold' style={{letterSpacing: '5px'}} >ATTEND</h5>
                            <h3 className='fw-bold' >{Number(preview.attend)}</h3>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-4 mb-4'>
                            <div className='p-3 rounded-3 lightshadow flex text-center' style={{height: '250px'}}>
                                <h5 className='fw-bold' style={{letterSpacing: '3px'}} >INCOME TODAY</h5>
                                <h3 className='fw-bold'>&#8377; {Number(amount.credittoday).toLocaleString('en-in')}</h3>
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='p-3 rounded-3 lightshadow flex text-center' style={{height: '250px'}}>
                                <h5 className='fw-bold' style={{letterSpacing: '3px'}} >EXPANCE TODAY</h5>
                                <h3 className='fw-bold'>&#8377; {Number(amount.debittoday).toLocaleString('en-in')}</h3>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='p-3 rounded-3 lightshadow flex text-center' style={{height: '250px'}}>
                                <h5 className='fw-bold' style={{letterSpacing: '3px'}} >PAYMENT TODAY</h5>
                                <h3 className='fw-bold'>&#8377; {Number(amount.paymenttoday).toLocaleString('en-in')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <div className='lightshadow p-3 rounded-3 text-center flex' style={{height: '200px'}} >
                                <h5 className='fw-bold lspace3'>BALANCE TODAY</h5>
                                <h3 className='fw-bold' >&#8377; {Number(Number(amount.credittoday) + Number(amount.paymenttoday) - Number(amount.debittoday)).toLocaleString('en-in')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}