import React, { useEffect, useState } from 'react'
import { Route, useRouteMatch, useHistory, useParams } from 'react-router-dom'

import SidebarBranch from './sidebar'
import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function StudentBranch({api}){
    const { path, url } = useRouteMatch()
    let history = useHistory()

    const [students, setStudents] = useState([])
    const [studentwarn, setStudentwarn] = useState('')
    const [loading, setLoading] = useState(true)
    const [query] = useState('')
    useEffect(() => {
        viewstudents(query)
    }, [query])
    const viewstudents = (query) => {
        setStudentwarn('')
        var formData = new FormData()
        formData.append('view-students', query)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setStudentwarn(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const searchstudent = (event) => {
        if(event.keyCode === 13){
            viewstudents(event.target.value)
        }
    }

    return(
        <>
        <SidebarBranch api={api} />
        <div className='content'>
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 text-center p-0'>
                    <TextField fullWidth type='text' variant='standard' label='Search' placeholder='Search by student name, code...' onKeyUp={(event) => searchstudent(event)} />
                </div>
                <div className='text-end mb-4 p-0'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >Add student</Button>
                </div>
                
                <div className='col-md-6 col-12 mx-auto'>
                    <div className='row'>
                        {loading ? <LinearProgress color='warning' /> :
                        studentwarn ? <Alert severity='warning'>{studentwarn}</Alert> :
                        students.map((stu, i) =>
                        <div className={`col-12 rounded-3 p-3 mb-2 bggrey ${stu.s_pending === '1' && 'blockbg' } `} key={i} >
                            <h5 className='fw-bold' >{stu.s_name}</h5>
                            <p>Code: {stu.s_code}</p>
                            <p>Call: <a href={`tel: ${stu.s_mobile}`}>{stu.s_mobile}</a></p>
                            <div className=''>
                                <IconButton onClick={() => history.push(`${url}/info/${stu.studentid}`)} ><InfoIcon /></IconButton>
                                <IconButton onClick={() => history.push(`${url}/configure/${stu.studentid}`)} ><EditIcon /></IconButton>
                            </div>
                        </div> )}
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:studentid`} component={Configure} />
        <Route path={`${path}/info/:studentid`} component={Info} />
        </>
    );

    function Configure(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')
        const { path, url } = useRouteMatch()

        const [code, setCode] = useState('')
        const [email, setEmail] = useState('')
        const [name, setName] = useState('')
        const [guardian, setGuardian] = useState('')
        const [address, setAddress] = useState('')
        const [po, setPo] = useState('')
        const [ps, setPs] = useState('')
        const [city, setCity] = useState('')
        const [dist, setDist] = useState('')
        const [state, setState] = useState('')
        const [postel, setPostel] = useState('')
        const [aadhaar, setAadhaar] = useState('')
        const [mobile, setMobile] = useState('')
        const [birth, setBirth] = useState('')
        const [gender, setGender] = useState('')
        const [nation, setNation] = useState('')
        const [pending, setPending] = useState('')
        const [joindate, setJoindate] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCode(data.s_code); setEmail(data.s_email); setName(data.s_name); setGuardian(data.s_guardian); setAddress(data.s_address); setPo(data.s_po); setPs(data.s_ps); setCity(data.s_city); setDist(data.s_dist); setState(data.s_state); setPostel(data.s_postel); setAadhaar(data.s_aadhaar); setMobile(data.s_mobile); setBirth(data.s_birth); setGender(data.s_gender); setNation(data.s_nation); setPending(data.s_pending); setJoindate(data.s_joindate)
                }
            })
        }, [studentid])

        const [joining, setJoining] = useState(false)
        const upload = () => {
            setJoining(true)
            var formData = new FormData()
            formData.append('configure-student', studentid)
            formData.append('code', code)
            formData.append('email', email)
            formData.append('name', name)
            formData.append('guardian', guardian)
            formData.append('address', address)
            formData.append('po', po)
            formData.append('ps', ps)
            formData.append('city', city)
            formData.append('dist', dist)
            formData.append('state', state)
            formData.append('postel', postel)
            formData.append('aadhaar', aadhaar)
            formData.append('mobile', mobile)
            formData.append('birth', birth)
            formData.append('gender', gender)
            formData.append('nation', nation)
            formData.append('pending', pending)
            formData.append('joindate', joindate)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setJoining(false)
                if(data.warning){
                    setMessage(data.warning)
                    setOpen(true)
                } else {
                    viewstudents('')
                    history.go(-1)
                }
            })
        }

        const [qualifications, setQualifications] = useState([])
        const [qualiwarn, setQualiwarn] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            viewqualifications(studentid)
        }, [studentid])

        const viewqualifications = (studentid) => {
            var formData = new FormData()
            formData.append('view-qualifications', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setQualiwarn(data.warning)
                } else {
                    setQualifications(data)
                }
            })
        }

        const [qid, setQid] = useState('')
        const deleteQualification = (tableid) => {
            setQid(tableid)
            var formData = new FormData()
            formData.append('delete-qualification', tableid)
            formData.append('studentid', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setQid('')
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    viewqualifications(studentid)
                }
            })
        }

        return(
            <Dialog open={true} fullScreen>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Configure student' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid'>
                        <div className='row p-3'>
                            <div className='col-md-6 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <TextField fullWidth variant='standard' label='Student code' type='text' className='mt-4' value={code} onChange={(event) => setCode(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Name*' type='text' className='mt-4' value={name} onChange={(event) => setName(event.target.value)} />
                                        <TextField fullWidth variant='standard' label="Father's name*" type='text' className='mt-4' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Address*' type='text' className='mt-4' value={address} onChange={(event) => setAddress(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Post office' type='text' className='mt-4' value={po} onChange={(event) => setPo(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Police station' type='text' className='mt-4' value={ps} onChange={(event) => setPs(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='City' type='text' className='mt-4' value={city} onChange={(event) => setCity(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='District' type='text' className='mt-4' value={dist} onChange={(event) => setDist(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='State' type='text' className='mt-4' value={state} onChange={(event) => setState(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Postal code' type='number' className='mt-4' value={postel} onChange={(event) => setPostel(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Aadhaar' type='number' className='mt-4' value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Email' type='email' className='mt-4' value={email} onChange={(event) => setEmail(event.target.value)} />
                                        <TextField fullWidth variant='standard' label='Mobile' type='number' className='mt-4' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                        <TextField fullWidth variant='standard' helperText='Date of birth' type='date' className='mt-4' value={birth} onChange={(event) => setBirth(event.target.value)} />
                                        <FormControl fullWidth variant='standard' >
                                            <InputLabel>Gender</InputLabel>
                                            <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                                <MenuItem value='MALE' >Male</MenuItem>
                                                <MenuItem value='FEMALE' >Female</MenuItem>
                                                <MenuItem value='OTHERS' >Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' label='Nation' type='text' className='mt-4' value={nation} onChange={(event) => setNation(event.target.value)} />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Pending</InputLabel>
                                            <Select value={pending} onChange={(event) => setPending(event.target.value)} >
                                                <MenuItem value='1' >Yes</MenuItem>
                                                <MenuItem value='0' >No</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField fullWidth variant='standard' helperText='Admission date' type='date' className='mt-4' value={joindate} onChange={(event) => setJoindate(event.target.value)} />
                                    </div>
                                    <div className='col-12 mt-4 text-center'>
                                        <Button className='col-md-4 col-6' variant='contained' size='large' color='warning' disabled={joining} onClick={() => upload()} >{joining ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        {loading ? <LinearProgress color='warning' /> :
                                        qualiwarn ? <Alert severity='warning'>{qualiwarn}</Alert> :
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Board</TableCell>
                                                        <TableCell align='center' >Examinatin</TableCell>
                                                        <TableCell align='center' >Passed year</TableCell>
                                                        <TableCell align='center' >Obtain marks</TableCell>
                                                        <TableCell align='center' >Delete</TableCell>
                                                        <TableCell align='right' >Edit</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {qualifications.map((q, i) =>
                                                    <TableRow key={i} >
                                                        <TableCell>{q.board}</TableCell>
                                                        <TableCell align='center' >{q.exam}</TableCell>
                                                        <TableCell align='center' >{q.pass}</TableCell>
                                                        <TableCell align='center' >{q.marks}</TableCell>
                                                        <TableCell align='center' ><IconButton onDoubleClick={() => deleteQualification(q.q_table_id)} >{q.q_table_id === qid ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon /> }</IconButton></TableCell>
                                                        <TableCell align='right' ><IconButton onClick={() => history.push(`${url}/qualification/${q.q_table_id}/${studentid}`)} ><EditIcon /></IconButton></TableCell>
                                                    </TableRow>)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> }

                                        <Button color='warning' onClick={() => history.push(`${url}/qualification/0/${studentid}`)} >Add qualification</Button>
                                        <Button color='warning' onClick={() => history.push(`/form/${studentid}`)} >Print form</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <Route path={`${url}/qualification/:tableid/:studentid`} component={Qualification} />
                <Route path={`${url}/delete/:studentid`} component={DeleteStudent} />
            </Dialog>
        );
    }

    function Qualification(){
        const { tableid } = useParams()
        const { studentid } = useParams()
        const [board, setBoard] = useState('')
        const [exam, setExam] = useState('')
        const [pass, setPass] = useState('')
        const [marks, setMarks] = useState('')

        useEffect(() => {
            var formData = new FormData()
            formData.append('qualification-detail', studentid)
            formData.append('tableid', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setBoard(data.board); setExam(data.exam); setPass(data.pass); setMarks(data.marks)
                }
            })
        }, [tableid])

        const [warning, setWarning] = useState('')
        const [uploading, setUploading] = useState(false)
        const uploadnow = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-qualification', studentid)
            formData.append('tableid', tableid)
            formData.append('board', board)
            formData.append('exam', exam)
            formData.append('pass', pass)
            formData.append('marks', marks)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWarning('')
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Qualification' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' type='text' label='Board' className='mb-4' value={board} onChange={(event) => setBoard(event.target.value)} />
                                <TextField fullWidth variant='standard' type='text' label='Examination' className='mb-4' value={exam} onChange={(event) => setExam(event.target.value)} />
                                <TextField fullWidth variant='standard' type='number' label='Passed year' className='mb-4' value={pass} onChange={(event) => setPass(event.target.value)} />
                                <TextField fullWidth variant='standard' type='number' label='Obtain marks' className='mb-4' value={marks} onChange={(event) => setMarks(event.target.value)} />
                            </div>
                            <div className='col-12 mt-2'>
                                {warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='warning' variant='contained' size='large' onClick={() => uploadnow()} disabled={uploading} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Info(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        useEffect(() => {
            joinedcourebatch(studentid)
        }, [studentid])
        const [jcourse, setJcourse] = useState('')
        const [coursetitle, setCoursetitle] = useState('')
        const [jbatch, setJbatch] = useState('')
        const [pic, setPic] = useState('')
        const [name, setName] = useState('')
        const [code, setCode] = useState('')
        const joinedcourebatch = (studentid) => {
            var formData = new FormData()
            formData.append('joined-course-batch', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setJcourse(data.courseid); setJbatch(data.batchid); setPic(data.s_pic); setCoursetitle(data.c_title); setName(data.s_name); setCode(data.s_code)
                }
            })
        }

        const [courses, setCourses] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-course', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCourses(data)
                }
            })
        }, [])

        const joincourse = (courseid) => {
            setJcourse(courseid)
            var formData = new FormData()
            formData.append('join-course', studentid)
            formData.append('courseid', courseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.message); setOpen(true)
                } else if(data.success) {
                    setMessage('Succedd'); setOpen(true)
                }
            })
        }

        const [batches, setBatches] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-batches', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setBatches(data)
                }
            })
        }, [])

        const joinbatch = (batchid) => {
            setJbatch(batchid)
            var formData = new FormData()
            formData.append('join-batch', studentid)
            formData.append('batchid', batchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    setMessage('Succedd'); setOpen(true)
                }
            })
        }

        const [picuploading, setPicuploading] = useState(false)
        const updatepic = (pic) => {
            setPicuploading(true)
            var formData = new FormData()
            formData.append('update-pic', studentid)
            formData.append('pic', pic.target.files[0])

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data =>{
                setPicuploading(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    joinedcourebatch(studentid)
                }
            })
        }

        const [subjects, setSubjects] = useState([])
        const [subjectwarn, setSubjectwarn] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-results', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setSubjectwarn(data.warning)
                } else {
                    setSubjects(data)
                }
            })
        }, [studentid])

        const [added, setAdded] = useState('')

        const uploadresult = (event, subjectid) => {
            setOpen(false)
            var formData = new FormData()
            formData.append('upload-result', studentid)
            formData.append('subjectid', subjectid)
            formData.append('marks', event.target.value)
            if(event.keyCode === 13){
                setAdded(Number(added)+Number(event.target.value))
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    if(data.warning){
                        setMessage(data.warning); setOpen(true)
                    } else if(data.success){
                        setMessage('Succedd'); setOpen(true)
                    }
                })
            }
        }

        const [typing, setTyping] = useState('')
        
        useEffect(() => {
            viewtyping(studentid)
        }, [])

        const viewtyping = (studentid) => {
            var formData = new FormData()
            formData.append('view-typing-result', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setTyping(data.r_typing)
                }
            })
        }

        const updatetyping = (event) => {
            setTyping(event.target.value)
            var formData = new FormData()
            formData.append('upload-typing-result', studentid)
            formData.append('courseid', jcourse)
            formData.append('typing', typing)
            if(event.keyCode === 13){
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    if(data.warning){
                        setMessage(data.warning); setOpen(true)
                    } else if(data.success){
                        setMessage('Succedd'); setOpen(true)
                    }
                })
            }
        }

        var x = 0;

        return(
            <Dialog open={true} fullScreen>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Student info' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-5 col-12 mx-auto'>
                                <div className='row'>
                                    <label className='col-12 text-center globalimgsize mx-auto' style={{position: 'relative'}} >
                                        <input type='file' accept='.png, .jpeg, .jpg' style={{position: 'absolute', zIndex: '-1', display: 'none'}} onChange={(event) => updatepic(event)} />
                                        <img src={`/radiantassets/studentimg/${pic}`} alt='Student img' className='globalimgsize' />
                                    </label>
                                    <div className='text-center col-12 mt-2'>
                                        <small>{picuploading && 'Uploading... Please wait.' }</small>
                                    </div>

                                    <div className='col-12 mt-4'>
                                        <TextField fullWidth variant='standard' value={name} disabled />
                                        <TextField fullWidth variant='standard' value={code} disabled className='mt-4' />
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Select course</InputLabel>
                                            <Select value={jcourse} onChange={(event) => joincourse(event.target.value)} >
                                                {courses.map((course, i) => 
                                                    <MenuItem className='text-truncate' key={i} value={course.courseid} >{course.c_title} ({course.c_name}) (Duration: {course.c_duration} Months)</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth variant='standard' className='mt-4' >
                                            <InputLabel>Select batch</InputLabel>
                                            <Select value={jbatch} onChange={(event) => joinbatch(event.target.value)} >
                                                {batches.map((batch, i) =>
                                                    <MenuItem key={i} value={batch.batchid} >{batch.bh_start} - {batch.bh_end}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    
                                    {coursetitle === 'TYPING' ?
                                    <div className='col-12 mt-5'>
                                        <FormControl onKeyUp={(event) => updatetyping(event)} fullWidth >
                                            <TextField fullWidth placeholder='' label='Typing speed' value={typing} onChange={(event) => setTyping(event.target.value)} />
                                        </FormControl>
                                    </div>
                                    :
                                    <div className='col-12 mt-5'>
                                        {subjectwarn ? <Alert severity='warning'>{subjectwarn}</Alert> :
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Term</TableCell>
                                                        <TableCell align='right' >Subject</TableCell>
                                                        <TableCell align='right' >Full marks</TableCell>
                                                        <TableCell align='right' >Pass marks</TableCell>
                                                        <TableCell align='right' >Obtain marks</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {subjects.map((sub, i) =>
                                                    <TableRow key={i} >
                                                        <TableCell>{sub.s_term}</TableCell>
                                                        <TableCell align='right' >{sub.s_subject}</TableCell>
                                                        <TableCell align='right' >{sub.s_fmark}</TableCell>
                                                        <TableCell align='right' >{sub.s_pmark}</TableCell>
                                                        <TableCell align='right' ><TextField fullWidth type='number' variant='standard' placeholder={sub.r_marks} onKeyUp={(event) => uploadresult(event, sub.subjectid)} /></TableCell>
                                                    </TableRow> )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer> }
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function DeleteStudent(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [deleting, setDeleting] = useState(false)
        const deleteNow = () => {
            setDeleting(true)
            var formData = new FormData()
            formData.append('delete-student', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setMessage('')
                setDeleting(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.success){
                    history.go(-2)
                }
            })
        }
        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} onClose={() => setOpen(false)} message={message} />
                <DialogContent>
                    <div className='container-fluid mt-4 mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h3 className='fw-bold'>Do you want to delete student?</h3>
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                <Button color='error' variant='contained' size='large' disabled={deleting} onClick={() => deleteNow()} >{deleting ? <CircularProgress color='inherit' size={24} /> : 'Yes' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}