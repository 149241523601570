import React, { } from 'react'
import { Link, useHistory } from 'react-router-dom'

// MUI
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'

export default function PagesFooter(){

    const history = useHistory()

    return(
        <Box mt={5} >
            <Box sx={{background: '#006d77'}} >
                <Container>
                    <Grid item>
                        <Box padding={2} >
                            <Typography fontWeight='bold' textAlign='center' fontSize={{xs: 15, md: 25}} letterSpacing={2} color='white' >RADIANT INSTITUTE OF ADVANCE TECHNOLOGY</Typography>
                        </Box>
                    </Grid>
                </Container>
            </Box>
            <Container>
                    <Grid container justifyContent='center' alignItems='center' mt={5} pb={3} >
                        <Grid item xs={12} md={8} mb={2} >
                            <Typography variant='body2' fontWeight='bold' mb={1} >SITE MAP</Typography>
                            <Stack direction={{xs: 'column', md: 'row'}} spacing={1} >
                                <Link to='/' >Home</Link>
                                <Link to='/course' >Course</Link>
                                <Link to='/contact' >Contact</Link>
                                <Link to='/about' >About</Link>
                            </Stack>
                            <Stack direction='row' spacing={1} mt={1} >
                                <Link  to="/security#privacy" >Privacy-Policy</Link>
                                <Link to='/security#term' >Terms & Conditions</Link>
                                <Link to='/security#cr' >Cancelation & Refund</Link>
                            </Stack>

                            
                            <Typography variant='body2' mt={3} fontWeight='bold' mb={1} >LOGIN</Typography>
                            <Stack direction='row' spacing={5} >
                                <Link to='/dashboard-student' >Student</Link>
                                <Link to='/dashboard-teacher' >Teacher</Link>
                                <Link to='/dashboard-branch' >Franchise</Link>
                                <Link to='/dashboard-admin' >Admin</Link>
                            </Stack>

                            
                            <Typography variant='body2' mt={3} fontWeight='bold' mb={1} >SOCIAL WITH US</Typography>
                            <Stack direction='row' spacing={5} >
                                <Typography>Facebook</Typography>
                                <Typography>Instagram</Typography>
                                <Typography>WhatsApp</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} textAlign={{xs: 'left', md: 'end'}} >
                            <Typography variant='body1' fontWeight='bold' >Office address</Typography>
                            <Typography variant='body2' >Hafla Ganj, Sirnia, Katihar</Typography>
                            <Typography variant='body2'>Bihar, 854103</Typography>

                            <Typography fontWeight='bold' mb={1} mt={2} >Be the part of our Universe</Typography>
                            <Button variant='contained' sx={{borderRadius: 0, boxShadow: 0}} onClick={() => history.push('/login-student')} >SIGN UP NOW</Button>
                        </Grid>
                    </Grid>
            </Container>
            <Box sx={{background: '#2ec4b6'}} padding={2} textAlign='end' >
                <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between' alignItems='center' >
                    <Typography fontSize={14} >&copy; Copyright RIAT. All rights reserved.</Typography>
                    <a href='https://passionfrom.com' target="_blank" rel='noreferrer' ><img alt='Designed & Developed by PassionFrom' src='https://passionfrom.com/passionfromassets/whitePassionfrom.png' style={{width: '120px'}} /></a>
                </Stack>
            </Box>
        </Box>
    );
}