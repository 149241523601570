import React, { useState } from 'react'

/* HEADER */
import PagesHeader from './../components/Navbar';
import PagesFooter from './../components/Footer';

import { Button, Dialog, DialogContent, Snackbar, TextField } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Student(){
    const [snack, setSnack] = useState(false)
    const [snackmessage, setSnackmessage] = useState('')
    const [openview, setOpenview] = useState(false)
    const [view, setView] = useState([])
    const [open, setOpen] = useState(false)

    const registration = (event) => {
        if(event.keyCode === 13){
            var formData = new FormData()
            formData.append('verify-certificate', event.target.value)
            fetch('/instituteapi/public/index.php', {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setOpenview(false)
                    setSnackmessage(data.warning); setSnack(true)
                } else {
                    setOpenview(true)
                    setView(data)
                }
            })
        }
    }

    const [verified, setVerified] = useState('')
    const [message, setMessage] = useState('')
    const verifyAd = (event) => {
        if(event.keyCode === 13){
            setMessage('')
            var formData = new FormData()
            formData.append('verify-admission', event.target.value)
            fetch('/instituteapi/public/index.php', {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setVerified(false); setMessage(data.warning)
                } else {
                    setVerified(true); setMessage('Admission succedd!');
                }
            })
        }
    }

    return(
        <><PagesHeader />
        <div className='container-fluid mb-5 flex' style={{marginTop: '7em'}} >
            <Snackbar message={snackmessage} open={snack} onClose={() => setSnack(false)} />
            <div className='row p-3'>
                <div className='col-md-7 col-12 mx-auto text-center'>
                    <h1 className='fw-bold'>Verify Certificate</h1>
                </div>
                <div className='col-md-6 col-12 mx-auto mt-5 mb-5'>
                    <TextField fullWidth variant='standard' type='text' label='Registration number' placeholder='Enter your registration number...' onKeyUp={(event) => registration(event)} />
                    <div className='text-center'>
                        <Button color='warning' onClick={() => setOpen(true)} >Verify Student</Button>
                    </div>

                    { openview &&
                    <div className='col-12 mt-4'>
                        <div className='col-12 text-center'>
                            <VerifiedIcon style={{fontSize: '10em', color: '#1de9b6'}} />
                        </div>
                        <div className='col-12 mt-4'>
                            <table className='table table-borderless' >
                                <tbody>
                                    <tr><td>Name</td><td>{view.s_name}</td></tr>
                                    <tr><td>Father/Mother's name</td><td>{view.s_guardian}</td></tr>
                                    <tr><td>Enrollment</td><td>{view.s_enrollment}</td></tr>
                                    <tr><td>Issue date</td><td>{view.s_issuedate}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div> }
                </div>
            </div>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <DialogContent>
                    <div className='container-fluid mt-5 mb-5'>
                        <div className='row'>
                            <div className='col-12'>    
                                <TextField fullWidth variant='outlined' label='Student code' placeholder='Enter student code...' onKeyUp={(event) => verifyAd(event)} />
                            </div>
                            <div className='mt-4 text-center'>
                                {verified === true ? <VerifiedIcon style={{fontSize: '10em', color: '#1de9b6'}} /> :
                                verified === false && <CancelIcon style={{fontSize: '10em', color: '#f50057'}} />
                                }
                                <h3 className='fw-bold'>{message}</h3>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
        <PagesFooter /></>
    );

}