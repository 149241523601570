import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

/* MUI */
import { AppBar, Box, Button, CircularProgress, Dialog, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BoyIcon from '@mui/icons-material/Boy';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import { Menu, PowerSettingsNew } from '@mui/icons-material';

export default function SidebarBranch({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()

    useEffect(() => {
        fetch('/radiantapi/branch/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-branch')
            }
        })
    }, [])

    const links = [
        {name: 'Dashboard', link: '/dashboard-branch', icon: <DashboardIcon/>},
        {name: 'Attendance', link: '/attendance-branch', icon: <BoyIcon/>},
        {name: 'Student', link: '/student-branch', icon: <GroupIcon/>},
        {name: 'Teacher', link: '/teacher-branch', icon: <GroupIcon/>},
        {name: 'Batch', link: '/batch-branch', icon: <AccessTimeIcon/>},
        {name: 'Payment', link: '/payment-branch', icon: <PaymentIcon/>},
        {name: 'Entry', link: '/entry-branch', icon: <AddCardIcon/>}
    ]

    const [open, setOpen] = useState(false)

    return(
        <>
        <AppBar position='sticky' color='primary'>
            <Toolbar>
            <Typography sx={{flexGrow: 1}} letterSpacing={3} fontSize={30} fontWeight='bold' fontFamily='serif' color='white'>RIAT</Typography>

                <IconButton color='inherit' onClick={() => history.push(`${url}/logout`)} ><PowerSettingsNew/></IconButton>
                <IconButton color='inherit' edge='end' onClick={() => setOpen(true)} sx={{display: {md: 'none'}}} ><Menu/></IconButton>
            </Toolbar>
        </AppBar>
        <Drawer variant='permanent' anchor='left' sx={{display: {xs: 'none', md: 'block'}}} >
            <Box sx={{backgroundColor: 'primary.main'}} >
                <Typography pt={1.15} pb={1.15} letterSpacing={3} fontSize={30} fontWeight='bold' fontFamily='serif' color='white' align='center' >RIAT</Typography>
            </Box>
            <List sx={{width: '250px'}} >
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(data.link)} >
                        <ListItemIcon>{data.icon}</ListItemIcon>
                        <ListItemText>{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>) }
            </List>
        </Drawer>
        <Drawer open={open} variant='temporary' anchor='bottom' sx={{display: {xs: 'block', md: 'none'}}} onClose={() => setOpen(false)} PaperProps={{style: {borderTopLeftRadius: 30, borderTopRightRadius: 30}}} >
            <List>
                { links.map((data, i) =>
                <ListItem key={i} >
                    <ListItemButton onClick={() => history.push(data.link)} >
                        <ListItemIcon>{data.icon}</ListItemIcon>
                        <ListItemText>{data.name}</ListItemText>
                    </ListItemButton>
                </ListItem>) }
            </List>
        </Drawer>
        <Route path={`${path}/logout`} component={Logout} />
        </>
    );

    function Logout(){

        const [loggingout, setLoggingout] = useState(false)
        const logout = () => {
            setLoggingout(true)
            var formData = new FormData()
            formData.append('logout', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoggingout(false)
                if(data.success){
                    // REDIRECT HERE
                    history.replace('/login-branch')
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <div className='container-fluid mt-5 mb-5'>
                    <div className='row p-3'>
                        <div className='col-12 text-center'>
                            <h1 className='fw-bold' >Do you want to logout?</h1>
                        </div>
                        <div className='col-12 text-center text-center mt-4'>
                            <Button color='primary' variant='contained' disabled={loggingout} onClick={() => logout()} >{loggingout ? <CircularProgress color='inherit' size={24} /> : 'YES LOGOUT' }</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

}