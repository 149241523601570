import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Close from './../close'
import Logo from './../images/logo.png'

/* MUI */
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'

export default function AdmissionForm({api}){
    const { studentid } = useParams()

    const printIt = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML
        document.body.innerHTML = getArea            
        window.print()
        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }
    
    const [view, setView] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('admission-form', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [studentid])

    const [qualifications, setQualifications] = useState([])
    
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-qualifications', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setQualifications(data)
            }
        })
    }, [studentid])

    useEffect(() => {
        document.title = view.s_name + " ADMISSION FORM"
    }, [view])

    return(
        <Dialog open={true} fullScreen >
            <DialogTitle><Close title='Print form' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid'>
                    <div className='row p-3'>
                        <div className='col-12 text-center mb-4'>
                            <Button className='col-md-2 col-4 mx-auto p-2' color='warning' variant='contained' onClick={() => printIt()} >Print</Button>
                        </div>
                    </div>
                    <div id='printArea'>
                        <div className='row' >
                            <div className='text-center col-3' >
                                <img src={Logo} alt='logo' className='ms-2' />
                            </div>
                            <div className='text-end col-9 flex' >
                                <h5 className='fw-bold' >NEXUS EDUCATION & WELFARE FOUNDATION</h5>
                                <small>Registered Under Trust Act 1882, An ISO 9001:2015 Certified</small>
                            </div>
                        </div>
                        <div className='row mt-4 border-bottom'>
                            <div className='col-10'>
                                <h6 className='fw-bold ms-2'>From center: {view.b_name} ({view.b_code})</h6>
                                <table className='table table-borderless col-12'>
                                    <tbody>
                                        <tr><td>Student's code</td><td>{view.s_code}</td><td>Date of admission</td><td>{view.s_joindate}</td></tr>
                                        <tr><td>Registration no</td><td>{view.s_enrollment}</td><td>Date of Reg.</td><td>{view.s_register}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-2 flex text-center'>
                                <small>Place a hard copy here.</small>
                            </div>
                        </div>
                        <div className='row border-bottom'>
                            <div className='col-12'>
                                <table className='table table-borderless col-12'>
                                    <tbody>
                                        <tr><td>Candidate name</td><td>{view.s_name}</td></tr>
                                        <tr><td>Father's name</td><td>{view.s_guardian}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='row border-bottom'>
                            <div className='col-12 mt-3'>
                                <h5 className='fw-bold ms-2'>Permanent address</h5>
                            </div>
                            <div className='col-12'>
                                <table className='table table-borderless'>
                                    <tr><td>Village/Town/City</td><td>{view.s_address}</td></tr>
                                </table>
                                <table className='table table-borderless'>
                                    <tbody>
                                        <tr><td>Thana</td><td>{view.s_ps}</td><td>District</td><td>{view.s_dist}</td><td>State</td><td>{view.s_state}</td></tr>

                                        <tr><td>Post office</td><td>{view.s_po}</td><td>Gender</td><td>{view.s_gender}</td></tr>
                                        <tr><td>Aadhaar No.</td><td>{view.s_aadhaar}</td><td>Mobile</td><td>{view.s_mobile}</td><td>PIN</td><td>{view.s_postel}</td></tr>
                                        <tr><td>Date of birth</td><td>{view.s_birth}</td><td>Nationality</td><td>{view.s_nation}</td><td>Email</td><td>{view.s_email}</td></tr>
                                        <tr><td>Course</td><td>{view.c_title}</td><td>Duration</td><td>{view.c_duration} Months</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div className='row border-bottom'>
                            <div className='col-12 mt-3'>
                                <h5 className='fw-bold ms-2'>Academic Qualification</h5>
                            </div>
                            <div className='col-12'>
                                <table className='table table-borderless col-12'>
                                    <thead>
                                        <tr>
                                            <th>Examination</th>
                                            <th>Board/University</th>
                                            <th>Year of passing</th>
                                            <th>Marks obtained</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qualifications.map((qua, i) =>
                                        <tr>
                                            <td>{qua.exam}</td>
                                            <td>{qua.board}</td>
                                            <td>{qua.pass}</td>
                                            <td>{qua.marks}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <h5 className='fw-bold ms-2 mt-3'>Declaration</h5>
                            </div>
                            <div className='col-12'>
                                <p className='ms-2' >I hereby declare that all above details are true to the best of my knowledge. I also will abide by the rules of the institute.</p>
                            </div>

                            <div className='col'>
                                <h5 className='fw-bold ms-2' >Center head signature</h5>
                            </div>
                            <div className='col text-end'>
                                <h5 className='fw-bold' >Candidate's signature</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}