import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

/* MUI */
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Drawer, Typography, Toolbar, AppBar, Divider, IconButton, Dialog, DialogContent, Button, CircularProgress } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupIcon from '@mui/icons-material/Group';
import PaymentIcon from '@mui/icons-material/Payment';
import BookIcon from '@mui/icons-material/Book';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Menu, PowerSettingsNew } from '@mui/icons-material';

export default function SidebarAdmin(){
    let history = useHistory()

    useEffect(() => {
        fetch('/radiantapi/admin/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-admin')
            }
        })
    }, [])

    const links = [
        {name: 'Dashboard', link: '/dashboard-admin', icon: <DashboardIcon/>},
        {name: 'Branch & Fran', link: '/branch-admin', icon: <AccountTreeIcon/>},
        {name: 'Course', link: '/course-admin', icon: <BookIcon/>},
        {name: 'Online Course', link: '/online-course-admin', icon: <BookIcon/>},
        {name: 'Student', link: '/student-admin', icon: <GroupIcon/>},
        {name: 'Payment', link: '/payment-admin', icon: <PaymentIcon/>},
        {name: 'Gallery', link: '/gallery-admin', icon: <InsertPhotoIcon/>}
    ]

    const [open, setOpen] = useState(false)
    const [openLogout, setOpenLogout] = useState(false)

    const [working, setWorking] = useState(false)
    const logout = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch('/radiantapi/admin/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                history.push('/login-admin')
            }
        })
    }

    return(
        <Box component='nav' sx={{marginBottom: 10}} >
            <Drawer variant='permanent' PaperProps={{style: {width: '256px', background: 'secondary.main'}}} sx={{display: {xs: 'none', md: 'block'}}} >
                <Box pt={2.1} pb={2.1} sx={{backgroundColor: 'primary.main'}} >
                    <Typography fontSize={20} letterSpacing={2} fontFamily='serif' color='white' fontWeight='bold' noWrap align='center' >RIAT CONTROLLER</Typography>
                </Box>
                <Divider/>
                <List>
                    {links.map((data, i) =>
                    <NavLink key={i} to={data.link} activeStyle={{color: '#006d77'}} style={{color: 'black'}} >
                        <ListItemButton>
                            <ListItem>
                                    <ListItemIcon>{data.icon}</ListItemIcon>
                                    <ListItemText>{data.name}</ListItemText>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>)}
                </List>
            </Drawer>
            <Drawer open={open} anchor='bottom' onClose={() => setOpen(false)} PaperProps={{style: {borderTopRightRadius: 25, borderTopLeftRadius: 25}}} >
                <List>
                    <Typography align='center' variant='h5' fontWeight='bold' noWrap letterSpacing={1} >ADMINISTRATOR</Typography>
                    <Divider/>
                    {links.map((data, i) =>
                    <NavLink key={i} to={data.link} >
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>{data.icon}</ListItemIcon>
                                <ListItemText>{data.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>)}
                </List>
            </Drawer>
            <AppBar color='primary' position='fixed' >
                <Toolbar>
                    <Typography noWrap fontSize={20} fontWeight='bold' letterSpacing={2} color='inherit' fontFamily='serif' sx={{flexGrow: 1}} >RIAT CONTROLLER</Typography>
                    <IconButton color='inherit' onClick={() => setOpenLogout(true)} ><PowerSettingsNew/></IconButton>
                    <Box sx={{display: {xs: 'block', md: 'none'}}} >
                        <IconButton color='inherit' edge='end' onClick={() => setOpen(true)} ><Menu/></IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <Dialog open={openLogout} fullWidth maxWidth='xs' onClose={() => setOpenLogout(false)} >
                <DialogContent>
                    <Box mt={2} mb={2} >
                        <Typography fontWeight='bold' fontSize={25} >Do you want to logout?</Typography>
                        <Typography variant='caption'>Note: This process will redirect you to the login page.</Typography>

                        <Box textAlign='center' mt={2} >
                            <Button color='primary' variant='contained' sx={{borderRadius: 0, boxShadow: 'none'}} disabled={working} onClick={() => logout()} >{working ? <CircularProgress color='inherit' size={24} /> : 'YES LOGOUT'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}