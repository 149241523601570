import React, { useEffect } from "react";

import PagesHeader from "./header";
import PagesFooter from "./footer";

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function PagesAbout(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <Box><PagesHeader />
            <Box sx={{backgroundImage: "url('/images/about-back.webp')", backgroundSize: 'cover', backgroundAttachment: 'fixed'}} >
                <Container>
                    <Grid container mt={4} >
                        <Grid item xs={12} md={7} mx='auto'>
                            <Typography variant="body1" color='gray' >Radiant Institute of Advance Technology is an educational institution that offers both online and offline courses in IT and cryptocurrency. The institute is dedicated to providing high-quality education to students and professionals looking to enhance their skills in these fields.</Typography>

                            <Typography variant="body1" color='gray' mt={3} >One of the key features of Radiant Institute of Advance Technology is its online platform, which allows students from all over the world to access their courses from anywhere at any time. The platform is user-friendly and easy to navigate, ensuring that students can easily access the resources they need to succeed.</Typography>

                            <Typography variant="body1" color='gray' mt={3}>The institute offers a wide range of courses in IT, including programming languages, web development, mobile application development, and software engineering. These courses are designed to equip students with the skills and knowledge they need to succeed in the rapidly evolving world of technology.</Typography>

                            <Typography variant="body1" color='gray' mt={3}>In addition to IT courses, Radiant Institute of Advance Technology also offers courses in cryptocurrency. This includes courses on blockchain technology, cryptocurrency trading, and mining. These courses are designed to give students a comprehensive understanding of the cryptocurrency ecosystem and the skills they need to take advantage of the opportunities it presents.</Typography>
                            
                            <Typography variant="body1" color='gray' mt={3}>Radiant Institute of Advance Technology prides itself on its experienced and knowledgeable faculty, who are experts in their respective fields. The institute also provides hands-on training and practical experience, ensuring that students are well-prepared for the challenges of the real world.</Typography>

                            <Typography variant="body1" color='gray' mt={3}>Overall, Radiant Institute of Advance Technology is a great choice for anyone looking to enhance their skills in IT or cryptocurrency. With its flexible online platform, experienced faculty, and comprehensive courses, the institute is well-equipped to help students achieve their goals and succeed in their careers.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        <PagesFooter/></Box>
    )
}