import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Bar from "../../bar";

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogContent, Grid, IconButton, LinearProgress, ListItemText, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Add, Delete, Edit, Visibility } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function AdminCourseFolders({api}){
    let history = useHistory()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { path, url } = useRouteMatch()

    const { onlinetopicid } = useParams()

    const [folders, setFolders] = useState([])
    const [fwarn, setFwarn] = useState('')
    const [floading, setFloading] = useState(true)
    useEffect(() => {
        get_folders(onlinetopicid)
    }, [onlinetopicid])
    const get_folders = (onlinetopicid) => {
        setFwarn('')
        var formData = new FormData()
        formData.append('online-course-folders', onlinetopicid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setFloading(false)
            if(data.warning){
                setFwarn(data.warning)
            } else {
                setFolders(data)
            }
        })
    }

    return(
        <Box>
            <Bar text='Topic folder' />
            <IconButton sx={{position: 'fixed', bottom: 50, right: 30, zIndex: 100}} size="large" color="error" onClick={() => history.push(`${url}/create/0`)} ><Add/></IconButton>
            <Container>
                <Grid container mt={3} mb={3} >
                    <Grid item xs={12} md={6} mx='auto' >
                        { floading ? <LinearProgress color="primary" /> :
                        fwarn ? <Alert severity="warning">{fwarn}</Alert> :
                        folders.map((data, i) =>
                        <Toolbar component={Paper} sx={{pt: 3, pb: 3, marginBottom: 1}} key={i} >
                            <img src="/images/video-folder.png" alt="Topic" style={{width: 70}} />
                            <ListItemText primary={<Typography fontWeight='bold' variant="body2" >{data.ocf_name}</Typography>} secondary={`${data.videos} Videos`} />

                            <IconButton color="error" onClick={() => history.push(`${url}/delete/${data.onlinefolderid}`)} ><Delete/></IconButton>
                            <IconButton color="info" onClick={() => history.push(`${url}/create/${data.onlinefolderid}`)} ><Edit/></IconButton>
                            <IconButton color="primary" onClick={() => history.push(`/online-course-video/${data.onlinefolderid}`)} ><Visibility/></IconButton>
                        </Toolbar> )}
                    </Grid>
                </Grid>
            </Container>
            <Route path={`${path}/create/:onlinefolderid`} component={Create} />
            <Route path={`${path}/delete/:onlinefolderid`} component={Delete_content} />
        </Box>
    );

    function Create(){
        const { onlinefolderid } = useParams()
        

        const [name, setName] = useState('')
        const [addwarn, setAddwarn] = useState('')
        const [working, setWorking] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('online-folder-detail', onlinefolderid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.ocf_name)
                }
            })
        }, [onlinefolderid])

        const create = () => {
            setAddwarn(''); setWorking(true)
            var formData = new FormData()
            formData.append('create-online-folder', onlinetopicid)
            formData.append('onlinefolderid', onlinefolderid)
            formData.append('name', name)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setAddwarn(data.warning)
                } else {
                    get_folders(onlinetopicid); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth maxWidth='xs' onClose={() => history.go(-1)} >
                <Bar text='Add folder' />
                <DialogContent>
                    <Stack spacing={1.5} pt={3} pb={3} >
                        <TextField variant="outlined" type="text" label='Folder name' fullWidth value={name} onChange={(event) => setName(event.target.value)} />
                        {addwarn && <Alert severity="warning">{addwarn}</Alert> }
                        <Box textAlign='center' >
                            <Button variant="contained" color="primary" disabled={working} onClick={() => create()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }

    function Delete_content(){
        const { onlinefolderid } = useParams()
        const [dwarn, setDwarn] = useState('')
        const [working, setWorking] = useState(false)

        const delete_content = () => {
            setWorking(true)
            var formData = new FormData()
            formData.append('delete-online-folder', onlinefolderid)
            formData.append('onlinetopicid', onlinetopicid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setDwarn(data.warning)
                } else {
                    get_folders(onlinetopicid); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => history.go(-1)}>
                <DialogContent>
                    <Box pt={3} pb={3}>
                        <Typography fontSize={25} fontWeight='bold' mb={1} >Do you want to delete this folder?</Typography>
                        <Typography fontSize={12} color='red' mb={1} >Note: When you delete this folder you will delete all the videos releted to this folder. And you can't undo this process.</Typography>
                        
                        {dwarn && <Alert severity="warning">{dwarn}</Alert> }
                        <Box mt={3} textAlign='center'>
                            <Button variant="contained" color="error" disabled={working} onClick={() => delete_content()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Yes, Delete'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

}