import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Bar from "../bar";

// MUI
import { Alert, Box, Container, Grid, LinearProgress, ListItemText, Paper, Toolbar, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function TopicFoldersStudent({api}){
    let history = useHistory()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { path, url } = useRouteMatch()

    const { onlinetopicid } = useParams()

    const [folders, setFolders] = useState([])
    const [folderwarn, setFolderwarn] = useState('')
    const [loadfolder, setLoadfolder] = useState(true)
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-folders', onlinetopicid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoadfolder(false)
            if(data.warning){
                setFolderwarn(data.warning)
            } else {
                setFolders(data)
            }
        })
    }, [onlinetopicid])

    return(
        <Box>
            <Bar text='Topic folders' />

            <Container>
                <Grid container mt={3} mb={3} >
                    <Grid item xs={12} md={7} mx='auto' >
                        <Box component={Paper} p={2} pt={3} pb={3} mb={2} >
                            <Typography letterSpacing={2} color='orange' fontWeight='bold' fontSize={25} align="center" >Folders</Typography>
                        </Box>

                        { loadfolder ? <LinearProgress color="primary" /> :
                        folderwarn ? <Alert severity="warning">{folderwarn}</Alert> :
                        folders.map((data, i) =>
                        <Toolbar component={Paper} sx={{pt: 3, pb: 3, mb: 2, cursor: 'pointer'}} key={i} onClick={() => history.push(`/folder-videos-student/${data.onlinefolderid}`)} >
                            <img src="/images/video-folder.png" style={{width: 60}} />
                            <ListItemText primary={<Typography fontWeight='bold' >{data.ocf_name}</Typography>} secondary={`${data.videos} Videos`} />
                        </Toolbar>)}
                    </Grid>
                </Grid>
            </Container>

        </Box>
    );
}