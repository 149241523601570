import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Header from './header';

/* MUI */
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, LinearProgress, ListItemText, Paper, Stack, Toolbar, Typography } from '@mui/material'
import {  } from '@mui/icons-material';

export default function CourseDetailStudent({api, img}){
    let history = useHistory()

    const { onlinecourseid } = useParams()

    // COURSE DETAILS
    const [cd, setCd] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-course-detail', onlinecourseid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setCd(data)
            }
        })
    }, [onlinecourseid])

    const [videos, setVideos] = useState([])
    const [vwarn, setVwarn] = useState('')
    const [vloading, setVloading] = useState(true)
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-videos', onlinecourseid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setVloading(false)
            if(data.warning){
                setVwarn(data.warning)
            } else {
                setVideos(data)
            }
        })
    }, [onlinecourseid])

    const [buyDialog, setBuydialog] = useState(false)
    const [buying, setBuying] = useState(false)
    const [buywarn, setBuywarn] = useState('')
    const buy_course = () => {
        setBuywarn(''); setBuying(true)
        var formData = new FormData()
        formData.append('online-course-purchase', onlinecourseid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setBuying(false)
            if(data.warning){
                setBuywarn(data.warning)
            } else if(data.success){
                window.location.reload()
            }
        })
    }

    const [topics, setTopics] = useState([])
    const [topicswarn, setTopicswarn] = useState('')
    const [loadtopics, setLoadtopics] = useState(true)
    useEffect(() => {
        setTopicswarn('')
        var formData = new FormData()
        formData.append('oc-topics', onlinecourseid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoadtopics(false)
            if(data.warning){
                setTopicswarn(data.warning)
            } else {
                setTopics(data)
            }
        })
    }, [onlinecourseid])

    return(
        <Box>
            <Header back={true} />
            <Container sx={{marginTop: 3, marginBottom: 7}} >
                <Grid container>
                    <Grid item xs={12} md={8} mx='auto' >
                        <Box sx={{background: '#dee2e6'}} padding={2} borderRadius={3} mb={2} >
                            <Stack mb={2} direction={{xs: 'column', md: 'row'}} alignItems='center' justifyContent='space-between' spacing={3} >
                                <img src={`${img}/${cd.oc_thumbnail}`} alt='Course image' style={{width: '300px', borderRadius: '10px'}} />
                                <Stack direction='row' alignItems='center' justifyContent='space-evenly' sx={{width: '100%', background: '#fafafa'}} padding={2} borderRadius={3} >
                                    <Typography fontSize={30} fontWeight='bold' color='black' >&#8377; {Number(cd.oc_price).toLocaleString('en-in')}</Typography>
                                    <Typography fontSize={15} sx={{textDecoration: 'line-through'}} >&#8377; {Number(cd.oc_mrp).toLocaleString('en-in')}</Typography>
                                    <Button sx={{background: '#fafafa'}} disabled ><Typography color='green' >{Number((Number(cd.oc_mrp) - Number(cd.oc_price)) * 100 / Number(cd.oc_mrp)).toFixed(0) }% OFF</Typography></Button>
                                </Stack>
                            </Stack>

                            {cd.purchased === '0' && <><Divider/><Box mt={2} textAlign='center' >
                                <Button variant='contained' sx={{boxShadow: 0, borderRadius: 0, paddingLeft: 6, paddingRight: 6, pt: 2, pb: 2}} onClick={() => setBuydialog(true)} >BUY NOW</Button>
                            </Box></>}
                        </Box>

                        <Box sx={{background: '#dee2e6'}} padding={2} borderRadius={3} mb={2} >
                            <Typography variant='h6' fontWeight='bold' >{cd.oc_name}</Typography>

                            <Typography variant='body1' fontWeight='bold' color='black' >About the course</Typography>
                            <Typography variant='body2' >{cd.oc_about}</Typography>

                            <Typography variant='body1' fontWeight='bold' color='black' mt={2} >Includes</Typography>
                            <Typography variant='body2' >14 Videos, Full time access, Access from anywhere, New skills, Certificate on completion</Typography>

                            <Typography variant='body1' fontWeight='bold' color='black' mt={2} >Watch limit</Typography>
                            <Typography variant='body2' >We have limited the video to the {cd.oc_watch_time} Days.</Typography>
                            <Typography variant='body2'>Note: After bought, some student take too much time to complete view of 1 course. This losses the time. So we limited the video timing to watch videos on time.</Typography>
                            <Typography variant='body2'>*Due to some reason if your video is locked you can contact us to unlock your course.</Typography>
                        </Box>

                        <Box component={Paper} padding={2}>
                            <Typography align='center' fontWeight='bold' color='orange' mb={3} fontSize={25} letterSpacing={2} >Topics</Typography><Divider/>
                            
                            { loadtopics ? <LinearProgress color='primary' /> :
                            topicswarn ? <Alert severity='warning'>{topicswarn}</Alert> :
                            topics.map((data, i) =>
                            <Box key={i} onClick={() => cd.purchased === '1' && history.push(`/topic-folders-student/${data.onlinetopicid}`)} ><Toolbar sx={{pt: 3, pb: 3, cursor: 'pointer'}} >
                                <img src='/images/trending.svg' alt='Online topics' style={{width: 60}} />
                                <ListItemText primary={<Typography fontWeight='bold' >{data.oct_name}</Typography>} secondary={`${data.folders} Folders`} />
                            </Toolbar><Divider/></Box>)}
                        </Box>
                        
                    </Grid>
                </Grid>
            </Container>

            <Dialog open={buyDialog} fullWidth={true} maxWidth='xs' onClose={() => setBuydialog(false)} >
                <DialogContent>
                    <Typography fontSize={22} fontWeight='bold' color='black' >Do you want to confirm purchase?</Typography>
                    <Typography fontSize={18} color='black' mt={1} mb={1} >Price: {cd.oc_price} = {Math.round(parseInt(cd.oc_price)/10)} Coins</Typography>
                    { buywarn && <Alert severity='warning'>{buywarn}</Alert> }
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant='text' onClick={() => setBuydialog(false)} >CANCEL</Button>
                    <Button variant='contained' color='primary' disabled={buying} onClick={() => buy_course()} >{buying ? <CircularProgress color='inherit' size={24} /> : 'YES, CONFIRM'}</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );

}