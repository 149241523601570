import React, { useEffect } from "react";

import PagesHeader from "./header";
import PagesFooter from "./footer";

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function PagesSecurity(){

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, []);

    return(
        <Box><PagesHeader />
            <Container>
                <Grid container mt={4} >
                    <Grid item xs={12} md={9} mx='auto' >
                        <Box>
                            <Typography fontSize={25} fontWeight='bold' mb={2} id='privacy' >Privacy Policy:</Typography>

                            <Typography variant="body1" color='gray' >At Radiant Institute of Advance Technology, we take the privacy of our users very seriously. We understand the importance of protecting your personal information and are committed to ensuring that it is collected, used, and protected in accordance with this Privacy Policy.</Typography>

                            <Typography fontWeight='bold' mt={3} >Information Collection:</Typography>
                            <Typography variant="body1" color='gray' >We may collect personal information from you when you register for our courses, such as your name, email address, phone number, and payment information. We may also collect non-personal information such as your IP address, browser type, and operating system.</Typography>

                            <Typography fontWeight='bold' mt={3} >Information Use:</Typography>
                            <Typography variant="body1" color='gray' >We use the information collected to provide you with the services you have requested, to communicate with you, and to improve our courses and services. We may also use your information to provide you with promotional materials.</Typography>

                            <Typography fontWeight='bold' mt={3} >Information Sharing:</Typography>
                            <Typography variant="body1" color='gray' >We do not sell, rent, or share your personal information with third parties except as required by law or to fulfill your requests. We may share your information with service providers who perform services on our behalf.</Typography>

                            <Typography fontWeight='bold' mt={3} >Data Security:</Typography>
                            <Typography variant="body1" color='gray' >We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no security measures are foolproof and we cannot guarantee the security of your information.</Typography>

                            <Typography fontSize={25} fontWeight='bold' mb={2} mt={3} id='term' >Terms & Conditions:</Typography>
                            <Typography variant="body1" color='gray' >Please read these terms and conditions carefully before registering for our courses. By registering for our courses, you agree to these terms and conditions.</Typography>
                            
                            <Typography fontWeight='bold' mt={3} >Registration:</Typography>
                            <Typography variant="body1" color='gray' >To register for our courses, you must be at least 16 years of age and provide accurate and complete information. You may be required to create an account and choose a password to access the course materials.</Typography>

                            <Typography fontWeight='bold' mt={3} >Course Materials:</Typography>
                            <Typography variant="body1" color='gray' >We own all intellectual property rights to the course materials and they may not be copied or distributed without our permission. You may use the course materials only for your personal use and may not share them with others.</Typography>

                            <Typography fontWeight='bold' mt={3} >Course Access:</Typography>
                            <Typography variant="body1" color='gray' >You will have access to the course materials for the duration of the course period. After the course period, your access to the course materials will be terminated.</Typography>

                            <Typography fontWeight='bold' mt={3} >Course Completion:</Typography>
                            <Typography variant="body1" color='gray' >To receive a certificate of completion, you must complete all required coursework and assessments. We reserve the right to verify your identity and may require you to take a proctored exam to ensure that you have completed the coursework yourself.</Typography>

                            <Typography fontSize={25} fontWeight='bold' mb={2} id='cr' mt={3} >Cancellation & Refund:</Typography>
                            <Typography variant="body1" color='gray' >If you wish to cancel your registration for our courses, please contact us at least 48 hours before the course start date.</Typography>
                            
                            <Typography fontWeight='bold' mt={3} >Refunds:</Typography>
                            <Typography variant="body1" color='gray' >Refunds will be issued on a case-by-case basis. If you cancel before the course start date, we will refund your registration fee minus a processing fee. If you cancel after the course start date, we may issue a partial refund based on your course progress.</Typography>
                            
                            <Typography fontWeight='bold' mt={3} >Non-refundable Fees:</Typography>
                            <Typography variant="body1" color='gray' >Please note that some fees, such as course materials fees, are non-refundable. We will inform you of any non-refundable fees at the time of registration.</Typography>

                            <Typography fontWeight='bold' mt={3} >Course Changes or Cancellations:</Typography>
                            <Typography variant="body1" color='gray' >We reserve the right to cancel courses or change course dates and times at any time. In the event of a cancellation or change, we will provide a full refund.</Typography>

                            <Typography fontWeight='bold' mt={3} >Contact Us:</Typography>
                            <Typography variant="body1" color='gray' >If you have any questions or concerns about this Privacy Policy or our Terms & Conditions, please contact us at <a href="mailto: info@radiantiteducation.com">info@radiantiteducation.com</a> .</Typography>
                            

                        
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        <PagesFooter/></Box>
    );
}