import React, { useEffect, useState } from 'react'

import Close from './../close'

/* MUI */
import { Alert, Snackbar, Dialog, DialogContent, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useParams } from 'react-router-dom'

export default function AttendanceTeacher({api}){
    let { batchid } = useParams()
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        viewAttendance()
    }, [])
    
    const [attendances, setAttendances] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewAttendance = () => {
        var formData = new FormData()
        formData.append('batch-students', batchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setAttendances(data)
            }
        })
    }

    const makeAttendance = (studentid) => {
        var formData = new FormData()
        formData.append('make-attendance', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success){
                setMessage('Attendance succeed!'); setOpen(true)
                viewAttendance()
            }
        })
    }

    return(
        <>
        <Dialog open={true} fullScreen>
            <Snackbar open={open} message={message} onClose={() =>  setOpen(false)} />
            <DialogTitle><Close title='Make attendance' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid mb-5'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row'>
                                { loading ? <LinearProgress color='warning' /> :
                                warning ? <Alert severity='warning'>{warning}</Alert> :
                                <div className='col-12'>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>NAME</TableCell>
                                                    <TableCell align='center'>CODE</TableCell>
                                                    <TableCell align='center'>AT IN</TableCell>
                                                    <TableCell align='right'>AT OUT</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { attendances.map((at, i) =>
                                                <TableRow key={i} onClick={() => makeAttendance(at.studentid)} >
                                                    <TableCell align='left'>{at.s_name}</TableCell>
                                                    <TableCell align='center'>{at.s_code}</TableCell>
                                                    <TableCell align='center'>{at.at_in}</TableCell>
                                                    <TableCell align='right'>{at.at_out}</TableCell>
                                                </TableRow> ) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        </>
    );
}