import React, { useState } from "react";
import {  } from 'react-router-dom'

import PagesHeader from "./header";

// MUI
import { Alert, Box, CircularProgress, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'

export default function Verification({api, img}){

    const [enrollment, setenrollment] = useState('')
    const [student, setStudent] = useState([])
    const [result, setResult] = useState([])
    const [tspeed, setTspeed] = useState('')
    const [studentwarn, setStudentwarn] = useState('')
    const [working, setWorking] = useState(false)
    const verify = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('verify', enrollment)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.warning){
                setStudentwarn(data.warning)
            } else {
                setStudent(data.student)
                setResult(data.result); setTspeed(data.tspeed.r_typing)
            }
        })
    }

    return(
        <Box><PagesHeader />
            <Container>
                <Grid container mt={3} mb={3} sx={{height: '40vh'}} display='flex' justifyContent='center' alignItems='center' >
                    <Grid item xs={12} md={7} mx='auto'>
                        <Typography fontSize={35} letterSpacing={2} fontWeight='bold' fontFamily='serif' align="center" mb={5} color='orange' >Verify your entity!</Typography>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Enter enrollment number</InputLabel>
                            <OutlinedInput
                                type='type'
                                value={enrollment}
                                onChange={(event) => setenrollment(event.target.value)}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    edge="end"
                                    onClick={() => verify()}
                                    >
                                    {working ? <CircularProgress color="inherit" size={24} /> : <Search /> }
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Enter enrollment number"
                            />
                        </FormControl>

                        <Box mt={4} >
                            <Typography color='red' variant="body2" fontWeight='lighter' align="center" >Result will appear here...</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3} display='flex' justifyContent='center' alignItems='center' >
                    <Grid item xs={12} md={7} mx='auto'>
                    { studentwarn ? <Alert severity="warning">{studentwarn}</Alert> :
                        <Box p={2} >
                            { student.length != 0 &&
                            <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'inherit', md: 'center'}} >
                                <img src={`${img}/${student['s_pic']}`} style={{width: 200, height: 220}} alt="Student" />
                                <Box ml={{xs: 0, md: 3}} mt={{xs: 3, md: 0}} sx={{width: '100%'}} >
                                    <Stack direction='row' justifyContent='space-between' mb={1} >
                                        <Typography color='gray' >Name</Typography>
                                        <Typography color='black' >{student['s_name']}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' mb={1} >
                                        <Typography color='gray' >Guardian</Typography>
                                        <Typography color='black' >{student['s_guardian']}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' mb={1} >
                                        <Typography color='gray' >Birth date</Typography>
                                        <Typography color='black' >{student['s_birth']}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' mb={1} >
                                        <Typography color='gray' >Address</Typography>
                                        <Typography color='black' >{student['s_address']}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' mb={1} >
                                        <Typography color='gray' >Registration date</Typography>
                                        <Typography color='black' >{student['s_register']}</Typography>
                                    </Stack>
                                    <Stack direction='row' justifyContent='space-between' >
                                        <Typography color='gray' >Issue date</Typography>
                                        <Typography color='black' >{student['s_issuedate']}</Typography>
                                    </Stack>
                                    {tspeed.length > 0 && <Stack direction='row' justifyContent='space-between' >
                                        <Typography color='gray' >Typing speed</Typography>
                                        <Typography color='black' >{tspeed}</Typography>
                                    </Stack> }
                                </Box>
                            </Stack> }
                            { result != false &&
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Term</TableCell>
                                            <TableCell>Subject</TableCell>
                                            <TableCell>Full marks</TableCell>
                                            <TableCell>Pass marks</TableCell>
                                            <TableCell>Obtained</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result.map((data, i) =>
                                        <TableRow key={i} >
                                            <TableCell>{data.s_term}</TableCell>
                                            <TableCell>{data.s_subject}</TableCell>
                                            <TableCell>{data.s_fmark}</TableCell>
                                            <TableCell>{data.s_pmark}</TableCell>
                                            <TableCell>{data.r_marks}</TableCell>
                                        </TableRow>) }
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </Box> }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}