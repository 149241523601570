import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Bar from "../bar";

// MUI
import { Alert, Box, Card, CardActionArea, CardContent, CardMedia, Container, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function FolderVideosStudent({api, img}){
    let history = useHistory()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { path, url } = useRouteMatch()

    const { onlinefolderid } = useParams()

    const [videos, setVideos] = useState([])
    const [videowarn, setVideowarn] = useState('')
    const [loadvideo, setLoadvideo] = useState(true)
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-videos', onlinefolderid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoadvideo(false)
            if(data.warning){
                setVideowarn(data.warning)
            } else {
                setVideos(data)
            }
        })
    }, [onlinefolderid])

    return(
        <Box>
            <Bar text='Folder videos' />

            <Container sx={{mt: 2}} >
                { loadvideo ? <LinearProgress color="primary"/> :
                videowarn ? <Alert severity="warning">{videowarn}</Alert> :
                <Grid container mt={1} mb={3} spacing={2} >
                    <Grid item xs={12} >
                        <Box component={Paper} p={2} pt={3} pb={3} >
                            <Typography letterSpacing={2} color='orange' fontWeight='bold' fontSize={25} align="center" >Videos</Typography>
                        </Box>
                    </Grid>
                    
                    {videos.map((data, i) =>
                    <Grid item xs={12} md={3} onClick={() => history.push(`/video-player-student/${data.ov_path}`)} key={i} >
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                height="200"
                                image={`${img}/${data.ov_thumbnail}`}
                                alt="Video thumbnail"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="body1" fontWeight='bold' component="div" sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }}>
                                        {data.ov_title}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary" sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }}>
                                        {data.ov_about}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid> ) }
                </Grid> }
            </Container>

        </Box>
    );
}