import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import Bar from './../bar'

/* MUI */
import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Paper, Snackbar, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { ContentCopy, History, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Send } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function StudentOnlineCourseAdmin({api, img}){

    const { studentid } = useParams()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const history = useHistory()
    const { path, url } = useRouteMatch()

    const [scourse, setScourse] = useState([])
    const [sloading, setSloading] = useState(true)
    const [swarn, setSwarn] = useState('')
    useEffect(() => {
        sold_course(studentid)
    }, [studentid])
    const sold_course = (studentid) => {
        setSwarn('')
        var formData = new FormData()
        formData.append('oc-student-course', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setSloading(false)
            if(data.warning){
                setSwarn(data.warning)
            } else {
                setScourse(data)
            }
        })
    }

    const [courses, setCourses] = useState([])
    const [cwarn, setCwarn] = useState('')
    const [cloading, setCloading] = useState(true)
    useEffect(() => {
        setCwarn('')
        var formData = new FormData()
        formData.append('online-course', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setCloading(false)
            if(data.warning){
                setCwarn(data.warning)
            } else {
                setCourses(data)
            }
        })
    }, [])

    const [sedialog, setSedialog] = useState(false)
    const [onlinecourseid, setOnlinecourseid] = useState('')
    const [amount, setAmount] = useState('')

    const [sewarn, setSewarn] = useState('')
    const [selling, setSelling] = useState(false)
    const sell_alert = (onlinecourseid, amount) => {
        setSedialog(true); setOnlinecourseid(onlinecourseid); setAmount(amount)
    }
    const sell_course = () => {
        setSelling(true); setSewarn('')
        var formData = new FormData()
        formData.append('oc-sell-course', studentid)
        formData.append('onlinecourseid', onlinecourseid)
        formData.append('amount', amount)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setSelling(false)
            if(data.warning){
                setSewarn(data.warning)
            } else if(data.success) {
                sold_course(studentid); setSedialog(false)
            }
        })
    }

    useEffect(() => {
        student_coin(studentid)
    }, [studentid])

    const [coins, setCoins] = useState([])
    const student_coin = (studentid) => {
        var formData = new FormData()
        formData.append('coin-student-coin', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setCoins(data)
            }
        })
    }

    const [scoin, setScoin] = useState('')
    const [scalert, setScalert] = useState(false)
    const [sellotp, setSellotp] = useState('')
    const [cselling, setCselling] = useState(false)
    const [scwarn, setScwarn] = useState('')
    const sell_coin = () => {
        setScwarn(''); setCselling(true)
        var formData = new FormData()
        formData.append('coin-sell', studentid)
        formData.append('coins', scoin)
        formData.append('otp', sellotp)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setCselling(false)
            if(data.warning){
                setScwarn(data.warning)
            } else {
                student_coin(studentid)
                setScoin(''); setScwarn(''); setScalert(false)
            }
        })
    }

    const [otpsending, setOtpsending] = useState(false)
    const send_otp = () => {
        setOtpsending(true)
        var formData = new FormData()
        formData.append('send-otp-to-admin', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setOtpsending(false)
            if(data.warning){
                setMessage(data.warning); setOpenm(true)
            } else {
                setScalert(true)
            }
        })
    }

    const [message, setMessage] = useState('')
    const [openm, setOpenm] = useState(false)
    const copy_text = (event) => {
        navigator.clipboard.writeText(event)
        setMessage('Copied'); setOpenm(true)
    }

    return(
        <Box>
        <SidebarAdmin />
        <Snackbar message={message} open={openm} onClose={() => setOpenm(false)} />
        <Box className='content'>
            <Container>

                <Grid container spacing={2} mb={3} >
                    <Grid item xs={12} md={6}>
                        <Box component={Paper} padding={1.5} >
                            <Toolbar>
                                <Typography color='gray' variant='body1' sx={{flexGrow: 1}} >Available coin <IconButton size='small' onClick={() => history.push(`${url}/coin-history`)} ><History /></IconButton></Typography>

                                <img src='/images/coin.gif' alt='Coin' style={{width: 30}} />
                                <Typography fontWeight='bold' fontSize={16} color='black' letterSpacing={2} >{parseInt(coins.acoin) - parseInt(coins.redeemed)}</Typography>
                            </Toolbar>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component={Paper} padding={2} >
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Sell coin</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type='Number'
                                    value={scoin}
                                    onChange={(event) => setScoin(event.target.value)}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        edge="end"
                                        disabled={scoin.length > 0 ? false : true}
                                        onClick={() => send_otp()}
                                        >
                                            {otpsending === true ? <CircularProgress color='inherit' size={24} /> : <Send/> }
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Sell coin"
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Stack component={Paper} padding={3} direction={{xs: 'column', md: 'row'}} justifyContent='space-between'>
                    <Typography color='black' variant='body2' onClick={() => copy_text(coins.s_ac)} >A/C Number: {coins.s_ac} <IconButton><ContentCopy/></IconButton> </Typography>
                    <Typography color='black' variant='body2' onClick={() => copy_text(coins.s_ifsc)} >IFSC: {coins.s_ifsc} <IconButton><ContentCopy/></IconButton></Typography>
                    <Typography color='black' variant='body2' onClick={() => copy_text(coins.s_upi)} >UPI ID: {coins.s_upi} <IconButton><ContentCopy/></IconButton></Typography>
                </Stack>
                
                <Divider/>

                <Typography variant='h6' gutterBottom fontWeight='bold' mt={3} >Bought courses</Typography>

                { sloading ? <LinearProgress color='primary' /> :
                swarn ? <Alert severity='warning'>{swarn}</Alert> :
                <Grid container spacing={2} >
                    { scourse.map((data, i) =>
                    <Grid item xs={12} md={3} key={i} >
                        <Card>
                            <CardMedia title='Course thumbnail' sx={{height: 120}} image={`${img}/${data.oc_thumbnail}`}/>
                            <CardContent>
                                <Typography fontSize={16} fontWeight='bold' mb={1} sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_name}</Typography>
                                <Typography variant='body2' color='gray' mb={1} sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_about}</Typography>

                                <Divider/>
                                <Typography variant='body2' color='gray' mt={1} >Price: {Number(parseInt(data.oc_price)).toLocaleString('en-in')} &#8377;</Typography>
                            </CardContent>
                        </Card>
                    </Grid>) }
                </Grid>}

                <Typography variant='h6' gutterBottom fontWeight='bold' mt={4} >Online courses</Typography>
                { cloading ? <LinearProgress color='primary' /> :
                cwarn ? <Alert severity='warning'>{cwarn}</Alert> :
                <Grid container spacing={2} >
                    { courses.map((data, i) =>
                    <Grid item xs={12} md={3} key={i} >
                        <Card>
                            <CardMedia title='Course thumbnail' sx={{height: 120}} image={`${img}/${data.oc_thumbnail}`}/>
                            <CardContent>
                                <Typography fontSize={16} fontWeight='bold' mb={1} sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_name}</Typography>
                                <Typography variant='body2' color='gray' mb={1} sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_about}</Typography>

                                <Divider/>
                                <Typography variant='body1' color='black' mt={1} fontWeight='bold' >Price: {Number(parseInt(data.oc_price)).toLocaleString('en-in')} &#8377;</Typography>
                            </CardContent><Divider/>
                            <CardActions>
                                <Button color='primary' variant='contained' sx={{boxShadow: 0, borderRadius: 0}} onClick={() => sell_alert(data.onlinecourseid, data.oc_price)} >SELL COURSE</Button>
                            </CardActions>
                        </Card>
                    </Grid>) }
                </Grid>}
            </Container>

            <Dialog open={sedialog} fullWidth onClose={() => setSedialog(false)} >
                <DialogTitle>Alert</DialogTitle>
                <DialogContent>
                    <Typography variant='h6' color='black' >Do you want to sell this course to this student?</Typography>
                    <Typography variant='body2' color='gray' >Note: This is manually payment method.</Typography>
                    <Typography variant='body2' color='gray' mb={1} >After confirm this process the course will be assign to this student. And this process can't be undone.</Typography>
                    { sewarn && <Alert severity='warning'>{sewarn}</Alert> }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSedialog(false)} >CANCEL</Button>
                    <Button color='primary' variant='contained' onClick={() => sell_course()} disabled={selling} >{selling ? <CircularProgress color='inherit' size={24} /> : 'YES, SELL'}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={scalert} fullWidth={true} maxWidth='xs' onClose={() => setScalert(false)}>
                <DialogTitle>Alert</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={2}>
                        <Box>
                            <Typography fontSize={18} fontWeight='bold' color='orange' >You are paying '{scoin}' Coins to '{coins.name}'</Typography>
                            <Typography fontSize={14} mb={1} color='gray' >Do you want to confirm it?</Typography>
                        </Box>
                        <TextField variant='outlined' type='number' label='OTP' value={sellotp} onChange={(event) => setSellotp(event.target.value)} />
                        { scwarn && <Alert severity='warning'>{scwarn}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant='text' onClick={() => setScalert(false)} >NO</Button>
                    <Button variant='contained' color='primary' disabled={cselling} onClick={() => sell_coin()} >{cselling ? <CircularProgress color='inherit' size={24} /> : 'YES CONFIRM'}</Button>
                </DialogActions>
            </Dialog>
        </Box>

        <Route path={`${path}/coin-history`} component={Coin_history} />
        </Box>
    );

    function Coin_history(){

        const [chis, setChis] = useState([])
        const [hiswarn, setHiswarn] = useState('')
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            setHiswarn('')
            var formData = new FormData()
            formData.append('coin-history', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setHiswarn(data.warning)
                } else {
                    setChis(data)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => history.go(-1)}>
                <Bar text='Coin history' /><Divider/>
                <DialogContent>
                    { loading ? <LinearProgress color='inherit' size={24} /> :
                    hiswarn ? <Alert severity='warning'>{hiswarn}</Alert> :
                    chis.map((data, i) =>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' component={Paper} padding={2.5} key={i} mb={2} >
                        <Typography variant='body2' >{data.coin_date}</Typography>
                        <Typography variant='body2' >{Number(parseInt(data.coin))}</Typography>
                        {data.coin_status === 'active' ? <Typography variant='body2' color='green' >Received <KeyboardDoubleArrowDown color='success' /></Typography> : data.coin_status === 'redeemed' && <Typography variant='body2' color='#d93830' >Redeemed <KeyboardDoubleArrowUp color='error' /></Typography> }
                    </Stack> )}
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant='text' onClick={() => history.go(-1)} >CLOSE</Button>
                </DialogActions>
            </Dialog>
        );
    }
    
}