import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';


// MATERIAL UI
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from '@mui/material'
import { Book, ConnectWithoutContact, Home, Login, Menu, WorkspacePremium } from '@mui/icons-material'


export default function PagesHeader(){

    const links = [
        {name: 'Home', link: '/', icon: <Home />},
        {name: 'Verification', link: '/verification', icon: <WorkspacePremium />},
        {name: 'Course', link: '/course', icon: <Book/>},
        {name: 'Contact', link: '/contact', icon: <ConnectWithoutContact/>},
        {name: 'Login', link: '/dashboard-student', icon: <Login/>}]

    const [open, setOpen] = useState(false)

    return(
        <><AppBar position='sticky' sx={{boxShadow: 0}} >
            <Toolbar>
                <Box flex={1} >
                    <Link to='/'><Typography fontFamily='serif' fontSize={30} color='white' fontWeight='bold' letterSpacing={2} >RIAT</Typography></Link>
                </Box>

                <Stack direction='row' spacing={3} display={{xs: 'none', md: 'block'}}>
                    { links.map((data, i) =>
                    <NavLink to={data.link} key={i} style={{color: 'white', fontWeight: 'lighter'}} >{data.name}</NavLink>) }
                </Stack>
                <Stack display={{xs: 'block', md: 'none'}}>
                    <IconButton onClick={() => setOpen(true)} color='inherit' ><Menu/></IconButton>
                </Stack>
            </Toolbar>
        </AppBar>
        <Drawer open={open} anchor='bottom' onClose={() => setOpen(false)} PaperProps={{style: {borderTopLeftRadius: 25, borderTopRightRadius: 25}}} >
            <List>
                { links.map((data, i) =>
                <NavLink key={i} to={data.link} style={{color: '#000000'}} >
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>{data.icon}</ListItemIcon>
                            <ListItemText>{data.name}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </NavLink>) }
            </List>
        </Drawer>
        </>
    );
}