import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Certificate  from './../images/marksheet.JPG'

import Bar from './../bar'

/* MUI */
import { Button, Box, Container, Typography, Grid } from '@mui/material'

export default function Marksheet(){
    const { studentid } = useParams()
    let history = useHistory()

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch('/radiantapi/print-view/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    const [results, setResults] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('marksheet-result', studentid)
        fetch('/radiantapi/print-view/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setResults(data)
            }
        })
    }, [studentid])

    useEffect(()  => {
        document.title = print.s_name
    }, [print])

    const area = useRef()

    return(
        <Box><Bar text='Marksheet' print={true} area={area} />
            <Container>
                <Box mt={4} mb={4} textAlign='center' >
                    <Button color='primary' variant='outlined' className='mt-2' onClick={() => history.push(`/certificate/${studentid}`)} >Go to certificate</Button>
                </Box>
                <Box overflow='scroll' >
                    <Box sx={{position: 'relative', height: '296mm', width: '210mm', margin: 'auto'}} ref={area} padding={2} >
                        <img src={Certificate} alt='Marksheet' style={{width: '100%', height: '100%'}} />

                        <Typography sx={{position: 'absolute', top: '77.3mm', left: '64.5mm'}} fontSize={12} fontWeight='bold' >{print.s_enrollment}</Typography>
                        <img src={`/radiantassets/studentimg/${print.s_pic}`} alt='Student' style={{width: '125px', height: '140px', position: 'absolute', top: '57mm', right: '33mm'}} />
                        
                        <Typography sx={{position: 'absolute', top: '92.2mm', left: '87mm'}} fontSize={12} fontWeight='bold' >{print.s_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '101mm', left: '87mm'}} fontSize={12} fontWeight='bold' >{print.s_guardian}</Typography>
                        <Typography sx={{position: 'absolute', top: '110.5mm', left: '80mm'}} fontSize={12} fontWeight='bold' >{print.c_title} ({print.c_name})</Typography>
                        <Typography sx={{position: 'absolute', top: '120.5mm', left: '88mm'}} fontSize={12} fontWeight='bold' >{print.c_duration} MONTHS</Typography>
                        <Typography sx={{position: 'absolute', top: '130mm', left: '80mm'}} fontSize={12} fontWeight='bold' >{print.b_name}</Typography>

                        <Box sx={{position: 'absolute', top: '158mm', left: '37mm', width: '138.5mm'}} >
                            { results.map((data, i) =>
                            <Grid container key={i} >
                                <Grid item xs={0.9} >
                                    <Typography textAlign='center' fontSize={14} mt={0.3} >{data.s_term}</Typography>
                                </Grid>
                                <Grid item xs={8} >
                                    <Typography ml={1} fontSize={10} mt={0.7} >{data.s_subject}</Typography>
                                </Grid>
                                <Grid item xs={1.6} >
                                    <Typography fontSize={14} mt={0.3} textAlign='center' >{data.s_fmark}</Typography>
                                </Grid>
                                <Grid item xs={1.5} >
                                    <Typography fontSize={14} textAlign='center' mt={0.3} >{data.r_marks}</Typography>
                                </Grid>
                            </Grid> ) }
                        </Box>

                        <Typography sx={{position: 'absolute', bottom: '69.4mm', left: '73mm'}} fontSize={12} fontWeight='bold' >{Number(print.marks) * 100 / Number(print.fullMarks) }%</Typography>
                        <Typography sx={{position: 'absolute', bottom: '69.4mm', left: '111mm'}} fontSize={12} fontWeight='bold' >{(Number(print.marks) * 100 / Number(print.fullMarks)) >= 85 ? 'A+' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 70 ? 'A' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 60 ? 'B' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 45 && 'C'  }</Typography>
                        <Typography sx={{position: 'absolute', bottom: '69.4mm', left: '144mm'}} fontSize={12} fontWeight='bold' >{Number(print.fullMarks) }</Typography>
                        <Typography sx={{position: 'absolute', bottom: '69.4mm', left: '163.5mm'}} fontSize={12} fontWeight='bold' >{Number(print.marks) }</Typography>
                        <Typography sx={{position: 'absolute', bottom: '48.6mm', left: '61mm'}} fontSize={12} fontWeight='bold' >{print.s_issuedate}</Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}