import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

/* MUI */
import { Alert, Button, CircularProgress, TextField } from '@mui/material';

export default function TeacherLogin({api}){
    let history = useHistory()

    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [logging, setLogging] = useState(false)
    const [warning, setWarning] = useState('')

    const login = () => {
        setWarning('')
        setLogging(true)
        var formData = new FormData()
        formData.append('login-teacher', code)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLogging(false)
            if(data.warning){
                setWarning(data.warning)
            } else if(data.success){
                // REDIRECT PAGE HERE
                history.replace('/dashboard-teacher')
            }
        })
    }

    useEffect(() => {
        fetch('/radiantapi/teacher/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                history.replace('/dashboard-teacher')
            }
        })
    }, [])

    return(
        <div className='container-fluid mt-5 mb-5'>
            <div className='row p-3'>
                <div className='col-md-6 col-12 mx-auto mt-5'>
                    <div className='row'>
                        <div className='col-12 text-center mb-4 mt-5'>
                            <h1 className='lspace3 fw-bold' >TEACHER LOGIN</h1>
                        </div>
                        <div className='col-12'>
                            <TextField fullWidth variant='standard' type='text' label='Teacher code' className='mb-4' value={code} onChange={(event) => setCode(event.target.value)} />
                            <TextField fullWidth variant='standard' type='password' label='Password' value={password} onChange={(event) => setPassword(event.target.value)} />
                        </div>
                        <div className='col-12 mt-2'>
                            { warning && <Alert severity='warning'>{warning}</Alert> }
                        </div>
                        <div className='col-12 text-center mt-4'>
                            <Button color='warning' size='large' variant='contained' className='col-md-4 col-6' disabled={logging} onClick={() => login()}  >{logging ? <CircularProgress color='inherit' size={24} /> : 'Enter' }</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}