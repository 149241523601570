import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Alert, AppBar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'

export default function StudentLogin({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [warning, setWarning] = useState('')
    const [logging, setLogging] = useState(false)

    useEffect(() => {
        fetch('/radiantapi/student/ui.php', {
            method: 'post'
        }).then(res => res.json())
        .then(data => {
            if(data.loggedin === true){
                // REDIRECT HERE
                history.replace('/dashboard-student')
            }
        })
    }, [])

    const login = () => {
        setWarning('')
        setLogging(true)
        var formData = new FormData()
        formData.append('login', email)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLogging(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                // REDIRECT HERE
                history.replace('/dashboard-student')
            }
        })
    }

    return(
        <>
        <div className='container-fluid flex' style={{height: '90vh'}} >
            <div className='row p-3'>
                <div className='col-md-6 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12 text-center mb-4'>
                            <h1 className='fw-bold' >Student Login</h1>
                        </div>
                        <div className='col-12'>
                            <TextField fullWidth variant='standard' type='email' label='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            <TextField fullWidth variant='standard' type='password' label='Password' className='mt-4' value={password} onChange={(event) => setPassword(event.target.value)} />
                        </div>
                        <div className='col-12 mt-2'>
                            {warning && <Alert severity='warning'>{warning}</Alert> }
                        </div>
                        <div className='col-12 mt-4 text-center'>
                            <Button className='col-md-4 col-6' size='large' color='primary' variant='contained' disabled={logging} onClick={() => login()} >{logging ? <CircularProgress color='inherit' size={24} /> : 'Login' }</Button>
                        </div>
                        <div className='col-12 text-end mt-4'>
                            <Button color='error' onClick={() => history.push(`${url}/reset`)} >Forgot password</Button><br/>
                            <Button color='primary' onClick={() => history.push(`${url}/create/0`)} >Join Institute</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Route path={`${path}/reset`} component={Reset} />
        <Route path={`${path}/create/:referredby`} component={Create} />
        </>
    );

    function Reset(){
        
        const [otpsended, setOtpsended] = useState(false)
        const [email, setEmail] = useState('')
        const [newpass, setNewpass] = useState('')
        const [otp, setOtp] = useState('')
        const [warning, setWarning] = useState('')
        const [reseting, setReseting] = useState(false)
        
        const verify = () => {
            setWarning('')
            setReseting(true)
            var formData = new FormData()
            formData.append('verify-email', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.otpsended){
                    setOtpsended(true)
                }
            })
        }

        const reset = () => {
            setWarning('')
            setReseting(true)
            var formData = new FormData()
            formData.append('reset-password', email)
            formData.append('new-password', newpass)
            formData.append('otp', otp)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setReseting(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    // REDIRECT HERE
                    history.replace('/dashboard-student')
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Reset password' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' type='email' label='Email' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            {otpsended === true &&
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' type='text' label='New password' className='mt-4' value={newpass} onChange={(event) => setNewpass(event.target.value)} />
                                <TextField fullWidth variant='standard' type='number' label='OTP' className='mt-4' value={otp} onChange={(event) => setOtp(event.target.value)} />
                            </div> }
                            <div className='col-12 mt-2'>
                                {warning && <Alert severity='warning'>{warning}</Alert> }
                            </div>
                            <div className='col-12 mt-4 text-center'>
                                {otpsended === true ?
                                <Button color='primary' size='large' variant='contained' disabled={reseting} onClick={() => reset()} >{reseting ? <CircularProgress color='inherit' size={24} /> : 'Reset' }</Button> :
                                <Button color='primary' size='large' variant='contained' disabled={reseting} onClick={() => verify()} >{reseting ? <CircularProgress color='inherit' size={24} /> : 'Verify' }</Button> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Create(){
        const { referredby } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [email, setEmail] = useState('')
        const [name, setName] = useState('')
        const [gender, setGender] = useState('')
        const [birth, setBirth] = useState('')
        const [referby, setReferby] = useState(referredby)

        const [otp, setOtp] = useState('')
        const [password, setPassword] = useState('')
        const [otpsended, setOtpsended] = useState(false)
        const [process, setProcess] = useState(false)

        const verify = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('verify-email-for-create', email)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else if(data.otpsended === true){
                    setOtpsended(true)
                }
            })
        }

        const create = () => {
            setProcess(true)
            var formData = new FormData()
            formData.append('create-account', email)
            formData.append('name', name)
            formData.append('birth', birth)
            formData.append('gender', gender)
            formData.append('referby', referby)
            formData.append('otp', otp)
            formData.append('password', password)

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcess(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    // REDIRECT HERE
                    history.replace('/dashboard-student')
                }
            })
        }

        return(
            <Dialog open={true} fullScreen onClose={() => history.go(-1)} >
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <AppBar position='sticky' color='primary' >
                    <Stack direction='row' justifyContent='space-between' alignItems='center' pt={1} pb={1} pr={1} >
                        <IconButton color='inherit' onClick={() => history.go(-1)} ><ArrowBackIosNew/></IconButton>
                        <Typography fontSize={20} color='white' >Create account</Typography>
                    </Stack>
                </AppBar>
                <DialogContent>
                    <Grid container justifyContent='center' >
                        <Grid item xs={12} md={6}>
                            <Stack direction='column' spacing={3}>
                                <TextField fullWidth variant='standard' type='email' label='Email' disabled={otpsended} value={email} onChange={(event) => setEmail(event.target.value)} />
                                { otpsended === true &&
                                <>
                                    <TextField fullWidth variant='standard' type='text' label='Full name' value={name} onChange={(event) => setName(event.target.value)} />
                                    <FormControl fullWidth variant='standard' >
                                        <InputLabel>Gender</InputLabel>
                                        <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                                            <MenuItem value='MALE' >Male</MenuItem>
                                            <MenuItem value='FEMALE' >Female</MenuItem>
                                            <MenuItem value='OTHERS' >Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField fullWidth variant='standard' type='date' value={birth} onChange={(event) => setBirth(event.target.value)} helperText='Birth date' />
                                    <TextField fullWidth variant='standard' type='text' value={referby} onChange={(event) => setReferby(event.target.value)} label='Refer by (Optional)' disabled={referredby !== '0' ? true : false} />
                                    <TextField fullWidth variant='standard' type='number' value={otp} onChange={(event) => setOtp(event.target.value)} label='OTP' />
                                    <TextField fullWidth variant='standard' type='text' value={password} onChange={(event) => setPassword(event.target.value)} label='Create password' />
                                </>}

                                <Box textAlign='center' >
                                    { otpsended ?
                                    <Button variant='contained' sx={{boxShadow: 0}} onClick={() => create()} disabled={process} >{process ? <CircularProgress color='inherit' size={24} /> : 'CREATE ACCOUNT'}</Button> :
                                    <Button variant='contained' sx={{boxShadow: 0}} onClick={() => verify()} disabled={process}>{process ? <CircularProgress color='inherit' size={24} /> : 'VERIFY'}</Button>
                                    }
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

}