import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from 'react-router-dom'
import Bar from "../../bar";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

// MUI
import { Box, Button, Container, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'

export default function PlayerAdmin({vpath}){

    const { videolink } = useParams()
    const { title } = useParams()

    const history = useHistory()

    const videoRef = useRef(null);
    useEffect(() => {
        new Plyr(videoRef.current);
    }, [])

    return(
        <Box sx={{background: '#fafafa'}} pb={6} >
            <Bar text={title} />

            <Container>
                <Grid container mt={2} >
                    <Grid item xs={12} md={9} mx='auto' >
                        <video ref={videoRef} autoPlay={true} >
                            <source src={`${vpath}/${videolink}`} type="video/mp4" />
                        </video>

                        <Typography variant="h6" fontWeight='bold' mt={2} >{title}</Typography>
                    </Grid>
                </Grid>
            </Container>

            <Dialog open={videolink === 'null' ? true : false} fullWidth >
                <DialogContent>
                    <Box textAlign='center' mt={2} >
                        <Warning fontSize="large" color="warning" />
                        <Typography color='orange' variant="h4" align="center" mt={1} >Not found any video.</Typography>
                        <Box textAlign='center' mb={3} mt={1} >
                            <Button color="primary" variant="outlined" onClick={() => history.go(-1)} >OK, Go back</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

        </Box>
    );
}