import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Certificate  from './../images/computer-certificate.png'
import Close from './../close'
import QRCode from "react-qr-code";

/* MUI */
import { Button } from '@mui/material'

export default function ComputerCertificate(){
    const { studentid } = useParams()
    let history = useHistory()

    const [print, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch('/radiantapi/print-view/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    const printcertificate = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML

        document.body.innerHTML = getArea
                
        window.print()

        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }

    useEffect(()  => {
        document.title = print.s_name
    }, [print])

    return(
        <>
            <Close title='Print computer certificate' />
            <div className='container-fluid mt-4 mb-2'>
                <div className='row'>
                    <div className='col-12 text-center mb-5'>
                        <Button variant='contained' color='warning' size='large' className='col-md-4 col-6' onClick={() => printcertificate()} >Print</Button><br/>
                        <Button color='warning' className='mt-2' onClick={() => history.push(`/marksheet/${studentid}`)} >Print marksheet</Button>
                    </div>
                </div>
            </div>
            <div style={{position: 'relative'}} id='printArea' >
                <img src={Certificate} style={{height: '1070px'}} alt='Computer certificate' />
                <QRCode size={200} style={{left: '1120px', top: '275px', position: 'absolute'}} value={`This certificate has issued by 'RADIANT INSTITUTE OF ADVANCE TECHNOLOGY' from '${print.b_name}' of ${print.s_name} (Registration number: ${print.s_enrollment}) on ${print.s_issuedate}`} />
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '490px', left: '1175px', color: '#ffffff'}} >{print.s_enrollment}</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '485px', left: '500px'}} >{print.s_name}</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '545px', left: '500px'}} >{print.s_guardian}</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '600px', left: '730px'}} >{print.c_duration}</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '650px', left: '400px'}} >{print.c_title} ({print.c_name})</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '695px', left: '450px'}} >{print.b_name}</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '750px', left: '930px'}} >{(Number(print.marks) * 100 / Number(print.fullMarks)) >= 85 ? 'A+' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 70 ? 'A' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 60 ? 'B' : (Number(print.marks) * 100 / Number(print.fullMarks)) >= 45 && 'C'  }</h6>
                <h6 className='fw-bold col-12' style={{position: 'absolute', top: '910px', left: '450px'}} >{print.s_issuedate}</h6>
            </div>
        </>
    );
}