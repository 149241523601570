import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'

import Header from './header';

/* MUI */
import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Snackbar, Stack, Typography } from '@mui/material'
import { AutoAwesome, People, Share } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function DashboardStudent({api, img}){
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const [warning, setWarning] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-courses', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setCourses(data)
            }
        })
    }, [])

    const [ycourse, setYcourse] = useState([])
    const [yloading, setYloading] = useState(true)
    const [ywarn, setYwarn] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('oc-courses-bought', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setYloading(false)
            if(data.warning){
                setYwarn(data.warning)
            } else {
                setYcourse(data)
            }
        })
    }, [])

    const [me, setMe] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setMe(data)
            }
        })
    }, [])

    const [copied, setCopied] = useState(false)
    const copy_link = (event) => {
        const domain = window.location.hostname
        const link = domain+'/login-student/create/'+event
        navigator.clipboard.writeText(link)
        setCopied(true)
    }

    return(
        <Box><Snackbar open={copied} message='Link copied' onClose={() => setCopied(false)} />
        <Header back={false} />
        <Container sx={{marginTop: 3, marginBottom: 7}} >
            <Card sx={{marginBottom: 4}}>
                <CardContent>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={1} justifyContent='space-between' alignItems='center' pt={2} pb={1} >
                        <Button size='large' variant='contained' color='primary' sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<AutoAwesome/>} >REFER ID - {me.referid}</Button>
                        <Button size='large' variant='contained' color='info' onClick={() => history.push(`${url}/referred`)} sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<People/>} >MY TEAM</Button>
                        <Button size='large' variant='contained' color='error' onClick={() => copy_link(me.referid)} sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<Share/>} >SHARE</Button>
                    </Stack>
                </CardContent>
            </Card>
            <Typography color='black' mb={1} fontWeight='bold' >Your courses</Typography>

            { yloading ? <LinearProgress color='primary' /> :
            ywarn ? <Alert severity='warning'>{ywarn}</Alert> :
            <Grid container spacing={2} >
                { ycourse.map((data, i) =>
                <Grid item xs={12} md={3} key={i} >
                    <Box key={i} className='shadow-1' height={220} maxWidth={340} borderRadius={2} overflow='hidden' sx={{position: 'relative', background: '#cbf3f0'}} mx='auto'>
                        <Box sx={{cursor: 'pointer'}} onClick={() => history.push(`/course-detail-student/${data.onlinecourseid}`)} >
                            <Box padding={1.5} >
                                <Typography color='black' fontWeight='bold' sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_name}</Typography>

                                <Typography fontWeight='bold' mt={1} variant='body1' >Includes</Typography>
                                <Typography variant='body2'>{data.videos} Videos, Full time access, Access from anywhere, New skills, Certificate on completion</Typography>
                            </Box>
                        </Box>

                        <Box sx={{position: 'absolute', bottom: 0, width: '100%'}} >
                            <Box sx={{background: '#000000', cursor: 'pointer'}} textAlign='center' color='white' fontWeight='bold' padding={1} >WATCH</Box>
                        </Box>
                    </Box>
                </Grid>) }
            </Grid>}

            <Typography color='black' mb={1} fontWeight='bold' mt={3} >Explore courses</Typography>
            { loading ? <LinearProgress color='primary' /> :
            warning ? <Alert severity='warning'>{warning}</Alert> :
            <Grid container spacing={2} >
                { courses.map((data, i) =>
                <Grid item xs={12} md={3} key={i} >
                    <Box key={i} className='shadow-1' height={330} maxWidth={340} sx={{position: 'relative'}} mx='auto'>
                        <Box sx={{cursor: 'pointer'}} onClick={() => history.push(`/course-detail-student/${data.onlinecourseid}`)} >
                            <img src={`${img}/${data.oc_thumbnail}`} className='c-thumbnail' alt='Course thumbnail' />

                            <Box padding={1.5} >
                                <Typography color='black' fontWeight='bold' sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_name}</Typography>

                                <Typography fontWeight='bold' align='center' mt={1} variant='body1' >Includes</Typography>
                                <Typography variant='body2' align='center'>{data.videos} Videos, Full time access, Access from anywhere, New skills, Certificate on completion</Typography>
                            </Box>
                        </Box>

                        <Box sx={{position: 'absolute', bottom: 0, width: '100%'}} >
                            <Stack direction='row' alignItems='center' pt={0.7} pb={0.7} padding={1} sx={{backgroundColor: 'primary.main'}} >
                                <Typography color='white' fontSize={20} >&#8377; {Number(data.oc_price).toLocaleString('en-in')}</Typography>&nbsp;&nbsp;
                                <Typography color='white' variant='caption' sx={{textDecoration: 'line-through', flexGrow: 1}} >&#8377; {Number(data.oc_mrp).toLocaleString('en-in')}</Typography>
                                <Button size='small' sx={{background: '#fafafa'}} disabled ><Typography fontSize={14} >{( (Number(data.oc_mrp) - Number(data.oc_price)) * 100 / Number(data.oc_mrp) ).toFixed(0)}% OFF</Typography></Button>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>) }
            </Grid>}
        </Container>
        <Route path={`${path}/referred`} component={Referred} />
        </Box>
    );

    function Referred(){

        const [referred, setReferred] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            var formData = new FormData()
            formData.append('referred', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setReferred(data)
                }
            })
        }, [])

        const [level, setLevel] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('referred-level', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setLevel(data)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => history.go(-1)} >
                <DialogTitle>My teams</DialogTitle><Divider/>
                <DialogContent>
                    <Card sx={{marginBottom: 2}} >
                        <CardContent>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                <Typography variant='body1' >Level 1</Typography>
                                <Typography variant='body1' >{level.level1}</Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                <Typography variant='body1' >Level 2</Typography>
                                <Typography variant='body1' >{level.level2}</Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                <Typography variant='body1' >Level 3</Typography>
                                <Typography variant='body1' >{level.level3}</Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                <Typography variant='body1' >Level 4</Typography>
                                <Typography variant='body1' >{level.level4}</Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                <Typography variant='body1' >Level 5</Typography>
                                <Typography variant='body1' >{level.level5}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    { loading ? <LinearProgress color='primary' /> :
                    warning ? <Alert severity='warning'>{warning}</Alert> :
                    referred.map((data, i) =>
                    <Card key={i} sx={{marginBottom: 1}} >
                        <CardContent>
                            <Typography fontSize={22} fontWeight='bold' letterSpacing={2} color='black' fontFamily='initial' >{data.s_name}</Typography>
                            <Typography variant='body2'>Joined on: {data.join_date}</Typography>
                            <Typography variant='body2'>Refer ID: {data.referid}</Typography>
                        </CardContent>
                    </Card>)}
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant='text' onClick={() => history.go(-1)} >CLOSE</Button>
                </DialogActions>
            </Dialog>
        );
    }

}