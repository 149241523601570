import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SidebarAdmin from './sidebar'

/* MUI */
import { Alert, Box, Chip, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { Book, Check, Circle, LocalOffer, PendingActions, Person, Redeem, WorkspacePremium } from '@mui/icons-material'

export default function DashboardAdmin({api}){
    let history = useHistory()
    const date = new Date()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard-preview', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [])

    const [redeemyear, setRedeemyear] = useState(date.toLocaleDateString('default', {year: 'numeric'}))
    const [redeemmonth, setRedeemmonth] = useState(date.toLocaleDateString('default', {month: '2-digit'}))
    useEffect(() => {
        redeem_status(redeemyear, redeemmonth)
    }, [redeemyear, redeemmonth])
    const [redeems, setRedeems] = useState([])
    const [redeemwarn, setRedeemwarn] = useState('')
    const redeem_status = (redeemyear, redeemmonth) => {
        setRedeemwarn('')
        var formData = new FormData()
        formData.append('redeem-history', redeemyear)
        formData.append('month', redeemmonth)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setRedeemwarn(data.warning)
            } else {
                setRedeems(data)
            }
        })
    }

    return(
        <Box>
        <SidebarAdmin />
        <Container className='content' >

            <Grid container spacing={1} >
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Person sx={{fontSize: 70}} color='warning' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >STUDENTS</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{preview.students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <WorkspacePremium sx={{fontSize: 70}} color='primary' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ISSUED</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{preview.issued}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <PendingActions sx={{fontSize: 70}} color='error' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >PENDING</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{preview.pending}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Grid container mt={3} spacing={1} >
                <Grid item xs={12} md={7} >
                    <Box component={Paper} padding={2} height={400} overflow='scroll' >
                        <Typography color='gray' variant='body2' mb={2} >Request for cash redeem</Typography>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mb={2} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={redeemyear}
                                onChange={(event) => setRedeemyear(event.target.value)}
                                label="Year"
                                >
                                    <MenuItem value='2023'>2023</MenuItem>
                                    <MenuItem value='2024'>2024</MenuItem>
                                    <MenuItem value='2025'>2025</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={redeemmonth}
                                onChange={(event) => setRedeemmonth(event.target.value)}
                                label="Year"
                                >
                                    <MenuItem value='01'>January</MenuItem>
                                    <MenuItem value='02'>February</MenuItem>
                                    <MenuItem value='03'>March</MenuItem>
                                    <MenuItem value='04'>April</MenuItem>
                                    <MenuItem value='05'>May</MenuItem>
                                    <MenuItem value='06'>June</MenuItem>
                                    <MenuItem value='07'>July</MenuItem>
                                    <MenuItem value='08'>August</MenuItem>
                                    <MenuItem value='09'>September</MenuItem>
                                    <MenuItem value='10'>October</MenuItem>
                                    <MenuItem value='11'>November</MenuItem>
                                    <MenuItem value='12'>December</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack><Divider/>

                        { redeemwarn ? <Alert severity='warning'>{redeemwarn}</Alert> :
                        <TableContainer>
                            <Table  >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align='center' >Refer ID</TableCell>
                                        <TableCell align='center' >Coins</TableCell>
                                        <TableCell align='right' >Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { redeems.map((data, i) =>
                                    <TableRow key={i} >
                                        <TableCell>{data.s_name}</TableCell>
                                        <TableCell align='center' >{data.referid}</TableCell>
                                        <TableCell align='center' >{data.coin}</TableCell>
                                        <TableCell align='right' >{data.coin_date}</TableCell>
                                    </TableRow>) }
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} >
                    <Box height={196} component={Paper} >
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Active Coin</Typography>
                            <Chip label={preview.activecoin} color='success' icon={<Circle />} />
                        </Toolbar>
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Redeemed Coin</Typography>
                            <Chip label={preview.redeemedcoin} color='warning' icon={<Redeem />} />
                        </Toolbar>
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Active Coin Value</Typography>
                            <Typography fontWeight='bold' >&#8377; {Number(parseInt(preview.activecoin)*10).toLocaleString('en-in')}</Typography>
                        </Toolbar>
                    </Box>
                    <Box height={196} component={Paper} mt={1} >
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Online Courses</Typography>
                            <Chip label={preview.onlinecourse} color='info' icon={<Book />} />
                        </Toolbar>
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Sold Courses</Typography>
                            <Chip label={preview.soldcourse} color='success' icon={<LocalOffer />} />
                        </Toolbar>
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Earned</Typography>
                            <Typography fontWeight='bold' >&#8377; {Number(preview.soldamount).toLocaleString('en-in')}</Typography>
                        </Toolbar>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}