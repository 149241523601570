import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MUI */
import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import BookIcon from '@mui/icons-material/Book';
import DeleteIcon from '@mui/icons-material/Delete';

export default function CourseAdmin({api}){
    const { path, url } = useRouteMatch()
    let history = useHistory()

    useEffect(() => {
        viewcourse()
    }, [])

    const [courses, setCourses] = useState([])
    const [coursewarn, setCoursewarn] = useState('')
    const [loading, setLoading] = useState(true)
    const viewcourse = () => {
        setCoursewarn(null)
        var formData = new FormData()
        formData.append('view-course', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setCoursewarn(data.warning)
            } else {
                setCourses(data)
            }
        })
    }

    return(
        <>
        <SidebarAdmin />
        <div className='content'>
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='col-12 text-end mb-3'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >Add course</Button>
                </div>
                
                { loading ? <LinearProgress color='warning' /> :
                coursewarn ? <Alert severity='warning'>{coursewarn}</Alert> :
                courses.map((course, i) =>
                <div className='col-md-3 col-12 mb-4' key={i} >
                    <div className={`p-3 rounded-3 lightshadow text-break ${course.c_disable === '1' && 'blockbg'}`}>
                        <h5 className='fw-bold mb-3 text-truncate'>{course.c_title}</h5>
                        <p className='text-truncate' >NAME: {course.c_name}</p>
                        <p className='text-truncate' >Duration: {course.c_duration} MONTHS</p>

                        <div className=''>
                            <IconButton onClick={() => history.push(`${url}/configure/${course.courseid}`)} ><EditIcon /></IconButton>
                            <IconButton onClick={() => history.push(`${url}/subject/${course.courseid}`)} ><BookIcon /></IconButton>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:courseid`} component={Course} />
        <Route path={`${path}/subject/:courseid`} component={Subject} />
        </>
    );

    function Course(){
        const { courseid } = useParams()
        const [open, setOpen] = useState(false)
        const [warning, setWarning] = useState('')

        const [title, setTitle] = useState('')
        const [name, setName] = useState('')
        const [duration, setDuration] = useState('')
        const [disable, setDisable] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('view-course-detail', courseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setTitle(data.c_title); setName(data.c_name); setDuration(data.c_name); setDuration(data.c_duration); setDisable(data.c_disable)
                }
            })
        }, [courseid])

        const upload = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-course', courseid)
            formData.append('title', title)
            formData.append('name', name)
            formData.append('duration', duration)
            formData.append('disable', disable)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                    setOpen(true)
                } else {
                    viewcourse()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={warning} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Course' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth type='text' variant='standard' label='Title' value={title} onChange={(event) => setTitle(event.target.value)} />
                                <TextField fullWidth type='text' variant='standard' label='Name' className='mt-4' value={name} onChange={(event) => setName(event.target.value)} />
                                <TextField fullWidth type='number' variant='standard' label='Duration(Months)' className='mt-4' value={duration} onChange={(event) => setDuration(event.target.value)} />
                                <FormControl fullWidth variant='standard' className='mt-4' >
                                    <InputLabel>Disable</InputLabel>
                                    <Select value={disable} onChange={(event) => setDisable(event.target.value)}>
                                        <MenuItem value='0' >No</MenuItem>
                                        <MenuItem value='1' >Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={uploading} onClick={() => upload()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Subject(){
        const { courseid } = useParams()
        const [open, setOpen] = useState(false)
        const [warning, setWarning] = useState('')

        const [term, setTerm] = useState('')
        const [subject, setSubject] = useState('')
        const [fmarks, setFmarks] = useState('')
        const [pmarks, setPmarks] = useState('')
        const [uploading, setUploading] = useState(false)

        const [subjects, setSubjects] = useState([])
        const [subwarn, setSubwarn] = useState('')
        const [loadsub, setLoadsub] = useState(true)

        useEffect(() => {
            viewsubjects(courseid)
        }, [courseid])
        const viewsubjects = (courseid) => {
            setSubwarn('')
            var formData = new FormData()
            formData.append('view-subject', courseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoadsub(false)
                if(data.warning){
                    setSubwarn(data.warning)
                } else {
                    setSubjects(data)
                }
            })
        }

        const uploadsubject = () => {
            setWarning('')
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-subject', '')
            formData.append('courseid', courseid)
            formData.append('term', term)
            formData.append('subject', subject)
            formData.append('fmark', fmarks)
            formData.append('pmark', pmarks)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                    setOpen(true)
                } else {
                    setTerm(''); setSubject(''); setFmarks(''); setPmarks('')
                    viewsubjects(courseid)
                }
            })
        }

        const [subjectid, setSubjectid] = useState('')
        const deletesubject = (subjectid) => {
            setSubjectid(subjectid)
            setWarning('')
            var formData = new FormData()
            formData.append('delete-subject', subjectid)
            formData.append('courseid', courseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setSubjectid('')
                if(data.warning){
                    setWarning(data.warning)
                    setOpen(true)
                } else {
                    setWarning('Delete succedd.')
                    setOpen(true)
                    viewsubjects(courseid)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)} >
                <Snackbar open={open} message={warning} onClick={() => setOpen(false)} />
                <DialogTitle><Close title='Subject' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <FormControl fullWidth variant='standard' >
                                    <InputLabel>Term</InputLabel>
                                    <Select value={term} onChange={(event) => setTerm(event.target.value)} >
                                        <MenuItem value='1' >1st</MenuItem>
                                        <MenuItem value='2' >2nd</MenuItem>
                                        <MenuItem value='3' >3rd</MenuItem>
                                        <MenuItem value='4' >4th</MenuItem>
                                        <MenuItem value='5' >5th</MenuItem>
                                        <MenuItem value='6' >6th</MenuItem>
                                        <MenuItem value='7' >7th</MenuItem>
                                        <MenuItem value='8' >8th</MenuItem>
                                        <MenuItem value='9' >9th</MenuItem>
                                        <MenuItem value='10' >10th</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField fullWidth type='text' variant='standard' label='Subject' className='mt-4' value={subject} onChange={(event) => setSubject(event.target.value)} />
                                <TextField fullWidth type='number' variant='standard' label='Full marks' className='mt-4' value={fmarks} onChange={(event) => setFmarks(event.target.value)} />
                                <TextField fullWidth type='number' variant='standard' label='Pass marks' className='mt-4' value={pmarks} onChange={(event) => setPmarks(event.target.value)} />
                            </div>
                            <div className='col-12 text-center text-center mt-4'>
                                <Button className='col-md-4 col-6' variant='contained' size='large' color='warning' disabled={uploading} onClick={() => uploadsubject()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>

                            <div className='col-12 mt-4'>
                                {loadsub ? <LinearProgress color='warning' /> :
                                subwarn ? <Alert severity='warning'>{subwarn}</Alert> :
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Term</TableCell>
                                                <TableCell align='right' >Subject</TableCell>
                                                <TableCell align='right' >Full marks</TableCell>
                                                <TableCell align='right' >Pass marks</TableCell>
                                                <TableCell align='right'>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subjects.map((sub, i) =>
                                            <TableRow key={i} >
                                                <TableCell>{sub.s_term}</TableCell>
                                                <TableCell align='right' >{sub.s_subject}</TableCell>
                                                <TableCell align='right' >{sub.s_fmark}</TableCell>
                                                <TableCell align='right' >{sub.s_pmark}</TableCell>
                                                <TableCell align='right' ><IconButton onDoubleClick={() => deletesubject(sub.subjectid)} >{subjectid === sub.subjectid ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon /> }</IconButton></TableCell>
                                            </TableRow> )}
                                        </TableBody>
                                    </Table>
                                </TableContainer> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}