import React, { useEffect } from "react";

import PagesHeader from "./header";
import PagesFooter from "./footer";

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import { Call, Mail, Navigation, WhatsApp } from '@mui/icons-material'

export default function PagesContact(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <Box><PagesHeader />
            <Container>
                <Typography fontSize={45} color='darkcyan' fontFamily='serif' fontWeight='bold' align="center" mt={3} letterSpacing={2} >CONTACT US</Typography>
                <Typography align="center" variant="body2" color='gray' >There are various way you can reach to us. Choose your easy one</Typography>
                <Grid container mt={3} direction='row' justifyContent='center' alignItems='center' >
                    <Grid item md={6} display={{md: 'block', xs: 'none'}} padding={3} >
                        <img src="/images/contact.webp" alt="Contact image" />
                    </Grid>
                    <Grid item md={6}  xs={12} padding={1} >
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6}>
                                <Box padding={2} sx={{backgroundColor: '#000000', height: 300}} display='flex' flexDirection='column' justifyContent='center' alignItems='center' borderRadius={5} >
                                    <Call sx={{color: '#000000', background: '#fafafa', fontSize: 60, borderRadius: 3, padding: 1}} />
                                    <Typography color='white' mt={2} >Make a call to us</Typography>
                                    <Typography color='yellow' fontWeight='bold' letterSpacing={1} fontSize={23} >+91 79797 82589</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box padding={2} sx={{backgroundColor: '#000000', height: 300}} display='flex' flexDirection='column' justifyContent='center' alignItems='center' borderRadius={5} >
                                    <WhatsApp sx={{color: '#000000', background: '#fafafa', fontSize: 60, borderRadius: 3, padding: 1}} />
                                    <Typography color='white' mt={2} >Chat on WhatsApp</Typography>
                                    <Typography color='green' fontWeight='bold' letterSpacing={1} fontSize={23} >+91 79797 82589</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box padding={2} sx={{backgroundColor: '#000000', height: 300}} display='flex' flexDirection='column' justifyContent='center' alignItems='center' borderRadius={5} >
                                    <Mail sx={{color: '#000000', background: '#fafafa', fontSize: 60, borderRadius: 3, padding: 1}} />
                                    <Typography color='white' mt={2} >Send you query</Typography>
                                    <Typography color='red' fontWeight='bold' letterSpacing={1} fontSize={16} >info@radiantiteducation.in</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box padding={2} sx={{backgroundColor: '#000000', height: 300}} display='flex' flexDirection='column' justifyContent='center' alignItems='center' borderRadius={5} >
                                    <Navigation sx={{color: '#000000', background: '#fafafa', fontSize: 60, borderRadius: 3, padding: 1}} />
                                    <Typography color='white' mt={2} >Reach to office</Typography>
                                    <Typography color='cyan' fontWeight='bold' letterSpacing={1} fontSize={16} >Hafla Ganj, Sirnia, Katihar</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        <PagesFooter/></Box>
    )
}