import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import Bar from "../../bar";

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogContent, Grid, IconButton, ListItemText, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Add, Delete, Edit, Visibility } from '@mui/icons-material'

export default function AdminCourseTopics({api}){
    const { onlinecourseid } = useParams()
    let history = useHistory()
    const { path, url } = useRouteMatch()

    const [topics, setTopics] = useState([])
    const [twarn, setTwarn] = useState('')
    useEffect(() => {
        get_topics(onlinecourseid)
    }, [onlinecourseid])

    const get_topics = (onlinecourseid) => {
        setTwarn('')
        var formData = new FormData()
        formData.append('get-course-topics', onlinecourseid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setTwarn(data.warning)
            } else {
                setTopics(data)
            }
        })
    }

    return(
        <Box>
            <Bar text='Course topics' />
            <IconButton sx={{position: 'fixed', bottom: 50, right: 30, zIndex: 100}} size="large" color="error" onClick={() => history.push(`${url}/create/0`)} ><Add/></IconButton>
            <Container sx={{marginTop: 3, marginBottom: 2}} >
                <Grid container>
                    <Grid item xs={12} md={6} mx='auto'>
                        { twarn ? <Alert severity="warning">{twarn}</Alert> :
                        topics.map((data, i) =>
                        <Toolbar component={Paper} sx={{pt: 3, pb: 3, marginBottom: 1}} key={i} >
                            <img src="/images/trending.svg" alt="Topic" style={{width: 70}} />
                            <ListItemText primary={<Typography fontWeight='bold' variant="body2" >{data.oct_name}</Typography>} secondary={`${data.folders} Folders`} />

                            <IconButton color="error" onClick={() => history.push(`${url}/delete/${data.onlinetopicid}`)} ><Delete/></IconButton>
                            <IconButton color="info" onClick={() => history.push(`${url}/create/${data.onlinetopicid}`)} ><Edit/></IconButton>
                            <IconButton color="primary" onClick={() => history.push(`/online-course-folders-admin/${data.onlinetopicid}`)} ><Visibility/></IconButton>
                        </Toolbar>)}
                    </Grid>
                </Grid>
            </Container>
            <Route path={`${path}/create/:onlinetopicid`} component={Add_topics} />
            <Route path={`${path}/delete/:onlinetopicid`} component={Delete_topic} />
        </Box>
    );
    
    function Add_topics(){
        const { onlinetopicid } = useParams()

        const [name, setName] = useState('')
        const [addwarn, setAddwarn] = useState('')
        const [working, setWorking] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('online-topic-details', onlinecourseid)
            formData.append('onlinetopicid', onlinetopicid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.oct_name)
                }
            })
        }, [onlinetopicid])

        const add_topic = () => {
            setAddwarn(''); setWorking(true)
            var formData = new FormData()
            formData.append('add-online-topic', onlinecourseid)
            formData.append('onlinetopicid', onlinetopicid)
            formData.append('name', name)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setAddwarn(data.warning)
                } else {
                    get_topics(onlinecourseid); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth maxWidth='xs' onClose={() => history.go(-1)} >
                <Bar text='Add topics' />
                <DialogContent>
                    <Stack spacing={1.5} pt={3} pb={3} >
                        <TextField variant="outlined" type="text" label='Topic name' fullWidth value={name} onChange={(event) => setName(event.target.value)} />
                        {addwarn && <Alert severity="warning">{addwarn}</Alert> }
                        <Box textAlign='center' >
                            <Button variant="contained" color="primary" disabled={working} onClick={() => add_topic()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }

    function Delete_topic(){
        const { onlinetopicid } = useParams()
        const [dwarn, setDwarn] = useState('')
        const [working, setWorking] = useState(false)

        const delete_topic = () => {
            setWorking(true)
            var formData = new FormData()
            formData.append('delete-online-topic', onlinecourseid)
            formData.append('onlinetopicid', onlinetopicid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setDwarn(data.warning)
                } else {
                    get_topics(onlinecourseid); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => history.go(-1)}>
                <DialogContent>
                    <Box pt={3} pb={3}>
                        <Typography fontSize={25} fontWeight='bold' mb={1} >Do you want to delete this topic?</Typography>
                        <Typography fontSize={12} color='red' mb={1} >Note: When you delete this topic you will delete all the folders and videos releted to this topic. And you can't undo this process.</Typography>
                        
                        {dwarn && <Alert severity="warning">{dwarn}</Alert> }
                        <Box mt={3} textAlign='center'>
                            <Button variant="contained" color="error" disabled={working} onClick={() => delete_topic()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Yes, Delete'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

}