import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'

/* MUI */
import { Alert, Button, Dialog, LinearProgress, CircularProgress, Snackbar } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function GalleryAdmin({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        viewgallery()
    }, [])

    const [warning, setWarning] = useState('')
    const [gallery, setGallery] = useState([])
    const [loading, setLoading] = useState(true)
    const viewgallery = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('view-gallery', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setGallery(data)
            }
        })
    }

    const deletegallery = (tableid, name) => {
        setMessage('Deleting...'); setOpen(true)
        var formData = new FormData()
        formData.append('delete-gallery', tableid)
        formData.append('name', name)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setMessage('Delete succedd.')
            if(data.success){
                viewgallery()
            }
        })
    }

    return(
        <>
        <SidebarAdmin />
        <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
        <div className='content'>
        <div className='container-fluid'>
            <div className='row p-3'>
                <div className='col-12 text-center'>
                    <Button color='warning' size='large' variant='contained' onClick={() => history.push(`${url}/upload`)} >Upload photos</Button>
                </div>
            </div>

            <div className='row p-3'>
                {loading ? <LinearProgress color='warning' /> :
                warning ? <Alert severity='warning'>{warning}</Alert> :
                gallery.map((g, i) =>
                <div className='col-md-3 col-6 mb-4 rounded-3 p-3' key={i} onDoubleClick={() => deletegallery(g.g_table_id, g.g_images)} >
                    <img src={`/instituteassets/gallery/${g.g_images}`} alt={g.g_images} />
                </div>)}
            </div>
        </div>
        </div>
        <Route path={`${path}/upload`} component={Upload} />
        </>
    );

    function Upload(){

        const [uploadWarn, setUploadWarn] = useState('')
        const [uploading, setUploading] = useState(false)

        const startUploading = (event) => {
            setUploading(true)
            setUploadWarn(null)
            var formData = new FormData()
            var getImages = event.target.files;

            for(var i=0; i<getImages.length; i++){
                var images = getImages[i]
                formData.append('upload-gallery[]', images, images.name)
            }

            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setUploadWarn(data.warning)
                } else if(data.success){
                    viewgallery()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <div className='container-fluid mb-5 mt-5'>
                    <div className='row mt-4'>
                        <div className='col-12 text-center'>
                            <label >
                                <input onChange={(event) => startUploading(event)} accept='.png, .jpeg, .jpg' type='file' multiple style={{display: 'none'}} id="uploadGallery" />
                                <Button disabled={uploading} variant='contained' size='large' color='warning' component='span' startIcon={<PhotoCameraIcon />} >Select Images</Button>
                            </label>
                        </div>
                        <div className='col-12 text-center mt-2'>
                            {uploadWarn && <Alert severity='warning'>{uploadWarn}</Alert>}
                            {uploading && <><CircularProgress color='warning' size={25} /><br/>Uploading...</> }
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

}