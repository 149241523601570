import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import Close from './../close'

/* MUI */
import { Alert, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

export default function PaymentAdmin({api}){
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const { path, url } = useRouteMatch()
    const history = useHistory()

    const [desc, setDesc] = useState('')
    const [type, setType] = useState('')
    const [amount, setAmount] = useState('')
    const [paydate, setPaydate] = useState('')

    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        viewentry()
    }, [])
    const viewentry = () => {
        var formData = new FormData()
        formData.append('total-entry', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDebit(data.debit); setCredit(data.credit); setBalance(data.balance)
            }
        })
    }

    const upload = (event) => {
        setType(event)
        var formData = new FormData()
        formData.append('configure-entry', event)
        formData.append('amount', amount)
        formData.append('desc', desc)
        formData.append('paydate', paydate)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning); setOpen(true)
            } else if(data.success) {
                viewentry(); setDesc(''); setType(''); setAmount('');
            }
        })
    }

    return(
        <>
        <SidebarAdmin />
        <div className='content'>
        <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-md-8 col-12 mx-auto flex mt-5' >
                    <div className='row p-3 rounded-3 lightshadow'>
                        <div className='col-8 p-0 mb-2'>
                            <TextField fullWidth variant='outlined' type='text' label='Description' value={desc} onChange={(event) => setDesc(event.target.value)} />
                        </div>
                        <div className='col-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Default</InputLabel>
                                <Select label='Default' value={desc} onChange={(event) => setDesc(event.target.value)} >
                                    <MenuItem value='Electriciy bill' >Electricity bill</MenuItem>
                                    <MenuItem value='Oil' >Oil</MenuItem>
                                    <MenuItem value='Recharge' >Rechage</MenuItem>
                                    <MenuItem value='Institute' >Institute</MenuItem>
                                    <MenuItem value='Grocery' >Grocery</MenuItem>
                                    <MenuItem value='Water' >Water</MenuItem>
                                    <MenuItem value='Ink' >Ink</MenuItem>
                                    <MenuItem value='Marker' >Marker</MenuItem>
                                    <MenuItem value='Register' >Register</MenuItem>
                                    <MenuItem value='A4 paper' >A4 paper</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='number' label='Amount &#8377;' value={amount} onChange={(event) => setAmount(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <TextField fullWidth type='date' label=' ' value={paydate} onChange={(event) => setPaydate(event.target.value)} />
                        </div>
                        <div className='col-md-4 p-0 mb-2'>
                            <FormControl fullWidth >
                                <InputLabel>Type</InputLabel>
                                <Select label='Type' value={type} onChange={(event) => upload(event.target.value)} >
                                    <MenuItem value='DEBIT' >EXPANCE</MenuItem>
                                    <MenuItem value='CREDIT' >INCOME</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row mt-4 rounded-3 p-3 lightshadow pointer' onClick={() => history.push(`${url}/history`)} >
                        <div className='col-md-6 col-12' >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3'>EXPANCE</h5>
                                <h3 className='fw-bold'>{Number(debit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                        <div className='col-md-6 col-12' >
                            <div className='rounded-3 flex text-center' style={{border: 'thin dashed #cccccc', height: '200px'}} >
                                <h5 className='lspace3'>INCOME</h5>
                                <h3 className='fw-bold'>{Number(credit).toLocaleString('en-in')} &#8377;</h3>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-4 rounded-3 lightshadow flex text-center' style={{height: '150px'}}>
                        <div className='col-12'>
                            <h5 className='lspace3'>BALANCE</h5>
                            <h3 className='fw-bold'>{Number(balance).toLocaleString('en-in')} &#8377;</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Route path={`${path}/history`} component={Entryhistory} />
        </>
    );

    function Entryhistory(){

        const [year, setYear] = useState('')
        const [month, setMonth] = useState('')
        const [warning, setWarning] = useState('')
        const [histories, setHistories] = useState([])
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            viewhistory(year, month)
        }, [year, month])

        const viewhistory = (year, month) => {
            setWarning('')
            var formData = new FormData()
            formData.append('view-entry', year)
            formData.append('month', month)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setHistories(data)
                }
            })
        }

        const [deleting, setDeleting] = useState('')
        const deleteentry = (tableid) => {
            setDeleting(tableid)
            var formData = new FormData()
            formData.append('delete-entry', tableid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.success){
                    viewhistory(year, month); viewentry()
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Entry history' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col'>
                                <FormControl fullWidth variant='standard' >
                                    <InputLabel>Year</InputLabel>
                                    <Select value={year} onChange={(event) => setYear(event.target.value)} >
                                        <MenuItem value='2019' >2019</MenuItem>
                                        <MenuItem value='2020' >2020</MenuItem>
                                        <MenuItem value='2021' >2021</MenuItem>
                                        <MenuItem value='2022' >2022</MenuItem>
                                        <MenuItem value='2023' >2023</MenuItem>
                                        <MenuItem value='2024' >2024</MenuItem>
                                        <MenuItem value='2025' >2025</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col'>
                                <FormControl fullWidth variant='standard' >
                                    <InputLabel>Month</InputLabel>
                                    <Select value={month} onChange={(event) => setMonth(event.target.value)} >
                                        <MenuItem value='01' >January</MenuItem>
                                        <MenuItem value='02' >February</MenuItem>
                                        <MenuItem value='03' >March</MenuItem>
                                        <MenuItem value='04' >April</MenuItem>
                                        <MenuItem value='05' >May</MenuItem>
                                        <MenuItem value='06' >June</MenuItem>
                                        <MenuItem value='07' >July</MenuItem>
                                        <MenuItem value='08' >August</MenuItem>
                                        <MenuItem value='09' >September</MenuItem>
                                        <MenuItem value='10' >October</MenuItem>
                                        <MenuItem value='11' >November</MenuItem>
                                        <MenuItem value='12' >December</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                {loading ? <LinearProgress color='warning' /> :
                                warning ? <Alert severity='warning'>{warning}</Alert> :
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Description</TableCell>
                                                <TableCell align='center' >Expance</TableCell>
                                                <TableCell align='center' >Income</TableCell>
                                                <TableCell align='center' >Date</TableCell>
                                                <TableCell align='right' >Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {histories.map((his, i) =>
                                            <TableRow key={i} >
                                                <TableCell>{his.e_description}</TableCell>
                                                <TableCell align='center' >{his.e_debit}</TableCell>
                                                <TableCell align='center' >{his.e_credit}</TableCell>
                                                <TableCell align='center' >{his.e_date}</TableCell>
                                                <TableCell align='right' ><IconButton onDoubleClick={() => deleteentry(his.e_table_id)} >{his.e_table_id === deleting ? <CircularProgress color='inherit' size={24} /> : <DeleteIcon />}</IconButton></TableCell>
                                            </TableRow> )}
                                        </TableBody>
                                    </Table>
                                </TableContainer> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}