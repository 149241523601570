import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import Close from './../close'
import Header from './header'

/* MUI */
import { Alert, Avatar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'

export default function ProfileStudent({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [message, setMessage] = useState('')
    const [snackbar, setSnackbar] = useState(false)

    const [profile, setProfile] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setProfile(data)
            }
        })
    }, [])

    useEffect(() => {
        viewqualification()
    }, [])

    const [qualifications, setQualifications] = useState([])
    const [quawarning, setQuawarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewqualification = () => {
        var formData = new FormData()
        formData.append('view-qualifications', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setQuawarning(data.warning)
            } else {
                setQualifications(data)
            }
        })
    }

    const [branches, setBranches] = useState([])
    const [selectedbranch, setSelectedbranch] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('branches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranches(data)
            }
        })
    }, [])

    const [openalert, setOpenalert] = useState(false)
    const [branchname, setBranchname] = useState('')
    const [branchcode, setBranchcode] = useState('')
    const [branchid, setBranchid] = useState('')
    const open_alert = (branchid, name, code) => {
        setBranchid(branchid); setBranchname(name); setBranchcode(code)
        setOpenalert(true)
    }

    const [joining, setJoining] = useState(false)
    const join_branch = () => {
        setJoining(true)
        var formData = new FormData()
        formData.append('join-branch', branchid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setMessage(data.warning)
            } else {
                setJoining(false)
                window.location.reload()
            }
        })
    }

    return(
        <Dialog open={true} fullScreen>
            <Header back={true} />
            <Snackbar message={message} open={snackbar} onClose={() => setSnackbar(false)} />
            <DialogContent>
                <div className='container-fluid mb-5 mt-3'>
                    <div className='row'>
                        <div className='col-md-6 col-12 mx-auto'>
                            <div className='row'>
                                {profile.branchid !== null &&
                                <div className='col-12'>
                                    <Avatar src={`/instituteassets/studentimg/${profile.s_pic}`} sx={{borderRadius: 100, width: 200, height: 200, margin: 'auto'}} />
                                </div>}
                                { profile.branchid === null ?
                                <div className='col-12 mt-4'>
                                    <Table component={Paper} >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align='right' >{profile.s_name}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell align='right' >{profile.s_email}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Birth date</TableCell>
                                                <TableCell align='right' >{profile.s_birth}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Gender</TableCell>
                                                <TableCell align='right' >{profile.s_gender}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Joined on</TableCell>
                                                <TableCell align='right' >{profile.s_joindate}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    
                                    <Box component={Paper} mt={3} padding={2} >
                                        <Typography variant='body1' color='black' fontWeight='bold' align='center' >Hi! We also provide offline courses</Typography>

                                        <Typography variant='body2' mt={2} mb={1} >Select your nearest institute</Typography>
                                        <FormControl fullWidth >
                                            <InputLabel id='Institutes' >Institutes</InputLabel>
                                            <Select value={selectedbranch} onChange={(event) => setSelectedbranch(event.target.value)} label='Institutes' >
                                                { branches.map((data, i) =>
                                                <MenuItem value={data.branchid} key={i} onClick={() => open_alert(data.branchid, data.b_name, data.b_code)} >{data.b_name} (City - {data.b_city})</MenuItem> ) }
                                            </Select>
                                        </FormControl>
                                    </Box>

                                </div>
                                :
                                <div className='mt-4 col-12'>
                                    <TableContainer className='mb-4' >
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>{profile.s_name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Father/Monther's name</TableCell>
                                                    <TableCell>{profile.s_guardian}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>{profile.s_address}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Post office</TableCell>
                                                    <TableCell>{profile.s_po}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Police station</TableCell>
                                                    <TableCell>{profile.s_ps}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>City</TableCell>
                                                    <TableCell>{profile.s_city}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>District</TableCell>
                                                    <TableCell>{profile.s_dist}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>State</TableCell>
                                                    <TableCell>{profile.s_state}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Postel</TableCell>
                                                    <TableCell>{profile.s_postel}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Aadhaar</TableCell>
                                                    <TableCell>{profile.s_aadhaar}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Mobile</TableCell>
                                                    <TableCell>{profile.s_mobile}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Birth</TableCell>
                                                    <TableCell>{profile.s_birth}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Gender</TableCell>
                                                    <TableCell>{profile.s_gender}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Nation</TableCell>
                                                    <TableCell>{profile.s_nation}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    
                                    {loading ? <LinearProgress color='warning' /> :
                                    quawarning ? <Alert severity='warning'>{quawarning}</Alert> :
                                    <TableContainer >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Examination</TableCell>
                                                    <TableCell align='center' >Board</TableCell>
                                                    <TableCell align='center' >Pass year</TableCell>
                                                    <TableCell align='right' >Full marks</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {qualifications.map((qua, i) =>
                                                <TableRow key={i} >
                                                    <TableCell>{qua.exam}</TableCell>
                                                    <TableCell align='center' >{qua.board}</TableCell>
                                                    <TableCell align='center' >{qua.pass}</TableCell>
                                                    <TableCell align='right' >{qua.marks}</TableCell>
                                                </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer> }
                                    
                                    <div className='mt-4 text-center'>
                                        <Button color='warning' onClick={() => history.push(`${url}/configure`)} >Add qualification</Button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            
            <Dialog open={openalert} fullWidth maxWidth='xs' onClose={() => setOpenalert(false)} >
                <DialogContent>
                    <Box mb={2} >
                        <Typography variant='body1' mb={0.5} >You are going to join...</Typography>
                        <Typography fontSize={14} fontWeight='bold' color='black' fontFamily='initial' >{branchname}</Typography>
                        <Typography fontSize={14} fontWeight='bold' color='black' fontFamily='initial' >CODE- {branchcode}</Typography>
                        <Typography variant='body2' color='red' mt={1} >Note: This process can't be undone.</Typography>
                        <Typography fontSize={18} fontWeight='bold' color='black' mt={2} >Do you want to confirm?</Typography>
                    </Box><Divider/>
                    <Box mt={2} textAlign='center' >
                        <Button color='primary' variant='outlined' sx={{borderRadius: 0}} disabled={joining} onClick={() => join_branch()} >{joining ? <CircularProgress color='inherit' size={24} /> : 'CONFIRM'}</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Route path={`${path}/configure`} component={ConfigureQualification} />
        </Dialog>
    );

    function ConfigureQualification(){
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [exam, setExam] = useState('')
        const [board, setBoard] = useState('')
        const [pass, setPass] = useState('')
        const [marks, setMarks] = useState('')
        const [adding, setAdding] = useState(false)
        const configure = () => {
            setAdding(true)
            var formData = new FormData()
            formData.append('configure-qualification', exam)
            formData.append('board', board)
            formData.append('pass', pass)
            formData.append('marks', marks)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAdding(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    setExam(''); setBoard(''); setPass(''); setMarks('')
                    setMessage('Add succedd.'); setOpen(true)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Add qualification' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TextField fullWidth variant='standard' label='Examnination' type='text' className='mb-4' value={exam} onChange={(event) => setExam(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Board' type='text' className='mb-4' value={board} onChange={(event) => setBoard(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Passed year' type='number' className='mb-4' value={pass} onChange={(event) => setPass(event.target.value)} />
                                <TextField fullWidth variant='standard' label='Marks obtained' type='number' className='mb-4' value={marks} onChange={(event) => setMarks(event.target.value)} />
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' color='warning' size='large' variant='contained' disabled={adding} onClick={() => configure()} >{adding ? <CircularProgress color='warning' size={24} /> : 'Add' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}