import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

/* MUI */
import { CircularProgress, Dialog, DialogContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import BoyIcon from '@mui/icons-material/Boy';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardIcon from '@mui/icons-material/AddCard';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

export default function HeaderBranch({api}){
    let history = useHistory()
    const [branch, setBranch] = useState([])

    /*useEffect(() => {
        var formData = new FormData()
        formData.append('branch-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranch(data)
            }
        })
    }, [])*/

    const [open, setOpen] = useState(false)

    const [loggingout, setLoggingout] = useState(false)
        const logout = () => {
            setLoggingout(true)
            var formData = new FormData()
            formData.append('logout', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoggingout(false)
                if(data.success){
                    // REDIRECT HERE
                    history.replace('/login-branch')
                }
            })
        }

    return(
        <div className='container-fluid header sticky-top'>
            <div className='row'>
                <div className='col-12 text-center' >
                    <h3 className='fw-bold' style={{marginTop: '0.61em'}}  >{branch.b_name}</h3>
                </div>
            </div>
            <IconButton className='d-md-none' style={{background: '#ef6c00', position: 'fixed', bottom: '3em', right: '1.5em', padding: '0.8em'}} onClick={() => setOpen(true)} ><MenuIcon /></IconButton>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <DialogContent>
                    <List >
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/dashboard-branch')} >
                                <ListItemIcon><DashboardIcon /></ListItemIcon>
                                <ListItemText  >Dashboard</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/attendance-branch')} >
                                <ListItemIcon><BoyIcon /></ListItemIcon>
                                <ListItemText >Attendance</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/student-branch')} >
                                <ListItemIcon><GroupIcon /></ListItemIcon>
                                <ListItemText >Student</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/teacher-branch')} >
                                <ListItemIcon><GroupIcon /></ListItemIcon>
                                <ListItemText >Teacher</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/batch-branch')} >
                                <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                                <ListItemText >Batch</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/payment-branch')} >
                                <ListItemIcon><PaymentIcon /></ListItemIcon>
                                <ListItemText >Payment</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton onClick={() => history.push('/entry-branch')} >
                                <ListItemIcon><AddCardIcon /></ListItemIcon>
                                <ListItemText >Entry</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton >
                                <ListItemIcon>{loggingout ? <CircularProgress color='inherit' /> : <LogoutIcon /> }</ListItemIcon>
                                <ListItemText onClick={() => logout()} >Logout</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
}