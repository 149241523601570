import React, { useEffect, useState } from "react";
import { Route, useRouteMatch, useHistory, useParams } from 'react-router-dom'

import SidebarAdmin from './../sidebar'
import Bar from "../../bar";

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Add, CameraAlt, Close, Edit, FileOpen, Timer, Topic } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function AdminOnlineCourse({api, img}){

    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { path, url } = useRouteMatch()
    let history = useHistory()

    useEffect(() => {
        get_courses()
    }, [])

    const [courseLoading, setCourseLoading] = useState(false)
    const [course, setCourse] = useState([])
    const [courseWarn, setCourseWarn] = useState('')
    const get_courses = () => {
        setCourseLoading(true); setCourseWarn('')
        var formData = new FormData()
        formData.append('online-course', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setCourseLoading(false)
            if(data.warning){
                setCourseWarn(data.warning)
            } else {
                setCourse(data)
            }
        })
    }

    const [opentopic, setOpentopic] = useState(false)
    const [onlinecourseid, setOnlinecourseid] = useState('')
    const open_topics = (onlinecourseid) => {
        setOnlinecourseid(onlinecourseid); setOpentopic(true)
    }

    const topic_list = () => {
        var formData = new FormData()
        formData.append("")
    }

    return(
        <Box>
            <SidebarAdmin />
            <Container className='content' >
                <Box sx={{position: 'fixed', bottom: 50, right: 20, zIndex: 100}} >
                    <IconButton size="large" color='inherit' sx={{bgcolor: 'primary.main'}} title="Add online course" onClick={() => history.push(`${url}/configure/0`)} ><Add/></IconButton>
                </Box>
                
                { courseLoading ? <LinearProgress color="primary" /> :
                courseWarn ? <Alert severity="warning">{courseWarn}</Alert> :
                <Grid container spacing={2} >
                    {course.map((data, i) =>
                    <Grid item xs={12} md={3} key={i} >
                        <Card elevation={0} className="shadow-1" sx={{borderRadius: 2}} >
                            <img src={`${img}/${data.oc_thumbnail}`} title="Course thumbnail" className="c-thumbnail" />

                            <Box sx={{marginTop: -5}} textAlign='right' >
                                <IconButton color="inherit" onClick={() => history.push(`${url}/cover-img/${data.onlinecourseid}`)} ><CameraAlt/></IconButton>
                                <IconButton color="inherit" onClick={() => history.push(`${url}/configure/${data.onlinecourseid}`)} ><Edit/></IconButton>
                                <IconButton color="inherit" onClick={() => history.push(`/online-course-topic-admin/${data.onlinecourseid}`) } ><FileOpen/></IconButton>
                            </Box>
                            <CardContent>
                                <Box mb={2} >
                                    <Typography mb={1} fontSize={22} noWrap >{data.oc_name}</Typography>

                                    <Box height={50} mb={2} >
                                        <Typography color='gray' sx={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'  }} >{data.oc_about}</Typography>
                                    </Box>

                                    <Box sx={{display: 'flex'}} mb={1} >
                                        <Timer color='secondary' />&nbsp;&nbsp;&nbsp;
                                        <Typography noWrap fontSize={16} >{data.oc_watch_time} Days from purchase</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <Typography mt={1} sx={{fontSize: 14, textDecoration: 'line-through'}} color='gray' >&#8377; {Number(data.oc_mrp).toLocaleString('en-in')}</Typography>
                                <Typography fontSize={20} fontWeight='bold' noWrap color='green' >Price: &#8377; {Number(data.oc_price).toLocaleString('en-in')}</Typography>
                            </CardContent>
                        </Card>
                    </Grid> )}
                </Grid>}

                <Dialog open={opentopic} fullWidth maxWidth='xs' fullScreen={fullScreen} onClose={() => setOpentopic(false)} >
                    <Toolbar sx={{backgroundColor: 'primary.main'}} >
                        <Typography fontWeight='bold' flexGrow={1} color='white' >Course topics</Typography>
                        <IconButton color="warning" ><Add /></IconButton>
                        <IconButton color='warning' ><Close/></IconButton>
                    </Toolbar><Divider/>
                    <DialogContent>
                        <List>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemIcon><Topic fontSize="large" /></ListItemIcon>
                                    <ListItemText>List 1 {onlinecourseid} </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </DialogContent>
                </Dialog>

            </Container>

            <Route path={`${path}/configure/:onlinecourseid`} component={Configure} />
            <Route path={`${path}/cover-img/:onlinecourseid`} component={CoverImg} />
        </Box>
    );

    function Configure(){

        const { onlinecourseid } = useParams()

        const [name, setName] = useState('')
        const [mrp, setMrp] = useState('')
        const [price, setPrice] = useState('')
        const [watch, setWatch] = useState('')
        const [about, setAbout] = useState('')
        const [status, setStatus] = useState('')
        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('online-course-detail', onlinecourseid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.oc_name); setMrp(data.oc_mrp); setPrice(data.oc_price); setWatch(data.oc_watch_time); setAbout(data.oc_about); setStatus(data.oc_status)
                }
            })
        }, [onlinecourseid])

        const create_course = () => {
            setWorking(true); setWarning('')
            var formData = new FormData()
            formData.append('configure-online-course', onlinecourseid)
            formData.append('name', name)
            formData.append('mrp', mrp)
            formData.append('price', price)
            formData.append('watch', watch)
            formData.append('about', about)
            formData.append('status', status)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    get_courses()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='md' fullScreen={fullScreen} onClose={() => history.go(-1)} >
                <Bar text='Add online course' />
                <DialogContent>
                    <Stack spacing={3} mb={3} >
                        <TextField fullWidth variant="standard" label="Course name" type="text" value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField fullWidth variant="standard" label="MRP" type="number" value={mrp} onChange={(event) => setMrp(event.target.value)} />
                        <TextField fullWidth variant="standard" label="Price" type="number" value={price} onChange={(event) => setPrice(event.target.value)} />
                        <TextField fullWidth variant="standard" label="Watch limit (Days)" type="number" value={watch} onChange={(event) => setWatch(event.target.value)} />
                        <TextField fullWidth variant="standard" label='About course' multiline rows={3} type="text" value={about} onChange={(event) => setAbout(event.target.value)} />
                        <FormControl fullWidth variant="standard">
                            <InputLabel>Status</InputLabel>
                            <Select value={status} onChange={(event) => setStatus(event.target.value)} >
                                <MenuItem value='active' >Active</MenuItem>
                                <MenuItem value='pending' >Pending</MenuItem>
                                <MenuItem value='archive' >Archive</MenuItem>
                            </Select>
                        </FormControl>
                        { warning && <Alert severity="warning">{warning}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" onClick={() => history.go(-1)} >CLOSE</Button>
                    <Button variant="contained" color="primary" onClick={() => create_course()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'UPLOAD'} </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function CoverImg(){
        const { onlinecourseid } = useParams()

        const [uploadWarn, setUploadWarn] = useState('')
        const [uploading, setUploading] = useState(false)

        const changeimg = (event) => {
            setUploadWarn(''); setUploading(true)
            var formData = new FormData()
            formData.append('add-thumbnail', onlinecourseid)
            formData.append('img', event.target.files[0])
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setUploadWarn(data.warning)
                } else {
                    get_courses(); history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} onClose={() => history.go(-1)} >
                <DialogTitle>Cover image</DialogTitle><Divider/>
                <DialogContent sx={{marginBottom: 2}} >
                    <Typography variant="body2" mb={1} color='gray' >For better user experience. Please upload 300x150 Pixel (W/H) image.</Typography>
                    <Stack spacing={2}>
                        <input type="file" accept='.jpg, .jpeg, .png' disabled={uploading} onChange={(event) => changeimg(event)} />
                        {uploadWarn && <Alert severity="warning">{uploadWarn}</Alert> }
                        {uploading && <LinearProgress color="primary" /> }
                    </Stack>
                </DialogContent>
            </Dialog>
        );
    }

}