import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

/* MUI */
import { AppBar, Badge, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { ArrowBack, Dashboard, History, Payment, Person, PowerSettingsNew } from '@mui/icons-material'

export default function Header({back}){

    const [ldialog, setLdialog] = useState(false)
    const history = useHistory()

    useEffect(() => {
        fetch('/radiantapi/student/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-student')
            }
        })
    }, [])

    const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    const links = [
        {name: 'Home', link: '/dashboard-student', icon: <Dashboard />},
        {name: 'Attendance', link: '/attendance-history-student', icon: <History />},
        {name: 'Payment', link: '/payment-history-student', icon: <Payment />},
        {name: 'Profile', link: '/profile-student', icon: <Person />}
    ]

    const [lworking, setLworking] = useState(false)
    const logout = () => {
        setLworking(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch('/radiantapi/student/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLworking(false)
            if(data.success){
                history.push('/login-student')
            }
        })
    }

    return(
        <>
            <AppBar position='sticky' color='primary' >
                <Toolbar>
                    { back === true && <Box sx={{flexGrow: 1}} ><IconButton edge='start' color='inherit' onClick={() => history.go(-1)} ><ArrowBack /></IconButton></Box> }
                    { back === false && <Typography fontWeight='bold' fontSize={30} fontFamily='serif' letterSpacing={3} color='white' sx={{flexGrow: 1}} >RIAT</Typography> }
                    
                    <Stack direction='row' alignItems='center' spacing={1} display={{xs: 'none', md: 'block'}} >
                        { links.map((data, i) =>
                            <Link to={data.link} key={i} style={{color: 'white'}} >{data.name}</Link>
                        ) }
                    </Stack>

                    <IconButton onClick={() => history.push('/coin-history-student')} >
                        <Badge badgeContent={getCookie('coins')} max={9999} color='warning' >
                            <img src='/images/coin.gif' alt='Coin' style={{width: 30}} />
                        </Badge>
                    </IconButton>
                    <IconButton color='inherit' edge='end' onClick={() => setLdialog(true)} ><PowerSettingsNew/></IconButton>
                </Toolbar>
            </AppBar>

            <Box sx={{position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fafafa', zIndex: 100, display: {xs: 'block', md: 'none'}}} pl={1} pr={1} padding={0.6} >
                <Stack justifyContent='space-between' alignItems='center' direction='row'>
                    { links.map((data, i) => 
                        <IconButton key={i} onClick={() => history.push(data.link)} >{data.icon}</IconButton>
                    ) }
                </Stack>
            </Box>

            <Dialog open={ldialog} fullWidth={true} maxWidth='xs' onClose={() => setLdialog(false)} >
                <DialogContent>
                    <Box mt={1}>
                        <Typography variant='h6' fontWeight='bold' >Do you want to logout?</Typography>
                        <Typography color='gray' variant='caption' >Note: This action will redirect you to the login page.</Typography>
                    </Box>
                    <Box textAlign='center' mt={3} mb={1} >
                        <Button variant='contained' color='error' sx={{boxShadow: 0, borderRadius: 0.5}} onClick={() => logout()} disabled={lworking} >{lworking ? <CircularProgress color='inherit' size={24} /> : 'YES, LOGOUT'}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}