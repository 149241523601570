import React, { useEffect, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import Header from "./header";

/* MUI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowUpward,
  ExpandMore,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  Redeem,
  WhatsApp,
} from "@mui/icons-material";

export default function CoinHistoryStudent({ api }) {
  const history = useHistory();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    coin_history();
  }, []);

  const [coins, setCoins] = useState([]);
  const [cwarn, setCwarn] = useState("");
  const [cloading, setCloading] = useState(true);
  const coin_history = () => {
    var formData = new FormData();
    formData.append("coin-history", "");
    fetch(api, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setCloading(false);
        if (data.warning) {
          setCwarn(data.warning);
        } else {
          setCoins(data);
        }
      });
  };

  const [twarn, setTwarn] = useState("");
  const [tloading, setTloading] = useState(false);
  const [topen, setTopen] = useState(false);

  const [sendtoreferid, setSendtoreferid] = useState("");
  const [coinstosend, setCoinstosend] = useState(10);
  const [referidname, setReferidname] = useState([]);
  const coin_verify_referid = () => {
    setTloading(true);
    var formData = new FormData();
    formData.append("coin-verify-referid", sendtoreferid);
    fetch(api, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTloading(false);
        if (data.warning) {
          setTwarn(data.warning);
        } else {
          setTwarn("");
          setCtopen(true);
          setReferidname(data);
        }
      });
  };

  const [ctopen, setCtopen] = useState(false);
  const [otp, setOtp] = useState("");
  const send_coin_to_referid = () => {
    setTloading(true);
    var formData = new FormData();
    formData.append("coin-transfer", sendtoreferid);
    formData.append("coins", coinstosend);
    formData.append("otp", otp);
    fetch(api, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setTloading(false);
        if (data.warning) {
          setTwarn(data.warning);
        } else if (data.success) {
          coin_history();
          setCtopen(false);
          setTopen(false);
        }
      });
  };

  const activecoin = coins.reduce((total, item) => {
    if (item.coin_status.toLowerCase() === "active") {
      return total + parseInt(item.coin);
    }
    return total;
  }, 0);
  const redeemedcoin = coins.reduce((total, item) => {
    if (item.coin_status.toLowerCase() === "redeemed") {
      return total + parseInt(item.coin);
    }
    return total;
  }, 0);

  const [adddialog, setAdddialog] = useState(false);

  return (
    <Box mb={7}>
      <Header back={true} />
      <Container>
        <Grid container mt={3}>
          <Grid item xs={12} md={7} mx="auto">
            <Card>
              <CardContent>
                <Toolbar>
                  <Typography
                    fontSize={20}
                    color="green"
                    fontWeight="bold"
                    sx={{ flexGrow: 1 }}
                  >
                    Coins
                  </Typography>
                  <img
                    src="/images/coin.gif"
                    alt="Coin"
                    style={{ width: 30 }}
                  />{" "}
                  &nbsp;
                  <Typography
                    fontSize={15}
                    color="black"
                    fontWeight="bold"
                    letterSpacing={2}
                  >
                    {activecoin - redeemedcoin}
                  </Typography>
                </Toolbar>
                <Toolbar>
                  <Typography
                    fontSize={20}
                    color="orange"
                    fontWeight="bold"
                    sx={{ flexGrow: 1 }}
                  >
                    Redeemed coins
                  </Typography>
                  <img
                    src="/images/coin.gif"
                    alt="Coin"
                    style={{ width: 30 }}
                  />{" "}
                  &nbsp;
                  <Typography
                    fontSize={15}
                    color="black"
                    fontWeight="bold"
                    letterSpacing={2}
                  >
                    {redeemedcoin}
                  </Typography>
                </Toolbar>
                <Typography variant="body2" color="gray" align="right">
                  Each coin value is 10 &#8377;{" "}
                </Typography>
                <Typography variant="body2" color="gray" align="right">
                  Your total coin value is{" "}
                  {coins.reduce(
                    (t, i) => i.coin_status === "active" && t + Number(i.coin),
                    0
                  ) * 10}{" "}
                  &#8377;{" "}
                </Typography>
                <Divider />
                <Stack
                  spacing={1}
                  mt={2}
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    color="info"
                    variant="contained"
                    sx={{ borderRadius: 0, boxShadow: 0, width: "100%" }}
                    startIcon={<Redeem />}
                    onClick={() => history.push(`${url}/redeem`)}
                  >
                    REDEEM COIN
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ borderRadius: 0, boxShadow: 0, width: "100%" }}
                    onClick={() => setTopen(true)}
                    startIcon={<ArrowUpward />}
                  >
                    TRANSFER COIN
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ borderRadius: 0, boxShadow: 0, width: "100%" }}
                    onClick={() => setAdddialog(true)}
                    startIcon={<Add />}
                  >
                    ADD COIN
                  </Button>
                </Stack>
              </CardContent>
            </Card>

            <TableContainer sx={{ marginTop: 3 }} component={Paper}>
              {cloading ? (
                <LinearProgress color="primary" />
              ) : cwarn ? (
                <Alert severity="warning">{cwarn}</Alert>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="center">Coin</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coins.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{data.coin_date}</TableCell>
                        <TableCell align="center">{data.coin}</TableCell>
                        <TableCell align="right">
                          {data.coin_status === "active" ? (
                            <>
                              Received
                              <KeyboardDoubleArrowDown color="success" />
                            </>
                          ) : (
                            <>
                              Redeemed
                              <KeyboardDoubleArrowUp color="error" />
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={topen}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setTopen(false)}
      >
        <DialogTitle>Transfer coin</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2} pb={3}>
            <TextField
              fullWidth
              variant="standard"
              label="Refer ID"
              type="text"
              value={sendtoreferid}
              onChange={(event) => setSendtoreferid(event.target.value)}
            />
            <TextField
              fullWidth
              variant="standard"
              label="Coin"
              type="number"
              value={coinstosend}
              onChange={(event) => setCoinstosend(event.target.value)}
            />
            {twarn && <Alert severity="warning">{twarn}</Alert>}
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 0, boxShadow: 0 }}
                disabled={tloading}
                onClick={() => coin_verify_referid()}
              >
                {tloading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "TRANSFER"
                )}
              </Button>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={ctopen}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setCtopen(false)}
      >
        <DialogContent>
          <Stack
            mb={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="gray">
              Transferring to
            </Typography>
            <Typography fontWeight="bold" fontSize={15} letterSpacing={3}>
              {referidname.s_name}
            </Typography>
          </Stack>
          <Divider />
          <Stack
            mb={2}
            mt={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="gray">
              coins
            </Typography>
            <Typography fontWeight="bold" fontSize={20} letterSpacing={3}>
              {coinstosend}
            </Typography>
          </Stack>
          <Divider />
          <TextField
            sx={{ marginTop: 3 }}
            fullWidth
            variant="outlined"
            label="OTP"
            helperText="OTP sent your email"
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
          />
          <Box mt={1} mb={4}>
            {twarn && <Alert severity="warning">{twarn}</Alert>}
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ boxShadow: 0, borderRadius: 0 }}
                onClick={() => send_coin_to_referid()}
                disabled={tloading}
              >
                {tloading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "CONFIRM"
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={adddialog} fullWidth onClose={() => setAdddialog(false)}>
        <DialogTitle>Manually payment</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="h6" color="black">
            We are currently support manually payment method.
          </Typography>
          <Typography variant="body1">Follow steps:</Typography>
          <Typography variant="body2">
            1. You can pay us the amount of the coins on
          </Typography>
          <Typography variant="body2" ml={2}>
            Account number - 34181149848
          </Typography>
          <Typography variant="body2" ml={2}>
            IFSC - SBIN0016623
          </Typography>

          <Typography variant="body2" mt={1}>
            2. Take the screenshot of successfull payment page.
          </Typography>
          <Typography variant="body2" mt={1}>
            3. Send the screenshot and course name on WhatsApp.
          </Typography>
          <Typography variant="body2" ml={2} mt={1}>
            <a href="https://wa.me/919534541471" rel="noreffer">
              <WhatsApp fontSize="small" /> 9534541471
            </a>
          </Typography>
        </DialogContent>
      </Dialog>

      <Route path={`${path}/redeem`} component={Redeem_coin} />
    </Box>
  );

  function Redeem_coin() {
    const [working, setWorking] = useState(false);
    const [warning, setWarning] = useState("");
    const [coins, setCoins] = useState(100);
    const [otp, setOtp] = useState("");
    const [sent, setSent] = useState(false);

    const send_otp = () => {
      setWarning("");
      setWorking(true);
      var formData = new FormData();
      formData.append("verify-coin-to-redeem", coins);
      fetch(api, {
        method: "post",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setWorking(false);
          if (data.warning) {
            setWarning(data.warning);
          } else {
            setSent(true);
          }
        });
    };

    const redeem_coin = () => {
      setWarning("");
      setWorking(true);
      var formData = new FormData();
      formData.append("redeem-coin", coins);
      formData.append("otp", otp);
      fetch(api, {
        method: "post",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setWorking(false);
          if (data.warning) {
            setWarning(data.warning);
          } else {
            coin_history();
            history.go(-1);
          }
        });
    };

    const [acnum, setAcnum] = useState("");
    const [cacnum, setCacnum] = useState('')
    const [ifsc, setIfsc] = useState("");
    const [upi, setUpi] = useState("");
    useEffect(() => {
      var formData = new FormData();
      formData.append("get-account-detail", "");
      fetch(api, {
        method: "post",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (!data.warning) {
            setAcnum(data.s_ac);
            setIfsc(data.s_ifsc);
            setUpi(data.s_upi);
          }
        });
    }, []);

    const [updating, setUpdating] = useState(false);
    const update_ac = () => {
      if(acnum.length > 0 && acnum !== '0' ){
        if(acnum === cacnum){
          update_here()
        } else {
          setWarning('Your account number and confirm account number are not same.');
        }
      } else {
        update_here()
      }
    };

    const update_here = () => {
      setUpdating(true);
      setWarning("");
      var formData = new FormData();
      formData.append("add-account-detail", acnum);
      formData.append("ifsc", ifsc);
      formData.append("upi", upi);
      fetch(api, {
        method: "post",
        body: formData,
      })
      .then((res) => res.json())
      .then((data) => {
        setUpdating(false);
        if (data.warning) {
          setWarning(data.warning);
        } else if (data.success) {
          setWarning("Account detail updation succeed.");
        }
      });
    }

    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="xs"
        onClose={() => history.go(-1)}
      >
        <DialogTitle>Redeem coin</DialogTitle>
        <DialogContent>
          <Box mt={1} mb={1}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label="Redeem you coin"
                value={coins}
                onChange={(event) => setCoins(event.target.value)}
              />
              {sent === true && (
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  label="OTP"
                  helperText="OTP sent to your email"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                />
              )}
            </Stack>

            <Typography variant="body2" color="gray" mt={1}>
              *Minimum 100 coins are required.
            </Typography>
            <Typography variant="body2" color="gray" mb={1}>
              *The redemption of your coin into cash will take 7 to 10 working
              days.
            </Typography>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Account Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1.5}>
                  <TextField fullWidth variant="outlined" type="number" label="Account Number" value={acnum} onChange={(event) => setAcnum(event.target.value)} />
                  <TextField fullWidth variant="outlined" type="password" label="Confirm Account Number" value={cacnum} onChange={(event) => setCacnum(event.target.value)} />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="IFSC"
                    type="text"
                    value={ifsc}
                    onChange={(event) => setIfsc(event.target.value)}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="UPI ID"
                    type="text"
                    value={upi}
                    onChange={(event) => setUpi(event.target.value)}
                  />

                  <Button
                    variant="contained"
                    color="inherit"
                    sx={{ width: "100%", borderRadius: 0, boxShadow: 0 }}
                    disabled={updating}
                    onClick={() => update_ac()}
                  >
                    {updating ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
          {warning && <Alert severity="warning">{warning}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => history.go(-1)}>
            CLOSE
          </Button>
          {sent === false ? (
            <Button
              color="primary"
              variant="contained"
              disabled={working}
              onClick={() => send_otp()}
            >
              {working ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "NEXT"
              )}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={working}
              onClick={() => redeem_coin()}
            >
              {working ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "REDEEM"
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
