import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import SidebarBranch from './sidebar'
import HeaderBranch from './header'

/* MUI */
import { Alert, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';

export default function StudentList({api}){
    let history = useHistory()

    const printIt = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML
        document.body.innerHTML = getArea            
        window.print()
        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }
    const [filter, setFilter] = useState('')
    const [batch, setBatch] = useState('')
    useEffect(() => {
        studentLists(filter, batch)
    }, [filter, batch])

    const [loading, setLoading] = useState(true)
    const [warning, setWarning] = useState('')
    const [students, setStudents] = useState([])
    const studentLists = (filter, batch) => {
        setWarning('')
        var formData = new FormData()
        formData.append('student-list', filter)
        formData.append('batchid', batch)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const [batches, setBatches] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('view-batches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBatches(data)
            }
        })
    }, [])

    return(
        <>
        <SidebarBranch api={api} />
        <div className='content'>
        <div className='container-fluid mb-5 mt-4'>
            <div className='row' >
                <div className='col-8 pe-0'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Filter student</InputLabel>
                        <Select value={filter} onChange={(event) => setFilter(event.target.value)} >
                            <MenuItem value='ALL' >All</MenuItem>
                            <MenuItem value='VERIFIED' >Verified</MenuItem>
                            <MenuItem value='ISSUED' >Issued</MenuItem>
                            <MenuItem value='PENDING' >Pending</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-4 ps-0'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>Select batch</InputLabel>
                        <Select value={batch} onChange={(event) => setBatch(event.target.value)} >
                            { batches.map((batch, i) => 
                                <MenuItem key={i} value={batch.batchid} >{batch.bh_start} TO {batch.bh_end}</MenuItem>
                            ) }
                        </Select>
                    </FormControl>
                </div>
                <div className='col-12 text-end'>
                    <IconButton onClick={() => printIt()} ><PrintIcon /></IconButton>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-md-8 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12'>
                            { loading ? <LinearProgress color='warning' /> :
                            warning ? <Alert severity='warning'>{warning}</Alert> :
                            <TableContainer id='printArea' >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Father/Mother's name</TableCell>
                                            {batch && <TableCell>Batch</TableCell> }
                                            <TableCell align='center' >Code</TableCell>
                                            <TableCell align='center' >Reg. Num</TableCell>
                                            <TableCell align='center' >Reg. Date</TableCell>
                                            <TableCell align='center' >Issue date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { students.map((stu, i) =>
                                        <TableRow key={i} >
                                            <TableCell className='pointer' onClick={() => history.push(`/student-branch/configure/${stu.studentid}`)} >{stu.s_name}</TableCell>
                                            <TableCell>{stu.s_guardian}</TableCell>
                                            {batch && <TableCell>{stu.bh_start} TO {stu.bh_end}</TableCell> }
                                            <TableCell align='center' >{stu.s_code}</TableCell>
                                            <TableCell align='center' >{stu.s_enrollment}</TableCell>
                                            <TableCell align='center' >{stu.s_register}</TableCell>
                                            <TableCell align='center' >{stu.s_issuedate}</TableCell>
                                        </TableRow> ) }
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}