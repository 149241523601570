import React, { useEffect, useState, useRef } from "react";
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Bar from "../../bar";

// MUI
import { Alert, AppBar, Avatar, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Add, ArrowBack, Edit, Event, Folder, Image, Timelapse, Videocam } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function OnlineCourseVideos({api, img}){

    const history = useHistory()
    const { path, url } = useRouteMatch()
    const { onlinefolderid } = useParams()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const videoRef = useRef(null);
    new Plyr(videoRef.current);

    const [vwarn, setVwarn] = useState('')
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        get_videos(onlinefolderid)
    }, [onlinefolderid])
    const get_videos = (onlinefolderid) => {
        setVwarn('')
        var formData = new FormData()
        formData.append('ov-videos', onlinefolderid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setVwarn(data.warning)
            } else {
                setVideos(data)
            }
        })
    }

    const [progress, setProgress] = useState(0)
    const [upwarn, setUpwarn] = useState('')
    const [thumbnailDialog, setThumbnailDialog] = useState(false)
    const [getvideoid, setGetvideoid] = useState(0)

    const open_thumbnail_dialog = (videoid) => {
        setGetvideoid(videoid); setThumbnailDialog(true)
    }

    const add_thumbnail = (event) => {
        setProgress(1); setUpwarn('')
        var formData = new FormData()
        formData.append('ov-thumbnail', getvideoid)
        formData.append('onlinefolderid', onlinefolderid)
        formData.append('thumbnail', event.target.files[0])
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setProgress(0)
            if(data.warning){
                setUpwarn(data.warning)
            } else {
                get_videos(onlinefolderid)
                setThumbnailDialog(false)
            }
        })
    }

    const [videoDialog, setVideoDialog] = useState(false)
    const [videopath, setVpath] = useState('')
    const open_video_dialog = (videoid, path) => {
        setGetvideoid(videoid); setVideoDialog(true); setVpath(path)
    }

    const add_video = (event) => {
        setUpwarn('')
        var formData = new FormData()
        formData.append('ov-video', getvideoid)
        formData.append('onlinefolderid', onlinefolderid)
        formData.append('video', event.target.files[0])
        
        const xhr = new XMLHttpRequest()
        xhr.open('POST', api, true);

        xhr.addEventListener('load', () => {
            if(xhr.status === 200){
                const data = JSON.parse(xhr.responseText)
                if(data.warning){
                    setProgress(0)
                    setUpwarn(data.warning)
                } else if(data.success){
                    get_videos(onlinefolderid)
                    open_video_dialog(false)
                    setVideoDialog(false); setProgress(0)
                }
            }
        })
        xhr.upload.addEventListener('progress', e => {
            setProgress(Math.round((e.loaded/e.total) * 100))
        })
        xhr.send(formData)
    }

    return(
        <Box mb={2} >
            <AppBar position="sticky" >
                <Toolbar>
                    <IconButton edge='start' color="inherit" onClick={() => history.go(-1)} ><ArrowBack/></IconButton>
                    <Typography color='white' variant="body2" sx={{flexGrow: 1}} >Online course videos</Typography>

                    <IconButton edge='end' color="inherit" onClick={() => history.push(`${url}/upload-video/0`)} ><Add/></IconButton>
                </Toolbar>
            </AppBar>

            { loading ? <LinearProgress color="primary" /> :
            vwarn ? <Alert severity="warning">{vwarn}</Alert> :
            <Container sx={{marginTop: 4}} >
                <Grid container spacing={2} >
                    { videos.map((data, i) =>
                    <Grid item xs={12} md={3} key={i} >
                        <Box sx={{maxWidth: 350, height: 360, position: 'relative', margin: 'auto'}} component={Paper} >
                            <Box onClick={() => history.push(`/online-video-player-admin/${data.ov_path}/${data.ov_title}`)} >
                                <Avatar src={`${img}/${data.ov_thumbnail}`} alt="Thumbnail" sx={{width: '100%', height: '150px', borderRadius: '7px 7px 0 0'}} />
                                <Box padding={1} >
                                    <Typography mb={1} fontSize={18} fontWeight='bold' className="wrap-2" >{data.ov_title}</Typography>
                                    <Typography color='gray' mb={1} variant="body2" className="wrap-2" >{data.ov_about}</Typography>
                                </Box>

                                <Stack direction='row' alignItems='center' sx={{position: 'absolute', bottom: 40}} padding={1} >
                                    <Timelapse color='primary' fontSize='small' /> &nbsp;&nbsp;
                                    <Typography color='gray' variant="body2" >{data.ov_duration} Minutes</Typography> &nbsp;&nbsp;
                                    <Event color='secondary' fontSize="small" /> &nbsp;&nbsp;
                                    <Typography color='gray' variant="body2" >{data.ov_added}</Typography>
                                </Stack>
                            </Box>
                            
                            <Box textAlign='right' sx={{position: 'absolute', width: '100%', bottom: 0}} >
                                <Divider/>
                                <IconButton onClick={() => history.push(`${url}/upload-video/${data.onlinevideoid}`)} ><Edit/></IconButton>
                                <IconButton onClick={() => open_thumbnail_dialog(data.onlinevideoid)} ><Image/></IconButton>
                                <IconButton onClick={() => open_video_dialog(data.onlinevideoid, data.ov_path)} ><Videocam/></IconButton>
                            </Box>
                        </Box>
                    </Grid>) }
                </Grid>
            </Container> }
            
            <Dialog open={thumbnailDialog} fullWidth={true} maxWidth='xs'>
                <DialogContent>
                    <Typography variant="body2" mb={2} >Select an image (size - 1280x720, jpeg)</Typography>
                    <Stack spacing={2} >
                        <input type="file" accept=".jpg, .jpeg" disabled={progress > 0 && true} onChange={(event) => add_thumbnail(event)} />
                        {progress > 0 && <LinearProgress color="primary" /> }
                        {upwarn && <Alert severity="warning">{upwarn}</Alert> }
                    </Stack>
                    <Box mt={2} textAlign='center' >
                        <Button variant="text" color="error" size="small" onClick={() => setThumbnailDialog(false)} disabled={progress > 0 && true} >CLOSE</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={videoDialog} fullWidth={true} maxWidth='xs'>
                <DialogContent>
                    <Typography variant="body2" mb={2} >Select a video (only .mp4)</Typography>
                    <Stack spacing={2} >
                        <input type="file" accept=".mp4" disabled={progress > 0 && true} onChange={(event) => add_video(event)} />
                        {progress > 0 && <LinearProgress variant="determinate" color="primary" value={progress} /> }
                        {upwarn && <Alert severity="warning">{upwarn}</Alert> }
                    </Stack>
                    
                    {videopath !== null &&
                        <Box textAlign='center'>
                            <img src='/images/tick.gif' alt="Complete gif" style={{width: 200}} />
                        </Box>
                    }

                    <Box mt={2} textAlign='center' >
                        <Button variant="text" color="error" size="small" onClick={() => setVideoDialog(false)} disabled={progress > 0 && true} >CLOSE</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            
            <Route path={`${path}/upload-video/:onlinevideoid`} component={UploadContent} />
        </Box>
    );

    function UploadContent(){

        const { onlinevideoid } = useParams()

        const [title, setTitle] = useState('')
        const [duration, setDuration] = useState('')
        const [about, setAbout] = useState('')
        const [status, setStatus] = useState('')

        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('ov-content-detail', onlinevideoid)
            formData.append('onlinefolderid', onlinefolderid);
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setTitle(data.ov_title); setDuration(data.ov_duration); setAbout(data.ov_about); setStatus(data.ov_status)
                }
            })
        }, [onlinevideoid, onlinefolderid])

        const upload = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('ov-content', onlinevideoid)
            formData.append('onlinefolderid', onlinefolderid)
            formData.append('title', title)
            formData.append('about', about)
            formData.append('duration', duration)
            formData.append('status', status)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    get_videos(onlinefolderid)
                    history.go(-1)
                }
            })
        }
        
        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => history.go(-1)}>
                <Bar text='Upload video & content' />
                <DialogContent>
                    <Stack spacing={2} >
                        <TextField fullWidth variant="outlined" label='Title' type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
                        <TextField fullWidth variant="outlined" label='Duration (in minute)' type="number" value={duration} onChange={(event) => setDuration(event.target.value)} />
                        <TextField fullWidth variant="outlined" label='About' type="text" rows={3} multiline value={about} onChange={(event) => setAbout(event.target.value)} />
                        <FormControl fullWidth variant="outlined" >
                            <InputLabel>Status</InputLabel>
                            <Select label='Status' value={status} onChange={(event) => setStatus(event.target.value)} >
                                <MenuItem value='active'>Active</MenuItem>
                                <MenuItem value='pending'>Pending</MenuItem>
                                <MenuItem value='archive'>Archive</MenuItem>
                            </Select>
                        </FormControl>

                        { warning && <Alert severity="warning">{warning}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color='inherit' variant="text" onClick={() => history.go(-1)} >CLOSE</Button>
                    <Button color="primary" variant="contained" disabled={working} onClick={() => upload()} >{working ? <CircularProgress color="inherit" size={24} /> : 'UPLOAD'}</Button>
                </DialogActions>
            </Dialog>
        );
    }
    
}