import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import QRCode from "react-qr-code";

import Bar from './../bar'

/* MUI */
import { Box, Button, Container, Grid, Typography } from '@mui/material'

export default function ComputerCertificateNew({api, simg}){
    const { studentid } = useParams()
    const history = useHistory()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [studentid])

    const area = useRef()

    return(
        <Box><Bar text='Certificate' print={true} area={area} />
        
        <Container>
            <Grid container mt={4} >
                <Grid item xs={12} md={12} overflow='auto' margin='auto' >
                    <Box textAlign='center' >
                        <Button variant='outlined' onClick={() => history.push(`/mark-new/${studentid}`)} >GO TO MARKSHEET</Button>
                    </Box>
                    <Box sx={{position: 'relative', width: '297mm', height: '209mm'}} ref={area} padding={2} >
                        <img src='/cert/cert.jpg' alt='Certificate' style={{width: '100%', height: '100%'}} />
                        <Box sx={{left: '25mm', top: '47mm', position: 'absolute', background: 'white', padding: 0.7}} >
                            <QRCode size={105} value={`This certificate has issued by 'RADIANT INSTITUTE OF ADVANCE TECHNOLOGY' from '${preview.b_name}' of ${preview.s_name} (Registration number: ${preview.s_enrollment}) on ${preview.s_issuedate}`} />
                        </Box>
                        <Box sx={{right: '25mm', top: '47mm', position: 'absolute', background: 'white', padding: 0.7}} >
                            <img src={`${simg}/${preview.s_pic}`} alt='Student' style={{width: '100px', height: '120px'}} />
                        </Box>
                        <Typography sx={{position: 'absolute', top: '81.5mm', left: '55mm'}} color='black' fontWeight='bold' >{preview.s_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '93.5mm', left: '55mm'}} color='black' fontWeight='bold' >{preview.s_guardian}</Typography>
                        <Typography sx={{position: 'absolute', top: '93.5mm', right: '38mm'}} color='black' fontWeight='bold' >{preview.c_duration}</Typography>
                        <Typography sx={{position: 'absolute', top: '106.5mm', left: '90mm'}} color='black' fontWeight='bold' >{preview.c_name} ({preview.c_title})</Typography>
                        <Typography sx={{position: 'absolute', top: '117.5mm', left: '45mm'}} color='black' fontWeight='bold' >{preview.b_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '129.5mm', left: '120mm'}} color='black' fontWeight='bold' >{(Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 85 ? 'A+' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 70 ? 'A' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 60 ? 'B' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 45 && 'C'}</Typography>
                        <Typography sx={{position: 'absolute', top: '147mm', left: '54mm'}} color='black' fontWeight='bold' >{preview.s_issuedate}</Typography>
                        <Typography sx={{position: 'absolute', top: '147.3mm', left: '153mm'}} color='black' fontWeight='bold' >{preview.s_enrollment}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}