import React, { useEffect, useState } from 'react'

/* HEADER */
import PagesHeader from './../components/Navbar';
import PagesFooter from './../components/Footer'

/* MATERIAL UI */
import { IconButton } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function GetFranchise(){

    useEffect(() => {
        document.title = 'Get-Franchise'
    }, [])

    const [branches, setBranch] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('our-branch', '')
        fetch('/instituteapi/public/index.php', {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setBranch(data)
            }
        })
    }, [])

    return(
        <><PagesHeader />
        <div className='container-fluid mt-5 mb-5'>
            <div className='row p-3'>
                <div className='col-md-6 col-12 p-5 mb-4'>
                    <img src='/instituteassets/get-franchise.svg' alt='nexus franchise' />
                </div>
                <div className='col-md-6 col-12 flex p-3'>
                    <h2 className='fw-bold'>Lets make this education more interesting!</h2>

                    <h3 className='fw-bold mt-3'>To get our <u>Franchise...</u></h3>
                    <div className='row mt-4'>
                        <div className='col-md-6 col-12 mb-5'>
                            <div className='rounded-3 lightshadow p-3'>
                                <IconButton className='bgOrange'> <CallIcon className='text-light' style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='fw-bold mt-4'>Make a call</h5>
                                <a href='tel: 9431668289' className='colorBlack' rel='next'>9431668289</a>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='rounded-3 lightshadow p-3'>
                                <IconButton className='bgOrange'> <MailOutlineIcon className='text-light' style={{fontSize: '2em'}} /></IconButton>
                                <h5 className='fw-bold mt-4'>Write your query</h5>
                                <a href='mailto: nexuscomputerkatihar@gmail.com' className='colorBlack' rel='next'>nexuscomputerkatihar@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4 row p-3'>
                <div className='text-center col-12 mb-4'>
                    <h3 className='fw-bold lspace5'>OUR BRANCH & FRANCHISE</h3>
                </div>
                {branches.map((branch, i) =>
                <div className='col-md-6 col-12 mb-4' key={i} >
                    <div className='lightshadow p-3 rounded-3'>
                        <div className='text-center'>
                            <img src={`/instituteassets/branchimg/${branch.b_pic}`} className='globalimgsize' alt='Branch' />
                        </div>
                        <div className='mt-3'>
                            <table className='table table-borderless'>
                                <h5 className='fw-bold ms-2'>{branch.b_name}</h5>
                                <tbody>
                                    <tr><td>Code</td><td>{branch.b_code}</td></tr>
                                    <tr><td>Address</td><td>{branch.b_address}</td></tr>
                                    <tr><td>State</td><td>{branch.b_state}</td></tr>
                                    <tr><td>Category</td><td>{branch.b_category}</td></tr>
                                    <tr><td>Mobile No</td><td>{branch.b_omobile}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> )}
                </div>
        </div><PagesFooter/> </>
    );
}