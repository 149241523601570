import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import QRCode from "react-qr-code";

import Bar from './../bar'

/* MUI */
import { Box, Button, Container, Grid, Typography } from '@mui/material'

export default function MarksheetNew({api, simg}){
    const { studentid } = useParams()
    const history = useHistory()

    const [preview, setPreview] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPreview(data)
            }
        })
    }, [studentid])

    const [results, setResults] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('marksheet-result', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setResults(data)
            }
        })
    }, [studentid])

    const area = useRef()

    return(
        <Box><Bar text='Marksheet' print={true} area={area} />
        
        <Container>
            <Grid container mt={4} >
                <Grid item xs={12} md={8} overflow='auto' margin='auto' >
                    <Box textAlign='center' >
                        <Button variant='outlined' onClick={() => history.push(`/cert-new/${studentid}`)} >GO TO CERTIFICATE</Button>
                    </Box>
                    <Box sx={{position: 'relative', height: '296mm', width: '210mm', margin: 'auto'}} ref={area} padding={2} >
                        <img src='/cert/marksheet.jpg' alt='Certificate' style={{width: '100%', height: '100%'}} />
                        <Box sx={{left: '12mm', top: '46mm', position: 'absolute', background: 'white', padding: 0.7}} >
                            <QRCode size={100} value={`This marksheet has issued by 'RADIANT INSTITUTE OF ADVANCE TECHNOLOGY' from '${preview.b_name}' of ${preview.s_name} (Registration number: ${preview.s_enrollment}) on ${preview.s_issuedate}`} />
                        </Box>
                        {/*<Box sx={{right: '12mm', top: '44mm', position: 'absolute', background: 'white', padding: 0.7}} >
                            <img src={`${simg}/${preview.s_pic}`} alt='Student' style={{width: '95px', height: '110px'}} />
    </Box>*/}
                        <Typography sx={{position: 'absolute', top: '95.5mm', left: '53mm'}} color='black' fontWeight='bold' variant='body2' >{preview.s_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '107.5mm', left: '53mm'}} color='black' fontWeight='bold' variant='body2' >{preview.s_guardian}</Typography>
                        <Typography sx={{position: 'absolute', top: '120.5mm', left: '57mm'}} color='black' fontWeight='bold' variant='body2' >{preview.b_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '132.8mm', left: '50mm'}} color='black' fontWeight='bold' variant='body2' >{preview.b_code}</Typography>

                        <Typography sx={{position: 'absolute', top: '147mm', left: '24.5mm'}} color='black' variant='caption' fontWeight='bold' >{preview.c_duration}</Typography>
                        <Typography sx={{position: 'absolute', top: '147mm', left: '73mm'}} color='black' variant='caption' fontWeight='bold' >{preview.s_enrollment}</Typography>
                        <Typography sx={{position: 'absolute', top: '145mm', right: '38mm'}} color='black' variant='caption' fontWeight='bold' >{preview.s_issuedate}</Typography>

                        <Box sx={{position: 'absolute', top: '167mm', width: '100%'}} textAlign='center' >
                            <Typography color='black' fontWeight='bold' variant='body2' >{preview.c_name} ({preview.c_title})</Typography>
                        </Box>

                        <Box sx={{position: 'absolute', top: '190mm', marginLeft: 6.3, width: '82%'}} >
                            { results.map((data, i) =>
                            <Grid container key={i} >
                                <Grid item xs={1.7} mb={1} >
                                    <Typography variant='body2' >{data.s_term}</Typography>
                                </Grid>
                                <Grid item xs={5.9} mb={1} >
                                    <Typography variant='body2' >{data.s_subject}</Typography>
                                </Grid>
                                <Grid item xs={2.7} mb={1} >
                                    <Typography variant='body2' align='center' >{data.s_fmark}</Typography>
                                </Grid>
                                <Grid item xs={1.5} mb={1} >
                                    <Typography variant='body2' align='right' >{data.r_marks}</Typography>
                                </Grid>
                            </Grid>) }
                        </Box>

                        <Box sx={{position: 'absolute', bottom: '41mm', right: '120mm'}} color='black' fontWeight='bold'>Total</Box>
                        <Box sx={{position: 'absolute', bottom: '41mm', right: '59mm'}} color='black' fontWeight='bold'>{preview.fullMarks}</Box>
                        <Box sx={{position: 'absolute', bottom: '41mm', right: '23mm'}} color='black' fontWeight='bold'>{results.reduce((t, i) => t + Number(i.r_marks), 0)}</Box>

                        <Typography sx={{position: 'absolute', bottom: '32.2mm', right: '86.5mm'}} color='black' fontWeight='bold' variant='caption' >{(Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 85 ? 'A+' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 70 ? 'A' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 60 ? 'B' : (Number(preview.marks) * 100 / Number(preview.fullMarks)) >= 45 && 'C'}</Typography>
                        <Typography sx={{position: 'absolute', bottom: '32.2mm', right: '33mm'}} color='black' fontWeight='bold' variant='caption' >{Number(preview.marks) * 100 / Number(preview.fullMarks) }%</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}