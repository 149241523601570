import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'

/* ADMIN */
import AdminLogin from './login/admin'
import DashboardAdmin from './admin/dashboard'
import BranchAdmin from './admin/branch'
import StudentAdmin from './admin/student'
import CoursedAdmin from './admin/course'
import PaymentAdmin from './admin/payment'
import CertificatedAdmin from './admin/dashboard'
import BranchFranchiseCertificate from './certificate/branch'
import GalleryAdmin from './admin/gallery'
import StudentListAdmin from './admin/student-list'

// ADMIN ONLINE COURSE
import AdminOnlineCourse from './admin/online-course/index'
import OnlineCourseVideos from './admin/online-course/videos'
import PlayerAdmin from './admin/online-course/player'
import StudentOnlineCourseAdmin from './admin/student-online-course'
import AdminCourseTopics from './admin/online-course/topics'
import AdminCourseFolders from './admin/online-course/folders'

/* BRANCH */
import BranchLogin from './login/branch'
import DashboardBranch from './branch/dashboard'
import AttendanceBranch from './branch/attendance'
import StudentBranch from './branch/student'
import BatchBranch from './branch/batch'
import PaymentBranch from './branch/payment'
import EntryBranch from './branch/entry'
import StudentList from './branch/student-list'
import TeacherBranch from './branch/teacher'

/* STUDENT */
import StudentLogin from './login/student'
import DashboardStudent from './student/index'
import AttendanceHistoryStudent from './student/attendance'
import PaymentHistoryStudent from './student/payment'
import ProfileStudent from './student/profile'
import CourseDetailStudent from './student/course-details'
import VideoPlayerStudent from './student/video-player'
import CoinHistoryStudent from './student/coin-history'
import TopicFoldersStudent from './student/topic-folders'
import FolderVideosStudent from './student/folder-videos'

/* ISSUE */
import ComputerCertificate from './certificate/certificate'
import Marksheet from './certificate/marksheet'
import TypingCertificate from './certificate/typing-certificate'
import ComputerCertificateNew from './certificate/cert-new'
import TypingCertificateNew from './certificate/type-cert-new'
import MarksheetNew from './certificate/mark-new'

/* TEACHER */
import TeacherLogin from './login/teacher'
import DashboardTeacher from './teacher/index'
import AttendanceTeacher from './teacher/attendance'

/* PAGES */
import Home from './pages/home'
import Student from './pages/student.jsx'
import Gallery from './pages/gallery.jsx'
import GetFranchise from './pages/franchise'
import PagesSecurity from './pages/security'
import PagesAbout from './pages/about'
import PagesContact from './pages/contact'
import PagesCourse from './pages/course';
import Verification from './pages/verification'

/* PRINT */
import Invoice from './print/invoice'
import AdmissionForm from './print/form'
import PrintPayment from './print/payment'

function Institute(){

  useEffect(() => {
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    });
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        light: '#008a96',
        main: '#006d77',
        dark: '#015259',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  // IMAGES & VIDEOS
  const img = '../../radiantassets/images'
  const simg = '../../radiantassets/studentimg'
  const doc = '../../radiantassets/documents'
  const videoPath = '../../radiantassets/videos'

  // STUDENT
  const studentLoginApi = '/radiantapi/login/student/index.php'
  const studentApi = '/radiantapi/student/index.php'

  // ADMIN
  const adminLoginApi = '/radiantapi/login/admin/index.php'
  const adminApi = '/radiantapi/admin/index.php'

  // BRANCH
  const branchLoginApi = '/radiantapi/login/branch/index.php'
  const branchApi = '/radiantapi/branch/index.php'

  // TEACHER
  const teacherLoginApi = '/radiantapi/login/teacher/index.php';
  const teacherApi = '/radiantapi/teacher/index.php';

  // PRINT
  const printapi = '/radiantapi/print-view/index.php'

  // PUBLIC
  const publicApi = '/radiantapi/public/index.php'

  return(
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path='/login-admin' component={() => <AdminLogin api={adminLoginApi} />} />
        <Route path='/dashboard-admin' component={() => <DashboardAdmin api={adminApi} />} />
        <Route path='/branch-admin' component={() => <BranchAdmin api={adminApi} />} />
        <Route path='/student-admin' component={() => <StudentAdmin api={adminApi} />} />
        <Route path='/course-admin' component={() => <CoursedAdmin api={adminApi} />} />
        <Route path='/payment-admin' component={() => <PaymentAdmin api={adminApi} />} />
        <Route path='/certificate-admin' component={() => <CertificatedAdmin api={adminApi} />} />
        <Route path='/gallery-admin' component={() => <GalleryAdmin api={adminApi} />} />
        <Route path='/student-list-admin' component={() => <StudentListAdmin api={adminApi} />} />

        <Route path='/online-course-admin' component={() => <AdminOnlineCourse api={adminApi} img={img} />} />
        <Route path='/online-course-video/:onlinefolderid' component={() => <OnlineCourseVideos api={adminApi} img={img} />} />
        <Route path='/online-video-player-admin/:videolink/:title' component={() => <PlayerAdmin vpath={videoPath} />} />
        <Route path='/online-course-student-admin/:studentid' component={() => <StudentOnlineCourseAdmin api={adminApi} img={img} />} />
        <Route path='/online-course-topic-admin/:onlinecourseid' component={() => <AdminCourseTopics api={adminApi} />} />
        <Route path='/online-course-folders-admin/:onlinetopicid' component={() => <AdminCourseFolders api={adminApi} />} />

        <Route path='/login-branch' component={() => <BranchLogin api={branchLoginApi} />} />
        <Route path='/dashboard-branch' component={() => <DashboardBranch api={branchApi} />} />
        <Route path='/attendance-branch' component={() => <AttendanceBranch api={branchApi} />} />
        <Route path='/student-branch' component={() => <StudentBranch api={branchApi} />} />
        <Route path='/batch-branch' component={() => <BatchBranch api={branchApi} />} />
        <Route path='/payment-branch' component={() => <PaymentBranch api={branchApi} />} />
        <Route path='/entry-branch' component={() => <EntryBranch api={branchApi} />} />
        <Route path='/student-list-branch' component={() => <StudentList api={branchApi} />} />
        <Route path='/teacher-branch' component={() => <TeacherBranch api={branchApi} />} />

        <Route path='/login-student' component={() => <StudentLogin api={studentLoginApi} />} />
        <Route path='/dashboard-student' component={() => <DashboardStudent api={studentApi} img={img} />} />
        <Route path='/attendance-history-student' component={() => <AttendanceHistoryStudent api={studentApi} />} />
        <Route path='/payment-history-student' component={() => <PaymentHistoryStudent api={studentApi} />} />
        <Route path='/profile-student' component={() => <ProfileStudent api={studentApi} />} />
        <Route path='/course-detail-student/:onlinecourseid' component={() => <CourseDetailStudent api={studentApi} img={img} />} />
        <Route path='/video-player-student/:videopath' component={() => <VideoPlayerStudent vpath={videoPath} />} />
        <Route path='/coin-history-student' component={() => <CoinHistoryStudent api={studentApi} />} />
        <Route path='/topic-folders-student/:onlinetopicid' component={() => <TopicFoldersStudent api={studentApi} />} />
        <Route path='/folder-videos-student/:onlinefolderid' component={() => <FolderVideosStudent api={studentApi} img={img} />} />

        <Route path='/certificate/:studentid' component={ComputerCertificate} />
        <Route path='/marksheet/:studentid' component={Marksheet} />
        <Route path='/typing-certificate/:studentid' component={() => <TypingCertificate api={printapi} simg={simg} />} />
        <Route path='/certificate-branch/:branchid' component={() => <BranchFranchiseCertificate api={adminApi} />} />
        <Route path='/cert-new/:studentid' component={() => <ComputerCertificateNew api={printapi} simg={simg} />} />
        <Route path='/type-cert-new/:studentid' component={() => <TypingCertificateNew api={printapi} simg={simg} />} />
        <Route path='/mark-new/:studentid' component={() => <MarksheetNew api={printapi} simg={simg} />} />

        <Route path='/invoice/:studentid/:paymentid' component={() => <Invoice api={branchApi} />} />
        <Route path='/form/:studentid' component={() => <AdmissionForm api={branchApi} />} />
        <Route path='/payment-detail' component={() => <PrintPayment api={branchApi} />} />

        <Route path='/login-teacher' component={() => <TeacherLogin api={teacherLoginApi} />} />
        <Route path='/dashboard-teacher' component={() => <DashboardTeacher api={teacherApi} />} />
        <Route path='/attendance-teacher/:batchid' component={() => <AttendanceTeacher api={teacherApi} />} />

        <Route exact path='/' component={Home} />
        <Route path='/student' component={Student} />
        <Route path='/gallery' component={Gallery} />
        <Route path='/get-franchise' component={GetFranchise} />
        <Route path='/security' component={() => <PagesSecurity />} />
        <Route path='/about' component={() => <PagesAbout />} />
        <Route path='/contact' component={() => <PagesContact />} />
        <Route path='/course' component={() => <PagesCourse />} />
        <Route path='/verification' component={() => <Verification api={publicApi} img={simg} />} />
      </Switch>
    </ThemeProvider>
  );

} ReactDOM.render(<BrowserRouter><Institute /></BrowserRouter>, document.getElementById('root'))