import React from "react";
import { useHistory } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";

// MUI
import { AppBar, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { CloseRounded, Print } from '@mui/icons-material'

export default function Bar({text, print, area}){
    let history = useHistory()

    const printit = useReactToPrint({
        content:() => area.current
    })

    return(
        <AppBar color="primary" position="sticky" sx={{boxShadow: 0}} >
            <Toolbar>
                <Typography variant="body2" noWrap color='inherit' sx={{flexGrow: 1}} >{text}</Typography>
                <Stack direction='row' spacing={1} >    
                    {print === true && <IconButton color='inherit' onClick={() => printit()} ><Print/></IconButton> }
                    <IconButton color="inherit" edge='start' onClick={() => history.go(-1)} ><CloseRounded/></IconButton>
                </Stack>
            </Toolbar><Divider/>
        </AppBar>
    );
}