import React, { useEffect, useState } from 'react'
import {  } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import HeaderAdmin from './header'
import Close from './../close'

/* MUI */
import { Alert, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';

export default function StudentListAdmin({api}){

    const [filter, setFilter] = useState('')

    const printIt = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML
        document.body.innerHTML = getArea            
        window.print()
        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }

    const [warning, setWarning] = useState('')
    const [students, setStudents] = useState([])
    useEffect(() => {
        viewStudent(filter)
    }, [filter])
    const viewStudent = (filter) => {
        setWarning('')
        var formData = new FormData()
        formData.append('student-list', filter)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    return(
        <>
        <div className='sidebar'> <SidebarAdmin /> </div>
        <div className='content'>
        <HeaderAdmin />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12'>
                    <FormControl fullWidth variant='standard' >
                        <InputLabel>FILTER</InputLabel>
                        <Select value={filter} onChange={(event) => setFilter(event.target.value)} >
                            <MenuItem value='all' >All</MenuItem>
                            <MenuItem value='verified' >Verified</MenuItem>
                            <MenuItem value='issued' >Issued</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-12 text-end'>
                    <IconButton onClick={()=> printIt()} ><PrintIcon /></IconButton>
                </div>
                <div className='col-md-10 col-12 mx-auto flex mt-5' >
                    <div className='row'>
                        <div className='col-12'>
                            { warning ? <Alert severity='warning'>{warning}</Alert> :
                            <TableContainer id='printArea' >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left' >NAME</TableCell>
                                            <TableCell align='left' >FATHER/MOTHER'S NAME</TableCell>
                                            <TableCell align='left' >S.CODE</TableCell>
                                            <TableCell align='left' >ADMISSION DATE</TableCell>
                                            <TableCell align='left' >REG. NUM.</TableCell>
                                            <TableCell align='left' >REG. DATE.</TableCell>
                                            <TableCell align='left' >ISSUED ON</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { students.map((stu, i) =>
                                        <TableRow key={i} >
                                            <TableCell align='left' >{stu.s_name}</TableCell>
                                            <TableCell align='left' >{stu.s_guardian}</TableCell>
                                            <TableCell align='left' >{stu.s_code}</TableCell>
                                            <TableCell align='left' >{stu.s_joindate}</TableCell>
                                            <TableCell align='left' >{stu.s_enrollment}</TableCell>
                                            <TableCell align='left' >{stu.s_register}</TableCell>
                                            <TableCell align='left' >{stu.s_issuedate}</TableCell>
                                        </TableRow> ) }
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}