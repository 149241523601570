import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from "react-qr-code";
import Bar from './../bar'

/* MUI */
import { Box, Container, Grid, Typography } from '@mui/material'

export default function TypingCertificate({api, simg}){
    const { studentid } = useParams()

    const [preview, setPrint] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('computer-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPrint(data)
            }
        })
    }, [studentid])

    const [typing, setTyping] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('typing-certificate-detail', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setTyping(data)
            }
        })
    }, [studentid])

    useEffect(()  => {
        document.title = preview.s_name
    }, [preview])

    const area = useRef()

    return(
        <Box><Bar text='Certificate' print={true} area={area} />
        
        <Container>
            <Grid container mt={4} >
                <Grid item xs={12} md={12} overflow='auto' margin='auto' >
                    <Box sx={{position: 'relative', width: '297mm', height: '209mm'}} ref={area} padding={2} >
                        <img src='/cert/typing-certificate.jpg' alt='Certificate' style={{width: '100%', height: '100%'}} />
                        {/*<Box sx={{left: '25mm', top: '47mm', position: 'absolute', background: 'white', padding: 0.7}} >
                            <QRCode size={105} value={`This certificate has issued by 'RADIANT INSTITUTE OF ADVANCE TECHNOLOGY' from '${preview.b_name}' of ${preview.s_name} (Registration number: ${preview.s_enrollment}) on ${preview.s_issuedate}`} />
                        </Box>*/}
                        <Box sx={{right: '18mm', top: '32mm', position: 'absolute', background: 'white'}} >
                            <img src={`${simg}/${preview.s_pic}`} alt='Student' style={{width: '140px', height: '160px'}} />
                        </Box>
                        <Typography sx={{position: 'absolute', top: '78.3mm', left: '251mm'}} color='white' fontSize={13} >{preview.s_enrollment}</Typography>
                        <Typography sx={{position: 'absolute', top: '94.4mm', left: '95mm'}} color='black' >{preview.s_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '105.8mm', left: '95mm'}} color='black' >{preview.s_guardian}</Typography>
                        <Typography sx={{position: 'absolute', top: '116.7mm', left: '145mm'}} color='black' >{preview.c_duration}</Typography>
                        <Typography sx={{position: 'absolute', top: '126.5mm', left: '75mm'}} color='black' >{preview.c_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '136.5mm', left: '83mm'}} color='black' >{preview.b_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '146.5mm', left: '177mm'}} color='black' >{typing.r_typing}</Typography>
                        <Typography sx={{position: 'absolute', top: '175mm', left: '80mm'}} color='black' fontSize={12} >{preview.s_issuedate}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}