import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch, useParams, Route } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import Close from './../close'

/* MUI */
import { Alert, FormControl, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputLabel, LinearProgress, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BlockIcon from '@mui/icons-material/Block';

export default function BranchdAdmin({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()
    const [opensnack, setOpenSnack] = useState(false)
    const [snackwarn, setSnackwarn] = useState('')

    const [branches, setBranches] = useState([])
    const [branchwarn, setBranchwarn] = useState('')
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        viewbranches()
    }, [])

    const viewbranches = () => {
        setBranchwarn(null)
        var formData = new FormData()
        formData.append('view-branches', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setBranchwarn(data.warning)
            } else {
                setBranches(data)
            }
        })
    }

    return(
        <>
        <SidebarAdmin />
        <div className='content'>
        <Snackbar message={snackwarn} open={opensnack} onClose={() => setOpenSnack(false)} />
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 text-end mb-2'>
                    <Button color='warning' onClick={() => history.push(`${url}/configure/0`)} >Add</Button>
                </div>
                {loading ? <LinearProgress color='warning' /> :
                branchwarn ? <Alert severity='warning'>{branchwarn}</Alert> :
                branches.map((branch, i) =>
                <div className='col-md-4 col-12' key={i} >
                    <div className={`lightshadow p-3 rounded-3 mb-4 ${branch.b_blocked === '1' && 'blockbg'}`}>
                        <div className='col-12 text-center'>
                            <img src={`/radiantassets/branchimg/${branch.b_pic}`} alt='Branch pic' className='rounded-circle' style={{width: '150px', height: '150px'}} />
                        </div>
                        <div className='mt-4'>
                            <h3 className='fw-bold text-truncate' >{branch.b_name}</h3>
                            <p>Name: {branch.b_oname}</p>
                            <p>Mobile: {branch.b_omobile}</p>
                            <p>Code: {branch.b_code}</p>
                            <p>Category: {branch.b_category}</p>
                        </div>
                        <div className=''>
                            <IconButton onClick={() => history.push(`${url}/block/${branch.branchid}`)} ><BlockIcon /></IconButton>
                            <IconButton onClick={() => history.push(`${url}/pic/${branch.branchid}`)} ><CameraAltIcon /></IconButton>
                            <IconButton onClick={() => history.push(`/certificate-branch/${branch.branchid}`)} ><CardMembershipIcon /></IconButton>
                            <IconButton onClick={() => history.push(`${url}/configure/${branch.branchid}`)} ><EditIcon /></IconButton>
                        </div>
                    </div>
                </div>) }
            </div>
        </div>
        </div>
        <Route path={`${path}/configure/:branchid`} component={ConfigureBranch} />
        <Route path={`${path}/pic/:branchid`} component={ConfigurePic} />
        <Route path={`${path}/block/:branchid`} component={ConfigureBlock} />
        </>
    );

    function ConfigureBranch(){
        const { branchid } = useParams()
        const [opensnack, setOpenSnack] = useState(false)
        const [snackwarn, setSnackwarn] = useState('')

        const [oname, setOname] = useState('')
        const [omobile, setOmobile] = useState('')
        const [oemail, setOemail] = useState('')
        const [oaadhaar, setOaadhaar] = useState('')
        const [bname, setBname] = useState('')
        const [baddress, setBaddress] = useState('')
        const [bstate, setBstate] = useState('')
        const [bcity, setBcity] = useState('')
        const [bdist, setBdist] = useState('')
        const [bcode, setBcode] = useState('')
        const [bjoined, setBjoined] = useState('')
        const [bvalid, setBvalid] = useState('')
        const [category, setCategory] = useState('')
        const [uploading, setUploading] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('branch-detail', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setOname(data.b_oname); setOmobile(data.b_omobile); setOemail(data.b_oemail); setOaadhaar(data.b_oaadhaar); setBname(data.b_name); setBaddress(data.b_address); setBstate(data.b_state); setBcity(data.b_city); setBdist(data.b_dist); setBcode(data.b_code); setBjoined(data.b_joined); setBvalid(data.b_valid); setCategory(data.b_category)
                }
            })
        }, [branchid])

        const configurebranch = () => {
            setUploading(true)
            var formData = new FormData()
            formData.append('configure-branch', branchid)
            formData.append('oname', oname)
            formData.append('omobile', omobile)
            formData.append('oemail', oemail)
            formData.append('oaadhaar', oaadhaar)
            formData.append('bname', bname)
            formData.append('baddress', baddress)
            formData.append('bstate', bstate)
            formData.append('bcity', bcity)
            formData.append('bdist', bdist)
            formData.append('bcode', bcode)
            formData.append('bjoined', bjoined)
            formData.append('bvalid', bvalid)
            formData.append('category', category)
            
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setSnackwarn(data.warning)
                    setOpenSnack(true)
                } else {
                    viewbranches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)} >
                <Snackbar message={snackwarn} open={opensnack} onClose={() => setOpenSnack(false)} />
                <DialogTitle><Close title='Configure ' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12'>
                                <h5 className='fw-bold mb-2' >Owner details</h5>
                                <TextField variant='standard' fullWidth type='text' label='Name*' className='mb-4' value={oname} onChange={(event) => setOname(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number' label='Mobile*' className='mb-4' value={omobile} onChange={(event) => setOmobile(event.target.value)} />
                                <TextField variant='standard' fullWidth type='email' label='Email' className='mb-4' value={oemail} onChange={(event) => setOemail(event.target.value)} />
                                <TextField variant='standard' fullWidth type='number' label='Aadhaar' className='mb-4' value={oaadhaar} onChange={(event) => setOaadhaar(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4'>
                                <h5 className='fw-bold mb-2'>Branch details</h5>
                                <TextField variant='standard' fullWidth type='text' label='Name*' className='mb-4' value={bname} onChange={(event) => setBname(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='Address' className='mb-4' value={baddress} onChange={(event) => setBaddress(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='State' className='mb-4' value={bstate} onChange={(event) => setBstate(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='City' className='mb-4' value={bcity} onChange={(event) => setBcity(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='District' className='mb-4' value={bdist} onChange={(event) => setBdist(event.target.value)} />
                                <TextField variant='standard' fullWidth type='text' label='Branch code*' className='mb-4' value={bcode} onChange={(event) => setBcode(event.target.value)} />
                                <TextField variant='standard' fullWidth type='date' helperText='Joined on' className='mb-4' value={bjoined} onChange={(event) => setBjoined(event.target.value)} />
                                <TextField variant='standard' fullWidth type='date' helperText='Valid till' className='mb-4' value={bvalid} onChange={(event) => setBvalid(event.target.value)} />
                                <FormControl variant='standard' fullWidth >
                                    <InputLabel>Category</InputLabel>
                                    <Select value={category} onChange={(event) => setCategory(event.target.value)} >
                                        <MenuItem value='BRANCH' >Branch</MenuItem>
                                        <MenuItem value='FRANCHISE' >Franchise</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' variant='contained' size='large' color='warning' disabled={uploading} onClick={() => configurebranch()} >{uploading ? <CircularProgress color='inherit' size={24} /> : 'Upload' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function ConfigurePic(){
        const { branchid } = useParams()
        const [opensnack, setOpenSnack] = useState(false)
        const [snackwarn, setSnackwarn] = useState('')
        const [uploading, setUploading] = useState(false)

        const uploadpic = () => {
            var formData = new FormData()
            var pic = document.getElementById('branchpic').files[0]
            formData.append('upload-branch-pic', branchid)
            formData.append('pic', pic)
            if(pic != 'undefined'){
                setUploading(true)
                fetch(api, {
                    method: 'post',
                    body: formData
                }).then(res => res.json())
                .then(data => {
                    setUploading(false)
                    if(data.warning){
                        setSnackwarn(data.warning)
                    } else if(data.success){
                        viewbranches()
                        history.go(-1)
                    }
                })
            }
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)} >
                <Snackbar message={snackwarn} open={opensnack} onClose={() => setOpenSnack(false)} />
                <DialogTitle><Close title='Configure pic' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center mt-4'>
                                {uploading && <p>Uploading... Please wait</p>}
                            </div>
                            <div className='mt-2 col-12'>
                                <input type='file' id='branchpic' accept='.jpg, .png, .jpeg' className='form-control' disabled={uploading} onChange={() => uploadpic()} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function ConfigureBlock(){
        const { branchid } = useParams()

        const [blocking, setBlocking] = useState(false)

        const block = () => {
            setBlocking(true)
            var formData = new FormData()
            formData.append('block-branch', branchid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setBlocking(false)
                if(data.success){
                    viewbranches()
                    history.go(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Block' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12 text-center text-break'>
                                <h1 className='fw-bold' >Are you sure to do this?</h1>
                            </div>
                            <div className='col-12 text-center mt-4'>
                                <Button className='col-md-4 col-6' variant='contained' color='warning' size='large' onClick={() => block()} disabled={blocking} >{ blocking ? <CircularProgress color='inherit' size={24} /> : 'YES' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}