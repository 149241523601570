import React, { useEffect, useState } from 'react'
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import SidebarAdmin from './sidebar'
import Close from './../close'

/* MUI */
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, Menu, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import { Sell } from '@mui/icons-material'

export default function StudentAdmin({api}){
    let history = useHistory()
    const { path, url } = useRouteMatch()

    useEffect(() => {
        viewstudents('')
        var formData = new FormData()
        formData.append('view-students', '')
    }, [''])

    const [students, setStudents] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    const viewstudents = (query) => {
        setWarning('')
        var formData = new FormData()
        formData.append('view-students', query)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setStudents(data)
            }
        })
    }

    const searchstudent = (event) => {
        if(event.keyCode === 13){
            viewstudents(event.target.value)
        }
    }

    const [menustudentid, setMenustudentid] = useState('')
    const [anchorEl, setAnchorEl] = useState('')
    const [type, setType] = useState('')
    const [certnew, setCertnew] = useState(false)
    const openmenu = (event, studentid, type, newcert) => {
        newcert === '1' && setCertnew(true)
        setMenustudentid(studentid)
        setType(type)
        setAnchorEl(event.currentTarget)
    }

    return(
        <>
        <SidebarAdmin />
        <Box className='content'>
        <Container>
            <TextField fullWidth variant='standard' label='Search student' placeholder='Search by name, code, Reg. no....' onKeyUp={(event) => searchstudent(event)} />
            <Grid container mt={2} spacing={2} >
                {loading ? <LinearProgress color='warning' /> :
                warning ? <Alert severity='warning'>{warning}</Alert> :
                students.map((stu, i) =>
                <Grid item xs={12} md={7} mx='auto' key={i} >
                    <Card>
                        <CardContent >
                            <Typography fontSize={18} fontWeight='bold' mb={1} letterSpacing={2} >{stu.s_name}</Typography>
                            <Typography variant='body2' color='gray' mt={1} >Email: {stu.s_email}</Typography>
                            <Typography variant='body2' color='gray' >Birth: {stu.s_birth}</Typography>
                            <Typography variant='body2' color='gray' mb={1} >Refer ID: {stu.referid}</Typography>
                        </CardContent><Divider/>
                        <DialogActions>
                            <IconButton onClick={() => history.push(`${url}/details/${stu.studentid}`)} ><VisibilityIcon /></IconButton>
                            <IconButton onClick={(event) => openmenu(event, stu.studentid, stu.c_title, stu.new_cert)} ><VerifiedIcon /></IconButton>
                            <IconButton onClick={() => history.push(`/online-course-student-admin/${stu.studentid}`)} ><Sell /></IconButton>
                            <IconButton onClick={() => history.push(`${url}/issue/${stu.studentid}`)} ><EditIcon /></IconButton>
                        </DialogActions>
                    </Card>
                </Grid>)}
            </Grid>
            <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} >
                {type === 'TYPING' ? 
                <MenuItem onClick={() => history.push(certnew === true ? `/type-cert-new/${menustudentid}` : `/typing-certificate/${menustudentid}`)} >Typing Certificate</MenuItem> :
                <><MenuItem onClick={() => history.push(certnew === true ? `/cert-new/${menustudentid}` : `/certificate/${menustudentid}`)} >Computer Certificate</MenuItem>
                <MenuItem onClick={() => history.push(certnew === true ? `/mark-new/${menustudentid}` : `/marksheet/${menustudentid}`)} >Marksheet</MenuItem></> }
            </Menu>
        </Container>
        <div className='container-fluid mb-5'>
            <div className='row p-3'>
                <div className='col-12 p-0'>
                    
                </div>
                <div className='col-md-6 col-12 mx-auto mt-2'>
                    <div className='row'>
                        
                    </div>
                </div>
            </div>
        </div>
        </Box>
        <Route path={`${path}/issue/:studentid`} component={Issue} />
        <Route path={`${path}/details/:studentid`} component={StudentDetail} />
        </>
    );

    function Issue(){
        const { studentid } = useParams()
        const [open, setOpen] = useState(false)
        const [warning, setWarning] = useState('')

        const [detail, setDetail] = useState([])
        const [enrollment, setEnrollment] = useState('')
        const [issuedate, setIssuedate] = useState('')
        const [register, setRegister] = useState('')
        const [issuing, setIssuing] = useState(false)

        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setDetail(data)
                    setEnrollment(data.s_enrollment); setIssuedate(data.s_issuedate); setRegister(data.s_register)
                }
            })
        }, [studentid])

        const issue = () => {
            setIssuing(true)
            var formData = new FormData()
            formData.append('issue-certificate', studentid)
            formData.append('enrollment', enrollment)
            formData.append('issuedate', issuedate)
            formData.append('register', register)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setIssuing(false)
                if(data.warning){
                    setWarning(data.warning); setOpen(true)
                } else {
                    setWarning('Issued succedd.'); setOpen(true)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <Snackbar open={open} message={warning} onClose={() => setOpen(false)} />
                <DialogTitle><Close title='Certificate/ Marksheet' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Name: </TableCell>
                                                <TableCell>{detail.s_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Father/Mother's name: </TableCell>
                                                <TableCell>{detail.s_guardian}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Code: </TableCell>
                                                <TableCell>{detail.s_code}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className='col-12'>
                                <TextField fullWidth type='text' variant='standard' label='Registration/Enrollment number' className='mt-4' value={enrollment} onChange={(event) => setEnrollment(event.target.value)} />
                                <TextField fullWidth type='date' variant='standard' helperText='Registration date' className='mt-4' value={register} onChange={(event) => setRegister(event.target.value)} />
                                <TextField fullWidth type='date' variant='standard' helperText='Date of issue' className='mt-5' value={issuedate} onChange={(event) => setIssuedate(event.target.value)} />
                            </div>
                            <div className='col-12 mt-4 text-end text-center'>
                                <Button className='col-md-4 col-6' variant='contained' size='large' color='warning' disabled={issuing} onClick={() => issue()} >{issuing ? <CircularProgress color='inherit' size={24} /> : 'Issue' }</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function StudentDetail(){
        const { studentid } = useParams()

        const [student, setStudent] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-detail', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setStudent(data)
                }
            })
        }, [studentid])

        const [qualifications, setQualifications] = useState([])
        const [warning, setWarning] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-student-qualification', studentid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setQualifications(data)
                }
            })
        }, [studentid])

        return(
            <Dialog open={true} fullScreen onClose={() => history.go(-1)}>
                <DialogTitle><Close title='Student details' /></DialogTitle>
                <DialogContent>
                    <div className='container-fluid mb-5'>
                        <div className='row'>
                            <div className='col-md-6 col-12 mx-auto'>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <img src={`/instituteassets/studentimg/${student.s_pic}`} className='globalimgsize' />
                                    </div>
                                    <div className='col-12 mt-4'>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Student code</TableCell>
                                                        <TableCell >{student.s_code}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Course</TableCell>
                                                        <TableCell >{student.c_title} ({student.c_name})</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Joined on</TableCell>
                                                        <TableCell >{student.s_joindate}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell >{student.s_name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Father/Mother's name</TableCell>
                                                        <TableCell >{student.s_guardian}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Address</TableCell>
                                                        <TableCell >{student.s_address}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Post office</TableCell>
                                                        <TableCell >{student.s_po}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Police station</TableCell>
                                                        <TableCell >{student.s_ps}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>City</TableCell>
                                                        <TableCell >{student.s_city}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>District</TableCell>
                                                        <TableCell >{student.s_dist}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>State</TableCell>
                                                        <TableCell >{student.s_state}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Postel</TableCell>
                                                        <TableCell >{student.s_postel}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Aadhaar number</TableCell>
                                                        <TableCell >{student.s_aadhaar}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Mobile</TableCell>
                                                        <TableCell >{student.s_mobile}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Birth</TableCell>
                                                        <TableCell >{student.s_birth}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Gender</TableCell>
                                                        <TableCell >{student.s_gender}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Nation</TableCell>
                                                        <TableCell >{student.s_nation}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <TableContainer className='mt-4' >
                                            {warning ? <Alert severity='warning'>{warning}</Alert> :
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Board</TableCell>
                                                        <TableCell align='center' >Examnination</TableCell>
                                                        <TableCell align='center' >Passed year</TableCell>
                                                        <TableCell align='right' >Obtained marks</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {qualifications.map((qua,i) =>
                                                    <TableRow key={i} >
                                                        <TableCell>{qua.board}</TableCell>
                                                        <TableCell align='center' >{qua.exam}</TableCell>
                                                        <TableCell align='center' >{qua.pass}</TableCell>
                                                        <TableCell align='right' >{qua.marks}</TableCell>
                                                    </TableRow> )}
                                                </TableBody>
                                            </Table>}
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }



}