import React from 'react'

// MUI
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material';

export default function HeaderAdmin(){

    return(
        <Box boxShadow={1} padding={0.8} position='sticky' >
            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                <Typography fontWeight='bold' fontSize={25} fontFamily='serif' >RIAT</Typography>
                <Box>
                    <IconButton><Menu/></IconButton>
                </Box>
            </Stack>
        </Box>
    );
}