import React, { useEffect } from "react";

import PagesHeader from "./header";
import PagesFooter from "./footer";

// MUI
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { LocalLibrary } from '@mui/icons-material'

export default function PagesCourse(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <Box><PagesHeader />
            <Container>
                <Typography mt={3} fontWeight='bold' color='darkblue' fontSize={30} fontFamily='serif' align="center" >Our Courses</Typography>
                <Grid container mt={1} spacing={2} >
                    <Grid item xs={12} md={8} mx='auto' >
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{background: '#b2f7ef', height: '100%'}} mx='auto' >
                            <Box sx={{background: '#000000', height: '100%'}} padding={2} pl={4} pr={4} display='flex' justifyContent='center' alignItems='center' >
                                <LocalLibrary sx={{color: 'white', fontSize: 70}} />
                            </Box>
                            <Box p={2} pt={1} >
                                <Typography fontWeight='bold' fontSize={18} >Advanced Diploma in Computer Application</Typography>
                                <Typography variant="body2" >Duration - 1 year</Typography>

                                <Typography variant="body1" fontWeight='bold' mt={1} >Subjects:</Typography>
                                <Typography variant="body2" >Fundamental of Computer, Microsoft Office, Desktop Publication (DTP), Accounting (Tally ERP-9.)</Typography>
                                <Typography variant="body2" >Assignments & Projects</Typography>

                                <Typography variant="body2" mt={2} >Eligibility - 10th</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} mx='auto' >
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{background: '#b2f7ef', height: '100%'}} mx='auto' >
                            <Box sx={{background: '#000000', height: '100%'}} padding={2} pl={4} pr={4} display='flex' justifyContent='center' alignItems='center' >
                                <LocalLibrary sx={{color: 'white', fontSize: 70}} />
                            </Box>
                            <Box p={2} pt={1} >
                                <Typography fontWeight='bold' fontSize={18} >Diploma in Computer Application</Typography>
                                <Typography variant="body2" >Duration - 6 Months</Typography>

                                <Typography variant="body1" fontWeight='bold' mt={1} >Subjects:</Typography>
                                <Typography variant="body2" >Fundamental of Computer, Microsoft Office</Typography>
                                <Typography variant="body2" >Assignments & Projects</Typography>

                                <Typography variant="body2" mt={2} >Eligibility - 10th</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} mx='auto' >
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{background: '#b2f7ef', height: '100%'}} mx='auto' >
                            <Box sx={{background: '#000000', height: '100%'}} padding={2} pl={4} pr={4} display='flex' justifyContent='center' alignItems='center' >
                                <LocalLibrary sx={{color: 'white', fontSize: 70}} />
                            </Box>
                            <Box p={2} pt={1} >
                                <Typography fontWeight='bold' fontSize={18} >Computer Teacher Training Course</Typography>
                                <Typography variant="body2" >Duration - 1 year</Typography>

                                <Typography variant="body1" fontWeight='bold' mt={1} >Subjects:</Typography>
                                <Typography variant="body2" >Fundamental of Computer, Microsoft Office, Desktop Publication (DTP), Accounting (Tally ERP-9.), Programming (HTML, C, C++, Java)</Typography>
                                <Typography variant="body2">Interview Facing, How to teach in Batches, Personality Development</Typography>

                                <Typography variant="body2" mt={2} >Eligibility - 10th</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} mx='auto' >
                        <Stack direction={{xs: 'column', md: 'row'}} sx={{background: '#b2f7ef', height: '100%'}} mx='auto' >
                            <Box sx={{background: '#000000', height: '100%'}} padding={2} pl={4} pr={4} display='flex' justifyContent='center' alignItems='center' >
                                <LocalLibrary sx={{color: 'white', fontSize: 70}} />
                            </Box>
                            <Box p={2} pt={1} >
                                <Typography fontWeight='bold' fontSize={18} >Computer Teacher Training Course</Typography>
                                <Typography variant="body2" >Duration - 1 year</Typography>

                                <Typography variant="body1" fontWeight='bold' mt={1} >Subjects:</Typography>
                                <Typography variant="body2" >Fundamental of Computer, Microsoft Office, Desktop Publication (DTP), Introduction of Account</Typography>
                                <Typography variant="body2" >Assignments & Projects</Typography>

                                <Typography variant="body2" mt={2} >Eligibility - 10+2</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        <PagesFooter/></Box>
    )
}