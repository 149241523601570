import React, {  } from 'react'
import { useHistory } from 'react-router-dom';

import PagesHeader from './header';
import PagesFooter from './footer';

// MATERIAL UI
import { Box, Button, Card, Container, Grid, Paper, Typography } from '@mui/material'
import { Call, Mail, Navigation, WhatsApp } from '@mui/icons-material';

export default function Home(){
    let history = useHistory()
    return(
        <><PagesHeader/>
        <Box className='landing' >
            <Box sx={{backgroundImage: "url('/images/landing-bg.png')"}} className='land-home' >
                <Container sx={{height: '100%'}} >
                    <Grid direction='row-reverse' container sx={{height: '100%'}} justifyContent='center' alignItems='center' >
                        <Grid item xs={12} md={6} padding={2} >
                            <img src='/images/hero.png' alt='Hero image' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h6' color='gray' fontWeight='light' >You only have to know one thing,</Typography>
                            <Typography variant='h3' fontWeight='bold' mt={1} >You can learn anything!</Typography>
                            <Box sx={{backgroundImage: 'linear-gradient(120deg, #018a7e, #ffffff)'}} borderRadius={0.6} padding={0.4} mb={3} ></Box>

                            <Button variant='contained' sx={{boxShadow: 0, borderRadius: 1}} color='primary' onClick={() => history.push('/login-student')} >Sign Up</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>


            <Box sx={{backgroundImage: "url('/images/back1.webp')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} >
                <Container>
                    <Grid container mt={3} spacing={3} justifyContent='center' alignItems='center' >
                        <Grid item xs={12} md={6} padding={3} >
                            <img src='/images/welcome.svg' alt='Welcome image' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography fontSize={20} fontFamily='serif' mb={3} letterSpacing={1} >Welcome to our institute! We are a leading provider of both online and offline courses, designed to help students achieve their academic and professional goals.</Typography>
                            <Button variant='contained' sx={{boxShadow: 0, borderRadius: 0}} color='primary' onClick={() => history.push('/login-student')} >Sign Up NOW</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box mt={3} >
                <Container>
                    <Typography variant='h4' color='rebeccapurple' fontSize={27} fontWeight='bold' fontFamily='serif' textAlign='center' mb={2} >Best of our offline courses,</Typography>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={4} >
                            <Paper>
                                <Box padding={2} textAlign='center' >
                                    <img src='/images/course.png' alt='Course' style={{width: 80}} />
                                    <Typography fontWeight='bold' fontSize={25} letterSpacing={2} >ADCA</Typography>
                                    <Typography variant='body1' >Advance Diploma in Computer Application</Typography>
                                    <Typography variant='body2' >Duration 1 Year</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Paper>
                                <Box padding={2} textAlign='center' >
                                    <img src='/images/course.png' alt='Course' style={{width: 80}} />
                                    <Typography fontWeight='bold' fontSize={25} letterSpacing={2} >DCA</Typography>
                                    <Typography variant='body1' >Diploma in Computer Application</Typography>
                                    <Typography variant='body2' >Duration 6 Months</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Paper>
                                <Box padding={2} textAlign='center' >
                                    <img src='/images/course.png' alt='Course' style={{width: 80}} />
                                    <Typography fontWeight='bold' fontSize={25} letterSpacing={2} >CTTC</Typography>
                                    <Typography variant='body1' >Computer Teacher Training Course</Typography>
                                    <Typography variant='body2' >Duration 1 Year</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Typography variant='h4' color='rebeccapurple' fontWeight='bold' fontSize={27} fontFamily='serif' mt={7} textAlign='center' >When money becomes digital, why not you?</Typography>

                    <Grid container justifyContent='center' alignItems='center' direction={{xs: 'column-reverse', md: 'row'}} sx={{background: '#cbf3f0'}} borderRadius={3} padding={2} >
                        <Grid item xs={12} md={6}>
                            <Typography variant='h5' fontWeight='bold' >Be expert in Crypto currency, Digital marketing, Trading </Typography>
                            <Typography variant='body2' color='gray'>Cryptocurrency is a digital currency that uses cryptography to secure and verify transactions. Digital marketing refers to promoting products or services using digital channels. Trading involves buying and selling financial instruments to profit from price fluctuations.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src='/images/digital-marketing.png' alt='Digital marketing image' />
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Container sx={{marginTop: 3}}>
                <Typography variant='h4' color='rebeccapurple' fontSize={27} fontWeight='bold' fontFamily='serif' textAlign='center' >Contact us</Typography>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={3} textAlign='center' >
                        <a href='tel: 7979782589' rel='noreffer'><Card>
                            <Box pt={6} pb={6} >
                                <Call sx={{fontSize: 50}} color='primary' />
                                <Typography color='gray' >Make a call</Typography>
                            </Box>
                        </Card></a>
                    </Grid>
                    <Grid item xs={12} md={3} textAlign='center' >
                        <a href='https://wa.me/917979782589' target='_blank' rel='noreffer'><Card>
                            <Box pt={6} pb={6} >
                                <WhatsApp sx={{fontSize: 50}} color='primary' />
                                <Typography color='gray' >Chat on WhatsApp</Typography>
                            </Box>
                        </Card></a>
                    </Grid>
                    <Grid item xs={12} md={3} textAlign='center' >
                        <a href='mailto: info@radiantiteducation.in' target='_blank' rel='noreffer'><Card>
                            <Box pt={6} pb={6} >
                                <Mail sx={{fontSize: 50}} color='primary' />
                                <Typography color='gray' >Write us query</Typography>
                            </Box>
                        </Card></a>
                    </Grid>
                    <Grid item xs={12} md={3} textAlign='center' >
                        <a href='' rel='noreffer'><Card>
                            <Box pt={6} pb={6} >
                                <Navigation sx={{fontSize: 50}} color='primary' />
                                <Typography color='gray' >Take a walk</Typography>
                            </Box>
                        </Card></a>
                    </Grid>
                </Grid>
            </Container>

        <PagesFooter/> </>
    );
}