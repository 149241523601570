import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import QRCode from "react-qr-code";

/* MUI */
import { Alert, AppBar, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, LinearProgress, Toolbar, Typography } from '@mui/material'
import { PowerSettingsNew } from '@mui/icons-material';

export default function DashboardTeacher({api}){
    let history = useHistory()
    let { path, url } = useRouteMatch()

    useEffect(() => {
        fetch('/radiantapi/teacher/ui.php').then(res => res.json())
        .then(data => {
            if(data.loggedin === false){
                history.replace('/login-teacher')
            }
        })
    }, [])

    const [view, setView] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('teacher-detail', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setView(data)
        })
    }, [])

    const [ldialog, setLdialog] = useState(false)
    const [working, setWorking] = useState(false)
    const logout = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                history.replace('/login-teacher')
            }
        })
    }

    return(
        <>
        <AppBar position='sticky' >
            <Toolbar>
                <Typography fontWeight='bold' fontSize={30} fontFamily='serif' letterSpacing={3} color='white' sx={{flexGrow: 1}} >RIAT</Typography>
                <IconButton color='inherit' edge='end' onClick={() => setLdialog(true)} ><PowerSettingsNew /></IconButton>
            </Toolbar>
        </AppBar>
        <Dialog open={ldialog} fullWidth={true} maxWidth='xs' onClose={() => setLdialog(false)} >
            <DialogContent>
                <Box mt={1}>
                    <Typography variant='h6' fontWeight='bold' >Do you want to logout?</Typography>
                    <Typography color='gray' variant='caption' >Note: This action will redirect you to the login page.</Typography>
                </Box>
                <Box textAlign='center' mt={3} mb={1} >
                    <Button variant='contained' color='error' sx={{boxShadow: 0, borderRadius: 0.5}} onClick={() => logout()} disabled={working} >{working ? <CircularProgress color='inherit' size={24} /> : 'YES, LOGOUT'}</Button>
                </Box>
            </DialogContent>
        </Dialog>
        <div className='container-fluid'>
            <div className='row mt-4 p-3'>
                <div className='col-md-8 col-12 mx-auto'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='lightshadow p-3 rounded-3 text-center'>
                                <h5 className='mt-2 text-truncate' >HELLO!</h5>
                                <h3 className='text-truncate fw-bold' >{view.t_name}</h3>
                            </div>
                        </div>
                        <div className='col-md-5 col-12 mt-4'>
                            <div className='lightshadow p-3 rounded-3 flex text-center' style={{height: '170px'}} >
                                <h6>Your code</h6>
                                <h3 className='fw-bold lspace3'>{view.t_code}</h3>
                            </div>
                        </div>
                        <div className='col-md-7 col-12 mt-4'>
                            <div className='lightshadow p-3 rounded-3 flex' style={{height: '170px'}} >
                                <h5 className='text-center'>Make attendance</h5>
                                <div className='text-center mt-2'>
                                    <Button color='warning' size='large' variant='contained' className='m-1 col-md-5 col-7' onClick={() => history.push(`${url}/qr`)} >QR</Button>
                                    <Button color='warning' size='large' variant='contained' className='m-1 col-md-5 col-7' onClick={() => history.push(`${url}/manually`)} >MANUALLY</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Route path={`${path}/qr`} component={QR} />
        <Route path={`${path}/manually`} component={Manually} />
        </>
    );

    function QR(){

        const [qr, setQr] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('view-qr', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setQr(data.qr)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <QRCode value={qr} />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function Manually(){

        const [batches, setBatches] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            var formData = new FormData()
            formData.append('view-batches', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setBatches(data)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth onClose={() => history.go(-1)}>
                <DialogContent>
                    <div className='container-fluid mt-5 mb-5'>
                        <div className='row'>
                            { loading ? <LinearProgress color='warning' /> :
                            warning ? <Alert severity='warning'>{warning}</Alert> :
                            batches.map((batch, i) =>
                            <div className='col-12 mb-2' key={i} >
                                <div className='bgGrey p-3 rounded-3 pointer' onClick={() => history.push(`/attendance-teacher/${batch.batchid}`)} >
                                    <h6 className='mt-2'>{batch.bh_start} TO {batch.bh_end}</h6>
                                </div>
                            </div> )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

}