import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Close from './../close'

/* MUI */
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'

export default function Invoice({api}){
    const { studentid } = useParams()
    const { paymentid } = useParams()

    const printIt = () => {
        var getArea = document.getElementById('printArea').innerHTML
        var printArea = document.body.innerHTML
        document.body.innerHTML = getArea            
        window.print()
        document.body.innerHTML = printArea
        window.close(window.location.reload())
    }

    const [view, setView] = useState([])

    useEffect(() => {
        var formData = new FormData()
        formData.append('print-invoice', paymentid)
        formData.append('studentid', studentid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setView(data)
            }
        })
    }, [studentid, paymentid])

    return(
        <Dialog open={true} fullScreen>
            <DialogTitle><Close title='Invoice' /></DialogTitle>
            <DialogContent>
                <div className='container-fluid'>
                    <div className='row p-3'>
                        <div className='col-12 text-center'>
                            <Button className='col-md-4 col-6 p-3' variant='contained' size='large' color='warning' onClick={() => printIt()} >PRINT</Button>
                        </div>
                    </div>
                    <div className='row p-3' id='printArea' >
                        <div className='col-12 text-end'>
                            <h1 className='fw-bold lspace5' >{view.b_name}</h1>
                            <small className='me-2' >{view.b_address}, {view.b_city}</small><br/>
                            <small>Mobile: {view.b_omobile}</small>
                        </div>
                        <div className='col-12 mt-3'>
                            <table className='table table-borderless' >
                                <tbody>
                                    <tr><td>Student code</td><td>{view.s_code}</td><td>Date</td><td>{view.p_date}</td></tr>
                                    <tr><td>Slip No</td><td>{paymentid}</td><td>Course</td><td>{view.c_title}</td></tr>
                                    <tr><td>Name</td><td>{view.s_name}</td><td>Mobile</td><td>{view.s_mobile}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='col-12 mt-2'>
                            <table className='table table-borderless'>
                                <thead style={{background: '#cccccc'}} >
                                    <tr><th className='fw-bold' >Description</th><th className='fw-bold text-end' >Amount (&#8377;)</th></tr>
                                </thead>
                                <tbody >
                                    <tr ><td>{view.p_description}</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                </tbody>
                                <tfoot style={{background: '#cccccc'}} >
                                    <tr ><td className='text-center' >Total</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                </tfoot>
                            </table>
                            <div className='col-12 text-end mt-4'>
                                <h5 className='fw-bold'>Authorized signature</h5>
                            </div>
                        </div>

                        <div className='col-12 text-end mt-5'>
                            <h1 className='fw-bold lspace5' >{view.b_name}</h1>
                            <small className='me-2' >{view.b_address}, {view.b_city}</small><br/>
                            <small>Mobile: {view.b_omobile}</small>
                        </div>
                        <div className='col-12 mt-3'>
                            <table className='table table-borderless' >
                                <tbody>
                                    <tr><td>Student code</td><td>{view.s_code}</td><td>Date</td><td>{view.p_date}</td></tr>
                                    <tr><td>Slip No</td><td>{paymentid}</td><td>Course</td><td>{view.c_title}</td></tr>
                                    <tr><td>Name</td><td>{view.s_name}</td><td>Mobile</td><td>{view.s_mobile}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='col-12 mt-2'>
                            <table className='table table-borderless'>
                                <thead style={{background: '#cccccc'}} >
                                    <tr><th className='fw-bold' >Description</th><th className='fw-bold text-end' >Amount (&#8377;)</th></tr>
                                </thead>
                                <tbody >
                                    <tr ><td>{view.p_description}</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                    <tr ><td></td><td className='text-end' ></td></tr>
                                </tbody>
                                <tfoot style={{background: '#cccccc'}} >
                                    <tr ><td className='text-center' >Total</td><td className='text-end' >{Number(view.p_amount).toLocaleString('en-in')}</td></tr>
                                </tfoot>
                            </table>
                            <div className='col-12 text-end mt-4'>
                                <h5 className='fw-bold'>Authorized signature</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}